.I-attach-products-modal {
  width            : 600px;
  height           : 600px;
  border-radius    : 10px;
  background-color : white;

  .I-attach-heading {
    width           : 100%;
    height          : 80px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    font-size       : 20px;
    border-bottom   : 1px solid #808080;
    position        : relative;

    span {
      display         : flex;
      justify-content : center;
      align-items     : center;
      position        : absolute;
      right           : 5%;
      font-size       : 18px;
      cursor          : pointer;
    }
  }

  .I-attach-search {
    width           : 100%;
    height          : 80px;
    display         : flex;
    justify-content : center;
    align-items     : center;

    .I-attach-input {
      width           : 90%;
      height          : 40px;
      display         : flex;
      justify-content : space-between;
      align-items     : center;
      border          : 1px solid #808080;
      border-radius   : 8px;

      input {
        width         : 80%;
        border-radius : 8px;
        border        : 0;
        text-indent   : 10px;
        font-size     : 16px;
      }

      i {
        font-size    : 16px;
        margin-right : 10px;
      }
    }
  }

  .I-attach-products-list {
    width      : 90%;
    height     : 345px;
    margin     : 0 auto;
    overflow-y : auto;

    .I-data-table-header {
      display : none;
    }

    .I-data-table-body {

      ul {
        padding : 0 10px;
      }
    }
  }

  .I-attach-products-footer {
    width           : 100%;
    height          : 80px;
    margin-top      : 15px;
    border-top      : 1px solid #808080;
    display         : flex;
    justify-content : flex-end;
    align-items     : center;

    .I-attach-footer-buttons {
      padding : 0 30px;

      button {
        width         : 120px;
        margin-left   : 20px;
        height        : 40px;
        border        : none;
        border-radius : 10px;

        &:first-child {
          color            : black;
          background-color : white;
        }

        &:nth-child(2) {
          color            : white;
          background-color : $app-color;
        }
      }

      .disable {
        background-color : yellow;
        color            : rgba(0, 0, 0, .3);
        border           : none;
        cursor           : default;
      }
    }
  }
}