.I-drop-down {
  min-width : 360px;
  cursor    : pointer;

  .I-drop-down-title {
    height           : 100%;
    overflow         : hidden;
    background-color : white;
    border           : none !important;
    border-bottom    : 1px solid $filter-input !important;
    color            : black;
  }

  .I-drop-down-title {
    display         : flex;
    align-items     : center;
    justify-content : center;

    p {
      width         : calc(100% - 50px);
      overflow      : hidden;
      text-overflow : ellipsis;
      white-space   : nowrap;
    }
  }

  .I-drop-down-component {
    height : 44px;
  }

  .I-drop-down-title{
    color : #A9B2B8;
  }

  .I-drop-down-options {
    max-height       : 400px;
    background-color : white;
    color            : black;
    border-radius    : 8px;
    overflow         : hidden;
    overflow-y       : auto;
    box-shadow       : 0 8px 25px -10px;
    z-index          : 100;
    position         : relative;

    div {
      padding : 15px 20px;
      overflow         : hidden;

    }
  }

  // .I-drop-down-option .selected {
  //   color       : $app-color;
  //   font-weight : bold;
  // }

  .placeholder {
    color : black;
  }
}

.selected {
  color       : $app-color;
  font-weight : bold;
}