.I-home-category-page {
  width           : 100%;
  padding         : 80px 0;
  display         : flex;
  justify-content : center;
  align-items     : center;

  .I-home-category-content {
    width            : 50vw;
    min-height       : 75vh;
    padding          : 40px 30px;
    background-color : white;
    box-shadow       : $main-box-shadow;
    border-radius    : 5px;

    div {
      margin-top : 10px;
    }

    .I-home-category-languages {
      width       : 100%;
      margin      : 0 auto;
      padding-top : 40px;

      input {
        width         : 100%;
        height        : 35px;
        margin-bottom : 20px;
        border-radius : 5px;
        border        : 1px solid $filter-input;
        font-size     : 14px;
        padding-left  : 7px;
      }

      div {
        margin-bottom : 10px;
        font-size     : 16px;

        img {
          width        : 16px;
          height       : 16px;
          margin-right : 10px;
        }
      }

      .I-right-side-attach-product {
        width  : 100%;
        margin : 25px auto;
        height : 150px;

        p {
          color         : $main-gray;
          margin-bottom : 15px;
        }
  
        div {
          width            : 100%;
          height           : 100px;
          margin-top       : 20px;
          display          : flex;
          flex-direction   : column;
          justify-content  : center;
          align-items      : center;
          border-radius    : 8px;
          background-color : #F0F3F4;
          cursor           : pointer;
  
          i {
            font-size : 30px;
            color     : #939EA6;
          }
  
          span {
            font-size : 18px;
            color     : #939EA6;
          }
        }
      }

      .I-attached-products {

        .I-promotion-product {
          width           : 100%;
          height          : 80px;
          border-top      : none;
          display         : flex;
          align-items     : center;
          justify-content : space-between;
          border-top      : 1px solid $main-gray;

          p {
            width: 100%;
          }
  
          .I-attach-img {
            width           : 50px;
            height          : 50px;
            border          : 1px solid #E2E2E2;
            border-radius   : 10px;
            display         : flex;
            align-items     : center;
            justify-content : center;
            margin-right    : 25px;
  
            img {
              width         : 43px;
              height        : 43px;
              border-radius : 10px;
              margin        : 0 auto;
            }
          }
        }
      }    
    }
  }

  .I-footer-buttons {
    width            : 50vw;
    height           : 60px;
    background-color : white;
    box-shadow       : 0 0 25px -10px;
    border-radius    : 5px;
    bottom           : 0;
    z-index          : 1;
    position         : absolute;
    display          : flex;
    align-items      : center;
  
    button {
      margin-right : 25px;
    }
  
    .save {
      background-color : $app-color;
      color            : white;
    }
    
    .disabled {
      background-color : rgba(0, 0, 0, .1);
      color            : rgba(0, 0, 0, .3);
      border           : none;
      cursor           : default;
    }
  }
}