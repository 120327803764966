@import url(~react-draft-wysiwyg/dist/react-draft-wysiwyg.css);
@import url(~react-datepicker/dist/react-datepicker.css);
@import url(~react-sortable-tree/style.css);
@import url(~react-notifications/lib/notifications.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: "Regular", "Armenian"; }

@font-face {
  font-family: "Regular";
  src: url("../fonts/FedraSansPro Book.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Armenian";
  src: url("../fonts/Armenian.TTF") format("truetype");
  font-weight: normal;
  font-style: normal; }

button {
  cursor: pointer; }

.I-error {
  box-shadow: 0 0 20px rgba(158, 0, 0, 0.8); }

a {
  text-decoration: none; }

.I-main-flex {
  width: 100%;
  display: flex;
  overflow: hidden; }
  .I-main-flex .I-left {
    width: 20%;
    height: 100vh;
    z-index: 100;
    transition: 0.5s; }
  .I-main-flex .I-head {
    width: 100%;
    height: 100vh; }
    .I-main-flex .I-head .I-header {
      width: 100%;
      height: 100px;
      z-index: 100; }
    .I-main-flex .I-head .I-content {
      width: 100%;
      height: calc(100vh - 100px);
      overflow-y: auto;
      background-color: #f0f3f4; }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-track {
  background: #f1f1f1; }

::-webkit-scrollbar-thumb {
  background: #888; }

::-webkit-scrollbar-thumb:hover {
  background: #1B313F; }

audio,
.I-full-width {
  width: 100%; }

video {
  background-color: black; }

.I-transparent {
  color: black !important;
  background: transparent !important; }

.rdw-editor-wrapper {
  margin: 20px 0; }
  .rdw-editor-wrapper .rdw-editor-main {
    border: 1px solid #eee;
    padding: 10px;
    max-height: 200px; }

.rst__collapseButton,
.rst__expandButton {
  z-index: 10; }
  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    box-shadow: 0 0 0 1px #000; }

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: black;
  font-size: 25px;
  right: 4px;
  top: 4px; }

.I-close {
  height: 100vh;
  z-index: 100; }

.I-search-input {
  width: 24%;
  border: none;
  border-bottom: 1px solid #A9B2B8;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #A9B2B8; }
  .I-search-input input {
    width: 85%;
    height: 42px;
    border: none;
    font-size: 18px;
    text-indent: 10px; }
    .I-search-input input::placeholder {
      font-size: 16px;
      color: #A5A6A7; }
  .I-search-input i {
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer; }

.I-search-quantity {
  width: 24%;
  color: #A9B2B8;
  border-bottom: 1px solid #A9B2B8; }
  .I-search-quantity input {
    width: 49%;
    height: 44px;
    border: none;
    font-size: 18px;
    text-indent: 10px; }
    .I-search-quantity input::placeholder {
      font-size: 16px;
      color: #A5A6A7; }

.I-filter-apply-buttons {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  float: right; }
  .I-filter-apply-buttons button {
    margin-left: 10px;
    padding: 10px 25px;
    border-radius: 8px;
    border: none; }
    .I-filter-apply-buttons button:first-child {
      background-color: white; }
    .I-filter-apply-buttons button:last-child {
      background-color: #1B313F;
      color: white; }

.I-repeat-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #cbd1d4;
  border-radius: 5px; }
  .I-repeat-button i {
    font-size: 20px;
    cursor: pointer;
    transition: 1s; }

.I-add-new-button {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  background-color: #1B313F;
  color: white; }

.I-toolbar-buttons {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #cbd1d4;
  margin-left: 20px; }

.I-table-img {
  width: 60px;
  height: 50px;
  border: 1px solid #A9B2B8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .I-table-img div {
    width: 55px;
    height: 40px;
    background-size: 150%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px; }

.I-footer-buttons {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end; }
  .I-footer-buttons button {
    width: 120px;
    margin-left: 20px;
    height: 40px;
    border: none;
    border-radius: 10px; }
    .I-footer-buttons button:first-child {
      color: black;
      background-color: white; }
    .I-footer-buttons button:last-child {
      color: white;
      background-color: #1B313F; }

.I-wrong {
  color: red;
  font-size: 14px !important;
  float: right; }

.I-wrong-field {
  border: 1px solid red !important; }

.I-show-more-div {
  width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%; }

.container:hover input ~ .checkmark {
  background-color: #ccc; }

.container input:checked ~ .checkmark {
  background-color: #1B313F; }

.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.container input:checked ~ .checkmark:after {
  display: block; }

.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white; }

.I-no-result {
  font-size: 20px;
  font-weight: bolder;
  position: absolute;
  left: 50%;
  top: 50%; }

.notification-container {
  width: 350px !important;
  padding: 20px 0 !important; }

.notification-error {
  background-color: white !important;
  border-left: 7px solid #ec5963;
  border-radius: 6px !important; }
  .notification-error::before {
    color: white !important;
    z-index: -100 !important; }

.notification-success {
  background-color: white !important;
  border-left: 7px solid #1B313F;
  border-radius: 6px !important; }
  .notification-success::before {
    color: white !important;
    z-index: -100 !important; }

.message {
  color: black;
  font-weight: bold; }

.notification {
  padding: 10px 15px !important;
  z-index: 8888888888 !important;
  opacity: 1 !important; }

.I-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%; }

.disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3);
  border: none;
  cursor: default; }

.chartjs-render-monitor {
  width: 880px !important;
  height: 280px !important; }

.css-15k3avv {
  z-index: 500000; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?9ty0pt");
  src: url("../fonts/icomoon.eot?9ty0pt#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?9ty0pt") format("truetype"), url("../fonts/icomoon.woff?9ty0pt") format("woff"), url("../fonts/icomoon.svg?9ty0pt#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-Asset-178:before {
  content: "\e903"; }

.icon-Asset-216:before {
  content: "\e904"; }

.icon-Asset-219:before {
  content: "\e905"; }

.icon-attach:before {
  content: "\e906"; }

.icon-attributes:before {
  content: "\e907"; }

.icon-attributes_01:before {
  content: "\e908"; }

.icon-back:before {
  content: "\e909"; }

.icon-brand_01:before {
  content: "\e90a"; }

.icon-camera:before {
  content: "\e90b"; }

.icon-camera_01:before {
  content: "\e90c"; }

.icon-cart:before {
  content: "\e90d"; }

.icon-cart_01:before {
  content: "\e90e"; }

.icon-category:before {
  content: "\e90f"; }

.icon-category_01:before {
  content: "\e910"; }

.icon-chat:before {
  content: "\e911"; }

.icon-check:before {
  content: "\e912"; }

.icon-close:before {
  content: "\e913"; }

.icon-company:before {
  content: "\e914"; }

.icon-company_01:before {
  content: "\e915"; }

.icon-dashboard:before {
  content: "\e916"; }

.icon-dashboard_01:before {
  content: "\e917"; }

.icon-delete:before {
  content: "\e918"; }

.icon-delete_01:before {
  content: "\e919"; }

.icon-department_01:before {
  content: "\e91a"; }

.icon-document:before {
  content: "\e91b"; }

.icon-down:before {
  content: "\e91c"; }

.icon-edit:before {
  content: "\e91d"; }

.icon-edit_01:before {
  content: "\e91e"; }

.icon-eye:before {
  content: "\e91f"; }

.icon-eye_01:before {
  content: "\e920"; }

.icon-fb:before {
  content: "\e921"; }

.icon-google:before {
  content: "\e922"; }

.icon-help:before {
  content: "\e923"; }

.icon-home:before {
  content: "\e924"; }

.icon-home_01:before {
  content: "\e925"; }

.icon-homedepartment:before {
  content: "\e926"; }

.icon-linkdine:before {
  content: "\e927"; }

.icon-location:before {
  content: "\e928"; }

.icon-logout:before {
  content: "\e929"; }

.icon-minus:before {
  content: "\e92a"; }

.icon-more:before {
  content: "\e92b"; }

.icon-mu:before {
  content: "\e92c"; }

.icon-mu_01:before {
  content: "\e92d"; }

.icon-notification:before {
  content: "\e92e"; }

.icon-options:before {
  content: "\e92f"; }

.icon-options_01:before {
  content: "\e930"; }

.icon-orders:before {
  content: "\e931"; }

.icon-orders_01:before {
  content: "\e932"; }

.icon-photo:before {
  content: "\e933"; }

.icon-photo_01:before {
  content: "\e934"; }

.icon-photo_01_1:before {
  content: "\e935"; }

.icon-plus:before {
  content: "\e936"; }

.icon-plus_01:before {
  content: "\e937"; }

.icon-products:before {
  content: "\e938"; }

.icon-products_1:before {
  content: "\e939"; }

.icon-promo_code:before {
  content: "\e93a"; }

.icon-promo_code_01:before {
  content: "\e93b"; }

.icon-Rectangle-263:before {
  content: "\e93c"; }

.icon-requests_01:before {
  content: "\e93d"; }

.icon-right:before {
  content: "\e93e"; }

.icon-search:before {
  content: "\e93f"; }

.icon-sent:before {
  content: "\e940"; }

.icon-settings:before {
  content: "\e941"; }

.icon-settings_01:before {
  content: "\e942"; }

.icon-share:before {
  content: "\e943"; }

.icon-top:before {
  content: "\e944"; }

.icon-update:before {
  content: "\e945"; }

.icon-upload:before {
  content: "\e946"; }

.icon-user:before {
  content: "\e947"; }

.icon-user_01:before {
  content: "\e948"; }

.icon-voice:before {
  content: "\e949"; }

.icon-voice_01:before {
  content: "\e94a"; }

.icon-wishlist:before {
  content: "\e94b"; }

.icon-wishlist_01-copy:before {
  content: "\e94c"; }

.icon-wishlist_01:before {
  content: "\e94d"; }

.icon-credit_card_02:before {
  content: "\e94e"; }

.icon-dollar:before {
  content: "\e94f"; }

.icon-credit_card:before {
  content: "\e950"; }

.icon-call-answer:before {
  content: "\e900"; }

.icon-envelope:before {
  content: "\e901"; }

.icon-case:before {
  content: "\e902"; }

.icon-requests:before {
  content: "\e951"; }

.cssload-spin-box {
  position: absolute;
  left: 55%;
  top: 55%;
  transform: translate(-50%, -55%);
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -o-box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -ms-box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -webkit-box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -moz-box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  animation: cssload-spin ease infinite 2.8s;
  -o-animation: cssload-spin ease infinite 2.8s;
  -ms-animation: cssload-spin ease infinite 2.8s;
  -webkit-animation: cssload-spin ease infinite 2.8s;
  -moz-animation: cssload-spin ease infinite 2.8s;
  z-index: 5555555555555555555; }

@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F; }
  25% {
    box-shadow: -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249; }
  50% {
    box-shadow: -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F; }
  75% {
    box-shadow: 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249; } }

@-o-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F; }
  25% {
    box-shadow: -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249; }
  50% {
    box-shadow: -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F; }
  75% {
    box-shadow: 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249; } }

@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F; }
  25% {
    box-shadow: -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249; }
  50% {
    box-shadow: -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F; }
  75% {
    box-shadow: 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249; } }

@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F; }
  25% {
    box-shadow: -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249; }
  50% {
    box-shadow: -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F; }
  75% {
    box-shadow: 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249; } }

@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F; }
  25% {
    box-shadow: -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249; }
  50% {
    box-shadow: -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F; }
  75% {
    box-shadow: 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249; } }

.I-admin-login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden; }
  .I-admin-login-page .I-right-side {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center; }
    .I-admin-login-page .I-right-side .I-right-side-content {
      width: 20%;
      height: 60%;
      position: relative; }
      .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-logo {
        display: flex;
        justify-content: center; }
        .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-logo img {
          width: 160px; }
      .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation {
        width: 100%;
        height: 200px;
        position: absolute;
        top: 30%; }
        .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form {
          width: 90%;
          margin: 0 auto; }
          .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form p {
            font-size: 18px; }
            .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form p:last-child {
              position: absolute;
              right: 5%; }
          .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form input,
          .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form button {
            width: 100%;
            height: 44px;
            padding: 5px;
            margin: 10px auto;
            border-radius: 8px;
            border: 1px solid;
            font-size: 18px; }
          .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form .I-password-input {
            display: flex;
            align-items: center; }
            .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form .I-password-input .I-password {
              width: 90%;
              border-right: 0;
              border-radius: 8px 0 0 8px; }
            .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form .I-password-input div {
              width: 10%;
              border: 1px solid;
              height: 44px;
              border-left: 0;
              border-radius: 0 8px 8px 0;
              display: flex;
              align-items: center; }
              .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form .I-password-input div img {
                width: 25px;
                cursor: pointer; }
          .I-admin-login-page .I-right-side .I-right-side-content .I-right-side-validation .form button {
            background-color: #1B313F;
            color: white;
            font-size: 15px; }

.I-products-page .I-drop-down {
  margin-right: 20px; }

.I-products-page .I-order-filter .I-order-input {
  display: flex;
  flex-direction: row; }
  .I-products-page .I-order-filter .I-order-input .I-drop-down {
    min-width: 340px; }
  .I-products-page .I-order-filter .I-order-input .I-drop-down-title {
    border: 1px solid #A9B2B8; }

.I-products-page .I-order-filter .I-filter-apply-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; }
  .I-products-page .I-order-filter .I-filter-apply-block > div {
    display: flex;
    align-items: center; }
    .I-products-page .I-order-filter .I-filter-apply-block > div .I-drop-down {
      min-width: 340px; }
  .I-products-page .I-order-filter .I-filter-apply-block .I-footer-apply {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    float: right; }
    .I-products-page .I-order-filter .I-filter-apply-block .I-footer-apply button {
      margin-left: 10px;
      padding: 10px 25px;
      border-radius: 8px;
      border: none;
      white-space: nowrap; }
      .I-products-page .I-order-filter .I-filter-apply-block .I-footer-apply button:first-child {
        background-color: white; }
      .I-products-page .I-order-filter .I-filter-apply-block .I-footer-apply button:last-child {
        background-color: #1B313F;
        color: white; }
  .I-products-page .I-order-filter .I-filter-apply-block .I-search-quantity {
    width: 44%;
    border: none;
    border-bottom: 1px solid #A9B2B8;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 22px; }
    .I-products-page .I-order-filter .I-filter-apply-block .I-search-quantity hr {
      height: 44px;
      border: 0.5px solid #A9B2B8; }
    .I-products-page .I-order-filter .I-filter-apply-block .I-search-quantity input {
      width: 85%;
      border: none;
      font-size: 18px;
      text-indent: 10px; }
      .I-products-page .I-order-filter .I-filter-apply-block .I-search-quantity input::placeholder {
        font-size: 16px; }

.I-products-page .I-data-table,
.I-products-page .I-table-footer {
  max-width: 90% !important;
  margin: 0 auto; }

.I-products-page .I-products-page-header {
  width: 90%;
  margin: 40px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .I-products-page .I-products-page-header .I-products-page-left {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .I-products-page .I-products-page-header .I-products-page-left p {
      position: absolute;
      bottom: -70%;
      font-weight: bold;
      white-space: nowrap; }
    .I-products-page .I-products-page-header .I-products-page-left a {
      width: 160px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      background-color: #1B313F;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
    .I-products-page .I-products-page-header .I-products-page-left .I-repeat-button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 5px;
      cursor: pointer; }
      .I-products-page .I-products-page-header .I-products-page-left .I-repeat-button i {
        font-size: 20px;
        transition: 1s; }
  .I-products-page .I-products-page-header .I-products-page-right {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-products-page .I-products-page-header .I-products-page-right button {
      width: 120px;
      background-color: white;
      height: 40px;
      border-radius: 5px;
      font-size: 15px;
      border: 1px solid #A5A6A7; }

.I-table-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.I-requests-page {
  width: 100%;
  height: 100vh;
  padding: 40px 80px;
  background-color: #F0F3F4; }
  .I-requests-page .I-data-table a {
    color: black !important; }
    .I-requests-page .I-data-table a:hover {
      text-decoration: underline; }
  .I-requests-page .I-request-page-toolbar {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    .I-requests-page .I-request-page-toolbar p {
      position: absolute;
      bottom: 10%;
      font-weight: bold;
      left: 0; }
    .I-requests-page .I-request-page-toolbar button {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #CBD1D4;
      margin-left: 15px; }
    .I-requests-page .I-request-page-toolbar .I-request-repeat {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid;
      border-radius: 5px; }
      .I-requests-page .I-request-page-toolbar .I-request-repeat i {
        font-size: 20px;
        cursor: pointer;
        transition: 1s; }
  .I-requests-page .I-request-page-content {
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid gray; }
    .I-requests-page .I-request-page-content .I-request-page-headings {
      width: 97%;
      padding: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bolder; }
      .I-requests-page .I-request-page-content .I-request-page-headings div {
        display: flex;
        justify-content: space-between;
        align-items: center; }
  .I-requests-page .I-request-page-list {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px; }
    .I-requests-page .I-request-page-list .I-request-checkbox {
      width: 100%;
      display: flex;
      align-items: center; }
    .I-requests-page .I-request-page-list .I-request-quantity {
      width: 108%; }
    .I-requests-page .I-request-page-list .I-request-page-hours {
      width: 114%;
      margin-right: 10px; }
    .I-requests-page .I-request-page-list .I-request-name {
      width: 115%; }
    .I-requests-page .I-request-page-list .I-request-page-status {
      width: 100%;
      height: 30px;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: white;
      border-radius: 10px;
      font-weight: bolder; }
    .I-requests-page .I-request-page-list .I-request-page-status-yellow {
      width: 35%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ECAD17;
      text-align: center;
      border-radius: 10px;
      font-weight: bolder;
      color: white; }

.I-request-page-footer {
  width: 100%;
  height: 60px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .I-request-page-footer .I-request-page-panel {
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-request-page-footer .I-request-page-panel .I-request-page-number {
      padding: 5px 15px;
      background-color: white;
      border: 1px solid gray;
      border-radius: 5px; }
    .I-request-page-footer .I-request-page-panel .I-request-page-slide {
      width: 100px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .I-request-page-footer .I-request-page-panel .I-request-page-slide div {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        background-color: #334856;
        color: white;
        cursor: pointer; }
        .I-request-page-footer .I-request-page-panel .I-request-page-slide div i {
          font-size: 18px; }

.I-request-repeat {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid;
  border-radius: 5px; }
  .I-request-repeat i {
    font-size: 20px;
    cursor: pointer;
    transition: 1s; }

.I-attribute-page {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .I-attribute-page .I-data-table,
  .I-attribute-page .I-table-footer {
    width: 90%;
    margin: 0 auto; }
  .I-attribute-page .I-attribute-page-header {
    width: 90%;
    margin: 0 auto;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    .I-attribute-page .I-attribute-page-header p {
      position: absolute;
      bottom: 10%;
      font-weight: bold;
      left: 0; }
    .I-attribute-page .I-attribute-page-header > button {
      width: 160px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      background-color: #1B313F;
      color: white; }
    .I-attribute-page .I-attribute-page-header .I-attribute-page-header-actions {
      display: flex;
      align-items: center;
      height: 100%; }
      .I-attribute-page .I-attribute-page-header .I-attribute-page-header-actions > button {
        width: 120px;
        height: 40px;
        border-radius: 5px;
        background-color: white;
        border: 1px solid #CBD1D4;
        margin-left: 20px; }

.I-attribute-add {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
  position: relative; }
  .I-attribute-add .I-attribute-add-main {
    width: 50vw;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    padding: 40px;
    background-color: white; }
    .I-attribute-add .I-attribute-add-main .I-attribute-add-body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 40px; }
      .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item {
        height: 65px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item > div {
          display: flex;
          flex-direction: row; }
          .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item > div > p {
            margin-left: 10px; }
        .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-attribute-add-item-img {
          width: 16px;
          height: 16px;
          background-position: center;
          background-size: cover; }
        .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item > input {
          width: 100%;
          height: 35px;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          padding-left: 7px;
          font-size: 14px; }
        .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-drop-down {
          width: 100%; }
          .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-drop-down > div {
            width: 100%; }
            .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-drop-down > div > div {
              width: 100%;
              height: 35px; }
              .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-drop-down > div > div > div {
                color: rgba(0, 0, 0, 0.3); }
                .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-drop-down > div > div > div > p {
                  color: black;
                  font-size: 14px; }
        .I-attribute-add .I-attribute-add-main .I-attribute-add-body .I-attribute-add-item .I-attribute-disable-item {
          width: 100%;
          height: 35px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          align-items: center;
          font-size: 14px;
          background-color: rgba(0, 0, 0, 0.05);
          cursor: not-allowed; }
  .I-attribute-add .I-attribute-add-option {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    margin-top: 10px;
    background-color: white; }
    .I-attribute-add .I-attribute-add-option .I-attribute-add-option-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 40px; }
      .I-attribute-add .I-attribute-add-option .I-attribute-add-option-header .I-attribute-add-buttons {
        display: flex; }
        .I-attribute-add .I-attribute-add-option .I-attribute-add-option-header .I-attribute-add-buttons button {
          width: 120px;
          height: 40px;
          border-radius: 5px;
          align-items: center;
          font-size: 14px;
          border: 1px solid rgba(0, 0, 0, 0.4);
          background-color: white;
          color: black;
          margin-left: 25px; }
        .I-attribute-add .I-attribute-add-option .I-attribute-add-option-header .I-attribute-add-buttons .disabled {
          background-color: rgba(0, 0, 0, 0.1);
          color: rgba(0, 0, 0, 0.3);
          border: none;
          cursor: default; }
    .I-attribute-add .I-attribute-add-option > .I-attribute-add-option-body-header {
      width: 100%;
      height: 35px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 110px 0 40px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
    .I-attribute-add .I-attribute-add-option > .I-attribute-add-option-body-body {
      width: 100%;
      padding: 20px 110px 100px 40px; }
      .I-attribute-add .I-attribute-add-option > .I-attribute-add-option-body-body > div {
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
    .I-attribute-add .I-attribute-add-option > .I-data-table {
      box-shadow: none;
      margin-top: -20px; }
      .I-attribute-add .I-attribute-add-option > .I-data-table .I-data-table-header {
        font-weight: 400; }
        .I-attribute-add .I-attribute-add-option > .I-data-table .I-data-table-header > li {
          font-size: 16px;
          overflow: auto; }
      .I-attribute-add .I-attribute-add-option > .I-data-table .I-data-table-body > ul:hover {
        background-color: white !important;
        cursor: default; }
    .I-attribute-add .I-attribute-add-option .I-data-table-body ul li {
      position: relative; }
      .I-attribute-add .I-attribute-add-option .I-data-table-body ul li:nth-child(3) {
        position: absolute;
        left: 50%;
        overflow: unset !important; }
      .I-attribute-add .I-attribute-add-option .I-data-table-body ul li:nth-child(2) {
        position: absolute;
        left: 35%;
        overflow: unset !important; }
    .I-attribute-add .I-attribute-add-option .I-option-input {
      width: 40px;
      height: 25px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #A5A6A7;
      color: black; }
  .I-attribute-add .I-attribute-add-save {
    width: 50vw;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    padding: 20px 40px;
    margin-top: 50px;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 1000; }
    .I-attribute-add .I-attribute-add-save > div {
      width: 180px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .I-attribute-add .I-attribute-add-save > div .cancel {
        border: none;
        background-color: white; }
      .I-attribute-add .I-attribute-add-save > div .save {
        width: 100px;
        height: 32px;
        border-radius: 5px;
        align-items: center;
        border: none;
        background-color: #1B313F;
        color: white; }
      .I-attribute-add .I-attribute-add-save > div .disabled {
        background-color: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.3);
        border: none;
        cursor: default; }

.I-add-color-option {
  height: 65vh; }

.I-add-usual-option {
  height: 45vh; }

.I-add-option {
  width: 35vw;
  min-height: 550px;
  display: flex;
  flex-direction: column; }
  .I-add-option .I-add-option-header {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid grey; }
    .I-add-option .I-add-option-header > p {
      font-size: 20px;
      font-weight: 500; }
  .I-add-option .I-add-option-body {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 30px 30px 30px; }
    .I-add-option .I-add-option-body .I-add-option-item {
      height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .I-add-option .I-add-option-body .I-add-option-item > div {
        display: flex;
        flex-direction: row; }
        .I-add-option .I-add-option-body .I-add-option-item > div > p {
          margin-left: 10px; }
      .I-add-option .I-add-option-body .I-add-option-item .I-add-option-item-img {
        width: 16px;
        height: 16px;
        background-position: center;
        background-size: cover; }
      .I-add-option .I-add-option-body .I-add-option-item > input {
        width: 100%;
        height: 35px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding-left: 7px;
        font-size: 14px; }
      .I-add-option .I-add-option-body .I-add-option-item .I-drop-down {
        width: 100%; }
        .I-add-option .I-add-option-body .I-add-option-item .I-drop-down > div {
          width: 100%; }
          .I-add-option .I-add-option-body .I-add-option-item .I-drop-down > div > div {
            width: 100%;
            height: 35px; }
            .I-add-option .I-add-option-body .I-add-option-item .I-drop-down > div > div > div {
              color: rgba(0, 0, 0, 0.3); }
              .I-add-option .I-add-option-body .I-add-option-item .I-drop-down > div > div > div > p {
                color: black;
                font-size: 14px; }
    .I-add-option .I-add-option-body .I-add-option-color-block {
      width: 100%;
      height: 35px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-one-color-item {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 5px;
        padding-left: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3); }
        .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-one-color-item > .S-click-outside {
          width: 100%;
          position: absolute;
          bottom: 35px;
          left: 0; }
          .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-one-color-item > .S-click-outside > .chrome-picker {
            width: 100% !important;
            border-radius: 5px !important;
            box-shadow: 0 0 5px -2px !important; }
        .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-one-color-item .I-add-option-one-color-block {
          width: 100px;
          height: 80%;
          border-radius: 5px;
          cursor: pointer; }
      .I-add-option .I-add-option-body .I-add-option-color-block p {
        margin-left: 15px; }
      .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-two-color-item {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 5px;
        padding-left: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3); }
        .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-two-color-item .I-add-option-two-color-block {
          width: 100px;
          height: 80%;
          border-radius: 5px;
          cursor: pointer; }
        .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-two-color-item > .S-click-outside {
          width: 91%;
          position: absolute;
          bottom: 145px;
          left: 30px; }
          .I-add-option .I-add-option-body .I-add-option-color-block .I-add-option-two-color-item > .S-click-outside > .chrome-picker {
            width: 100% !important;
            border-radius: 5px !important;
            box-shadow: 0 0 5px -2px !important; }
  .I-add-option .I-add-option-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 30px 5px 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.3); }
    .I-add-option .I-add-option-footer > div {
      width: 200px;
      height: 45px; }
      .I-add-option .I-add-option-footer > div button {
        height: 35px;
        width: 100px; }
      .I-add-option .I-add-option-footer > div .cancel {
        border: none;
        background-color: white; }
      .I-add-option .I-add-option-footer > div .save {
        width: 100px;
        height: 32px;
        border-radius: 5px;
        align-items: center;
        border: none;
        background-color: #1B313F;
        color: white; }
      .I-add-option .I-add-option-footer > div .disabled {
        background-color: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.3);
        border: none;
        cursor: default; }

.I-categories-page {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0; }
  .I-categories-page .I-partners-page-header {
    width: 100%;
    margin: 40px auto;
    position: relative; }
    .I-categories-page .I-partners-page-header a {
      width: 160px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      background-color: #1B313F;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
    .I-categories-page .I-partners-page-header p {
      position: absolute;
      bottom: -70%;
      font-weight: bold;
      left: 0; }
  .I-categories-page .I-categories-page-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-categories-page .I-categories-page-header a {
      width: 160px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      background-color: #1B313F;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
  .I-categories-page .I-categories-page-drag-drop {
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin-top: 25px; }
    .I-categories-page .I-categories-page-drag-drop .rst__tree {
      padding: 20px 0; }
    .I-categories-page .I-categories-page-drag-drop .I-drag-drop-list {
      width: 700px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .I-categories-page .I-categories-page-drag-drop .I-drag-drop-list .I-category-item-name {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-categories-page .I-categories-page-drag-drop .I-drag-drop-list .I-category-item-name img {
          width: 20px;
          margin-right: 25px; }
      .I-categories-page .I-categories-page-drag-drop .I-drag-drop-list i {
        padding: 7px;
        cursor: pointer;
        transition: 0.3s;
        font-size: 15px;
        margin-left: 20px;
        color: black; }
        .I-categories-page .I-categories-page-drag-drop .I-drag-drop-list i:hover {
          border-radius: 50%;
          color: #3b4042;
          background-color: #e8eaed; }
      .I-categories-page .I-categories-page-drag-drop .I-drag-drop-list .I-add-category {
        width: 20px;
        position: absolute;
        right: -5%;
        cursor: pointer; }
    .I-categories-page .I-categories-page-drag-drop .I-sub-categories {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .I-categories-page .I-categories-page-drag-drop .I-sub-categories div:first-child {
        overflow: hidden;
        text-overflow: ellipsis; }
      .I-categories-page .I-categories-page-drag-drop .I-sub-categories i {
        padding: 7px;
        cursor: pointer;
        transition: 0.3s;
        font-size: 15px;
        margin-left: 20px;
        color: black; }
        .I-categories-page .I-categories-page-drag-drop .I-sub-categories i:hover {
          border-radius: 50%;
          color: #3b4042;
          background-color: #e8eaed; }
      .I-categories-page .I-categories-page-drag-drop .I-sub-categories .I-add-category {
        width: 20px;
        position: absolute;
        right: -10%;
        cursor: pointer; }
    .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop {
      width: 100% !important;
      height: 70px;
      padding: 20px;
      background-color: white;
      cursor: move;
      resize: none;
      border-top: 1px solid #ccd1d4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      position: relative; }
      .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop:hover .I-drag-drop-edit i {
        opacity: 1; }
      .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop:first-child {
        border-top: none; }
      .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop .I-drag-drop-icons {
        display: flex;
        align-items: center; }
        .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop .I-drag-drop-icons img {
          width: 30px;
          margin-right: 30px; }
        .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop .I-drag-drop-icons i {
          margin-right: 30px; }
      .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop .I-drag-drop-edit i {
        cursor: pointer;
        margin-left: 30px;
        opacity: 0;
        transition: 0.7s; }
        .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-drag-drop .I-drag-drop-edit i:hover {
          font-weight: bolder; }
    .I-categories-page .I-categories-page-drag-drop .I-main-drag .I-sub-categories {
      width: 100% !important;
      height: 70px;
      padding: 20px 60px;
      cursor: move;
      resize: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      position: relative; }

.I-request-details-page {
  width: 90%;
  margin: 25px auto;
  display: flex;
  justify-content: space-between; }
  .I-request-details-page .I-request-details-right-side {
    width: 70%;
    border-radius: 10px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    background-color: white; }
    .I-request-details-page .I-request-details-right-side .I-request-details-right-content {
      width: 95%;
      margin: 25px auto;
      padding: 0 40px;
      position: relative; }
      .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-headings {
        width: 70%;
        padding: 0 20px;
        display: flex;
        align-items: center; }
        .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-headings span {
          color: #A5A6A7;
          width: 100%; }
      .I-request-details-page .I-request-details-right-side .I-request-details-right-content > i {
        width: 20px;
        color: #A5A6A7;
        cursor: pointer;
        font-weight: bolder;
        display: flex;
        align-items: center;
        position: absolute;
        right: 1%;
        top: 0; }
      .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-small-content {
        width: 80%;
        padding: 0 20px;
        display: flex;
        align-items: center; }
        .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-small-content span {
          width: 100%;
          text-align: justify; }
      .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-responses {
        width: 70%;
        padding: 0 20px;
        margin-top: 10px;
        display: flex;
        align-items: center; }
        .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-responses span {
          width: 100%;
          text-align: justify;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-responses .I-drop-down {
          position: absolute;
          right: 5%;
          min-width: 180px !important; }
          .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-responses .I-drop-down .Open {
            height: 150px !important; }
          .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-responses .I-drop-down span {
            width: 25%; }
        .I-request-details-page .I-request-details-right-side .I-request-details-right-content .I-right-side-responses .I-drop-down .I-drop-down-title p {
          width: 145%; }
    .I-request-details-page .I-request-details-right-side .I-right-side-description {
      width: 95%;
      padding: 0 20px;
      margin-top: 15px; }
      .I-request-details-page .I-request-details-right-side .I-right-side-description p {
        color: #A5A6A7;
        margin-bottom: 15px;
        text-align: justify; }
        .I-request-details-page .I-request-details-right-side .I-right-side-description p:last-child {
          color: black; }
    .I-request-details-page .I-request-details-right-side .I-right-side-file {
      width: 95%;
      margin: 15px auto; }
      .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-files audio, .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-files video, .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-files span {
        margin-top: 15px; }
      .I-request-details-page .I-request-details-right-side .I-right-side-file p {
        color: #A5A6A7;
        margin-bottom: 15px; }
      .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-right-side-pdf {
        width: 100%;
        display: flex;
        align-items: center; }
        .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-right-side-pdf i {
          font-size: 22px; }
        .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-right-side-pdf a {
          text-decoration: none;
          color: black;
          margin-left: 25px; }
      .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-image {
        width: 100%;
        align-items: center;
        border-radius: 8px;
        margin-top: 15px; }
        .I-request-details-page .I-request-details-right-side .I-right-side-file .I-request-image .I-request-right-side-img {
          width: 200px;
          height: 150px;
          border: 2px solid #A5A6A7;
          border-radius: 8px;
          cursor: pointer;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat; }
    .I-request-details-page .I-request-details-right-side .I-right-side-attach-product {
      width: 95%;
      margin: 25px auto; }
      .I-request-details-page .I-request-details-right-side .I-right-side-attach-product p {
        color: #A5A6A7;
        margin-bottom: 15px; }
      .I-request-details-page .I-request-details-right-side .I-right-side-attach-product div {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #F0F3F4;
        cursor: pointer;
        position: relative; }
        .I-request-details-page .I-request-details-right-side .I-right-side-attach-product div i {
          font-size: 30px;
          color: #939EA6; }
        .I-request-details-page .I-request-details-right-side .I-right-side-attach-product div span {
          font-size: 18px;
          color: #939EA6; }
    .I-request-details-page .I-request-details-right-side .I-attached-headings {
      width: 90%;
      padding: 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #A5A6A7; }
    .I-request-details-page .I-request-details-right-side .I-right-side-attach {
      width: 95%;
      margin: 15px auto;
      border: 0;
      border-top: 1px solid #A5A6A7; }
      .I-request-details-page .I-request-details-right-side .I-right-side-attach .I-product-attach-details {
        width: 95%;
        height: 70px;
        display: flex;
        align-items: center;
        margin-top: 15px; }
        .I-request-details-page .I-request-details-right-side .I-right-side-attach .I-product-attach-details .I-attach-img {
          width: 50px;
          height: 50px;
          border: 1px solid #E2E2E2;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 25px; }
          .I-request-details-page .I-request-details-right-side .I-right-side-attach .I-product-attach-details .I-attach-img img {
            width: 43px;
            height: 43px;
            border-radius: 10px; }

.I-modal-picture {
  width: 750px;
  height: 550px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.I-status-dropdown {
  width: 200px;
  height: 200px;
  background-color: red;
  position: absolute; }

.I-uploaded-image {
  width: calc(25% - 30px);
  margin: 30px 15px 0 15px;
  height: 200px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px; }
  .I-uploaded-image:hover > .I-delete-wrapper {
    display: flex; }
  .I-uploaded-image > .I-delete-wrapper {
    width: 20%;
    padding: 20px;
    color: white;
    display: none;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5555555555555555; }

.I-add-product-page {
  padding: 80px 0; }
  .I-add-product-page .I-add-product-headings {
    width: 60%;
    margin: 0 auto; }
    .I-add-product-page .I-add-product-headings h5 {
      color: #AEB2B3; }
  .I-add-product-page .I-add-product-step {
    width: 50vw;
    min-height: 75vh;
    margin: 20px auto;
    padding: 30px 0;
    box-shadow: 0 0 25px -10px;
    background-color: white;
    border-radius: 5px; }
    .I-add-product-page .I-add-product-step .I-add-product-images {
      padding: 40px 0; }
      .I-add-product-page .I-add-product-step .I-add-product-images .I-uploader {
        width: calc(100% - 80px);
        color: #b6bec3;
        cursor: pointer;
        margin: 0 40px;
        padding: 60px 0;
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        text-align: center;
        background: #eff3f4;
        border-radius: 12px;
        justify-content: center; }
        .I-add-product-page .I-add-product-step .I-add-product-images .I-uploader > i {
          width: 100%;
          font-size: 40px;
          margin-bottom: 10px; }
        .I-add-product-page .I-add-product-step .I-add-product-images .I-uploader > input {
          display: none; }
      .I-add-product-page .I-add-product-step .I-add-product-images .I-uploaded-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap; }
      .I-add-product-page .I-add-product-step .I-add-product-images .I-uploaded-images {
        width: calc(100% - 60px);
        margin: 0 30px 0;
        display: flex;
        flex-wrap: wrap; }
    .I-add-product-page .I-add-product-step .I-select-language {
      width: 90%;
      height: 80px;
      margin: 20px auto;
      border-bottom: 1px solid #97A2A8;
      display: flex;
      align-items: flex-end; }
      .I-add-product-page .I-add-product-step .I-select-language .I-active-border {
        border-bottom: 5px solid #1B313F; }
      .I-add-product-page .I-add-product-step .I-select-language div {
        padding: 5px 0;
        margin-right: 20px;
        width: 110px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: 5px solid transparent; }
        .I-add-product-page .I-add-product-step .I-select-language div img {
          width: 18px;
          margin-right: 10px; }
    .I-add-product-page .I-add-product-step .I-add-product-content {
      width: 90%;
      margin: 20px auto;
      padding-bottom: 30px; }
      .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-brand {
        position: relative;
        margin-top: 20px; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-brand > .I-brand-drop-down {
          width: 100%;
          height: auto;
          background-color: white;
          position: absolute;
          left: 0;
          top: 68px;
          border-radius: 8px;
          box-shadow: 0 0 25px -10px;
          padding: 0 20px;
          font-weight: bolder;
          overflow-y: auto;
          max-height: 200px;
          z-index: 100; }
          .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-brand > .I-brand-drop-down p {
            cursor: pointer;
            margin-top: 10px; }
      .I-add-product-page .I-add-product-step .I-add-product-content .I-attributes-image-choose {
        width: 45px;
        height: 45px;
        border-radius: 8px;
        border: 1px solid #999;
        cursor: pointer;
        position: relative; }
        .I-add-product-page .I-add-product-step .I-add-product-content .I-attributes-image-choose > div {
          width: 300px;
          height: 300px;
          padding: 20px;
          background: white; }
      .I-add-product-page .I-add-product-step .I-add-product-content > .I-generate-variants {
        color: white;
        border: 0;
        margin: 20px 0 40px;
        padding: 10px 20px;
        background: #1B313F;
        border-radius: 8px; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-generate-variants:first-of-type {
          margin-right: 20px; }
      .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-multi-select {
        padding-top: 40px; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-multi-select > span {
          display: none !important; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-multi-select > div:first-of-type {
          z-index: 1000;
          border: 1px solid #999 !important;
          min-height: 40px;
          box-shadow: none; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-product-multi-select > div:last-child {
          z-index: 50000000; }
      .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing {
        padding-top: 40px; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > h2 {
          width: 100%;
          margin: 30px 0;
          font-weight: 300; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > h2:first-child {
            margin-top: 0; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > .I-product-pricing-date-input {
          float: left;
          width: calc(50% - 10px);
          margin-right: 20px; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > .I-product-pricing-date-input:last-child {
            margin-right: 0; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > .I-product-pricing-date-input > .react-datepicker-wrapper {
            width: 100%;
            margin-top: 10px; }
            .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > .I-product-pricing-date-input > .react-datepicker-wrapper > .react-datepicker__input-container {
              width: 100%; }
              .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-pricing > .I-product-pricing-date-input > .react-datepicker-wrapper > .react-datepicker__input-container > input {
                width: 100%; }
      .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories {
        min-height: inherit;
        padding-top: 30px; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories > .I-tree {
          height: 0;
          min-height: inherit; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories .rst__moveHandle {
          display: none; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories .rst__rowContents {
          border: 1px solid #999;
          box-shadow: none; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories .rst__rowTitle {
          display: flex; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories .rst__rowTitle > .I-checkbox {
            margin-right: 10px; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-categories .rst__rowTitle > .I-categories-include-line {
            content: ' ';
            cursor: pointer;
            position: absolute;
            width: 22px;
            height: 22px;
            left: -34px;
            top: 9px;
            border: 2px solid #1B313F;
            border-radius: 50%; }
      .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type {
        width: 100%;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 0; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type > .I-type-choose-content {
          width: calc(50% - 10px);
          margin: 0 20px 30px 0; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type > .I-type-choose-content > .I-add-product-text-switch {
            display: flex;
            width: 100%;
            justify-content: space-between;
            border: 1px solid #CBD1D4;
            padding: 5px;
            margin-top: 10px;
            height: 38px;
            border-radius: 8px; }
            .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type > .I-type-choose-content > .I-add-product-text-switch > div {
              border-radius: 8px;
              cursor: pointer;
              display: flex;
              width: calc(50% - 5px);
              transition: .2s;
              align-items: center;
              justify-content: center; }
              .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type > .I-type-choose-content > .I-add-product-text-switch > div.I-active-text {
                color: white;
                background: #1B313F; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type > .I-type-choose-content:nth-of-type(2), .I-add-product-page .I-add-product-step .I-add-product-content.I-add-product-type > .I-type-choose-content:nth-of-type(5) {
            margin-right: 0; }
      .I-add-product-page .I-add-product-step .I-add-product-content .I-add-new {
        width: 100%;
        color: #1B313F;
        cursor: pointer; }
      .I-add-product-page .I-add-product-step .I-add-product-content > .I-pricing-default-price {
        margin-bottom: 20px; }
      .I-add-product-page .I-add-product-step .I-add-product-content > .I-pricing-row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        justify-content: space-between; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-pricing-row > .I-pricing-content {
          width: calc(100% / 3 - 20px);
          float: left; }
        .I-add-product-page .I-add-product-step .I-add-product-content > .I-pricing-row > .icon-close {
          cursor: pointer;
          font-size: 20px;
          padding-bottom: 10px; }
      .I-add-product-page .I-add-product-step .I-add-product-content.I-add-features-content {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-features-content > .I-features-content {
          width: calc(50% - 50px);
          margin-right: 30px;
          margin-bottom: 20px; }
        .I-add-product-page .I-add-product-step .I-add-product-content.I-add-features-content > .icon-close {
          cursor: pointer;
          height: 68px;
          font-size: 25px;
          margin-left: 15px;
          line-height: 95px; }
          .I-add-product-page .I-add-product-step .I-add-product-content.I-add-features-content > .icon-close.I-disabled-remove {
            cursor: not-allowed;
            opacity: .3; }
      .I-add-product-page .I-add-product-step .I-add-product-content input {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #CBD1D4; }
      .I-add-product-page .I-add-product-step .I-add-product-content .I-product-name-input {
        width: 100%;
        margin-top: 10px; }
      .I-add-product-page .I-add-product-step .I-add-product-content .I-product-articles {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px; }
        .I-add-product-page .I-add-product-step .I-add-product-content .I-product-articles > p {
          width: calc(50% - 10px); }
          .I-add-product-page .I-add-product-step .I-add-product-content .I-product-articles > p:first-child {
            margin-right: 20px; }
      .I-add-product-page .I-add-product-step .I-add-product-content .I-product-two-content {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .I-add-product-page .I-add-product-step .I-add-product-content .I-product-two-content input {
          width: calc(50% - 10px);
          height: 44px;
          border: 1px solid #CBD1D4; }
        .I-add-product-page .I-add-product-step .I-add-product-content .I-product-two-content > .I-drop-down {
          width: calc(50% - 10px); }
      .I-add-product-page .I-add-product-step .I-add-product-content .I-products-description {
        margin-top: 20px; }
        .I-add-product-page .I-add-product-step .I-add-product-content .I-products-description .I-product-description-input {
          width: 100%;
          border: 1px solid #CBD1D4;
          height: 100px;
          overflow-y: hidden;
          margin-top: 20px; }
    .I-add-product-page .I-add-product-step .I-products-footer {
      width: 50vw;
      height: 70px;
      bottom: 0;
      z-index: 1;
      position: absolute;
      background-color: white;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 0 25px -10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 40px; }
      .I-add-product-page .I-add-product-step .I-products-footer .I-finish-button {
        background-color: #1B313F;
        color: white; }
      .I-add-product-page .I-add-product-step .I-products-footer button {
        margin-left: 20px;
        width: 100px;
        height: 40px;
        border: none;
        border-radius: 5px;
        background-color: white; }
        .I-add-product-page .I-add-product-step .I-products-footer button.I-products-back {
          width: auto;
          margin: 0 auto 0 0;
          background-color: white !important; }
        .I-add-product-page .I-add-product-step .I-products-footer button:last-child {
          background-color: #1B313F;
          color: white; }
  .I-add-product-page .disabled {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: rgba(0, 0, 0, 0.3) !important;
    border: none;
    cursor: default; }

.I-attributes-image-modal {
  width: 600px;
  height: 400px;
  padding: 20px;
  overflow-y: auto; }
  .I-attributes-image-modal > div {
    width: calc(100% / 3 - 20px);
    height: 150px;
    margin: 10px;
    cursor: pointer;
    float: left;
    border-radius: 8px; }
    .I-attributes-image-modal > div.I-choose-no-image {
      background: #eee;
      display: flex;
      justify-content: center;
      align-items: center; }

.I-category-details {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .I-category-details .I-category-content {
    width: 60vw;
    min-height: 75vh;
    padding: 30px 0;
    background-color: white;
    box-shadow: 0 0 25px -10px;
    border-radius: 5px; }
    .I-category-details .I-category-content h3 {
      margin-top: 25px;
      margin-left: 45px; }
    .I-category-details .I-category-content .I-category-languages {
      width: 90%;
      margin: 0 auto;
      padding-top: 40px; }
      .I-category-details .I-category-content .I-category-languages input {
        width: 100%;
        height: 35px;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #A9B2B8;
        font-size: 14px;
        padding-left: 7px; }
      .I-category-details .I-category-content .I-category-languages textarea {
        width: 100%;
        height: 100px;
        border-radius: 5px;
        border: 1px solid #A9B2B8;
        text-indent: 20px;
        font-size: 18px;
        resize: none;
        padding: 10px; }
      .I-category-details .I-category-content .I-category-languages div {
        margin-bottom: 10px;
        font-size: 16px; }
        .I-category-details .I-category-content .I-category-languages div img {
          width: 16px;
          height: 16px;
          margin-right: 10px; }
      .I-category-details .I-category-content .I-category-languages label {
        width: 341px;
        height: 245px;
        padding: 100px;
        border-radius: 8px;
        background: #f0f3f4;
        margin-top: 20px;
        display: table;
        font-size: 18px;
        color: #a0aab0;
        cursor: pointer;
        position: relative; }
        .I-category-details .I-category-content .I-category-languages label .I-uploaded-background {
          width: 200px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .I-category-details .I-category-content .I-category-languages label input[type="file"] {
          display: none; }
        .I-category-details .I-category-content .I-category-languages label .I-upload-image {
          width: 100%;
          height: 100%;
          position: absolute;
          border: 0;
          left: 0;
          top: -1%;
          border-radius: 8px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat; }
          .I-category-details .I-category-content .I-category-languages label .I-upload-image:hover {
            background-color: rgba(0, 0, 0, 0.3); }
      .I-category-details .I-category-content .I-category-languages .I-categories-icons {
        width: 100%;
        display: flex;
        justify-content: space-between; }
  .I-category-details .I-category-add-footer {
    min-width: 60vw;
    height: 60px;
    background-color: white;
    box-shadow: 0 0 25px -10px;
    border-radius: 5px;
    bottom: 0;
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center; }
    .I-category-details .I-category-add-footer div {
      width: 95%;
      display: flex;
      justify-content: flex-end; }
      .I-category-details .I-category-add-footer div button {
        width: 120px;
        height: 40px;
        margin-left: 20px;
        border-radius: 8px;
        border: none; }
        .I-category-details .I-category-add-footer div button:first-child {
          background-color: white;
          color: black; }
    .I-category-details .I-category-add-footer .save {
      background-color: #1B313F;
      color: white; }
    .I-category-details .I-category-add-footer .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }

.I-user-details {
  width: 90%;
  margin: 40px auto;
  display: flex;
  justify-content: space-between; }
  .I-user-details .I-user-details-info {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .I-user-details .I-user-details-info > div {
      width: 100%;
      height: auto;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      background-color: white;
      border-radius: 10px;
      margin-bottom: 20px; }
    .I-user-details .I-user-details-info .I-order-details-name {
      width: 100%;
      padding: 10px 30px;
      display: flex;
      flex-direction: column; }
      .I-user-details .I-user-details-info .I-order-details-name i {
        font-size: 15px;
        color: #1B313F; }
      .I-user-details .I-user-details-info .I-order-details-name .I-details-name {
        font-size: 22px;
        font-weight: bold; }
      .I-user-details .I-user-details-info .I-order-details-name hr {
        width: 80px;
        border: 2px solid #1B313F;
        border-radius: 20px; }
      .I-user-details .I-user-details-info .I-order-details-name div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px; }
        .I-user-details .I-user-details-info .I-order-details-name div span {
          width: 100%;
          margin-left: 10px; }
    .I-user-details .I-user-details-info .I-user-details-counts {
      width: 100%;
      padding: 10px 30px;
      display: flex;
      flex-direction: column; }
      .I-user-details .I-user-details-info .I-user-details-counts div {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-user-details .I-user-details-info .I-user-details-counts div span {
          width: 100%; }
          .I-user-details .I-user-details-info .I-user-details-counts div span:first-child {
            width: 250px;
            font-weight: bold;
            font-size: 15px; }
          .I-user-details .I-user-details-info .I-user-details-counts div span:last-child {
            width: 200px;
            font-weight: bold;
            font-size: 15px; }
    .I-user-details .I-user-details-info .I-block-user {
      width: 100%;
      height: 100px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      font-weight: bold; }
      .I-user-details .I-user-details-info .I-block-user input:checked + .slider {
        background-color: red; }
      .I-user-details .I-user-details-info .I-block-user input:focus + .slider {
        box-shadow: 0 0 1px red; }
    .I-user-details .I-user-details-info .I-choose-status {
      width: 100%;
      height: 100px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      font-weight: bold; }
      .I-user-details .I-user-details-info .I-choose-status > div {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .I-user-details .I-user-details-info .I-choose-status > div:nth-child(2) {
          color: silver; }
        .I-user-details .I-user-details-info .I-choose-status > div:last-child {
          color: gold; }
        .I-user-details .I-user-details-info .I-choose-status > div input {
          width: 20px;
          height: 20px;
          margin-top: 10px; }
  .I-user-details .I-user-page-right-side {
    width: 70%;
    display: flex;
    flex-direction: column; }
    .I-user-details .I-user-page-right-side .I-user-choose-status {
      width: 100%;
      height: 70px;
      padding: 0 40px;
      display: flex;
      align-items: center;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      background-color: white;
      border-radius: 10px; }
      .I-user-details .I-user-page-right-side .I-user-choose-status .I-choose-list {
        height: 30px;
        margin-right: 15px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer; }
      .I-user-details .I-user-page-right-side .I-user-choose-status .I-selected-list {
        border-bottom: 3px solid #1B313F; }
    .I-user-details .I-user-page-right-side .I-user-orders-requests {
      margin-top: 25px; }

.I-brands-page .I-brands-filter {
  width: 100%;
  height: 150px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 16px -10px;
  padding: 20px 80px; }
  .I-brands-page .I-brands-filter .I-brands-input {
    display: flex;
    align-items: baseline; }
    .I-brands-page .I-brands-filter .I-brands-input .I-search-quantity {
      margin-left: 25px; }

.I-brands-page .I-data-table,
.I-brands-page .I-table-footer {
  width: 90%;
  margin: 0 auto; }

.I-brands-table-header {
  width: 90%;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .I-brands-table-header .I-brands-toolbar {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .I-brands-table-header .I-brands-toolbar p {
      position: absolute;
      bottom: -70%;
      font-weight: bold;
      left: 0; }
    .I-brands-table-header .I-brands-toolbar button {
      width: 160px; }
    .I-brands-table-header .I-brands-toolbar div {
      cursor: pointer; }

.I-order-details {
  width: 90%;
  margin: 40px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .I-order-details .I-order-details-info {
    width: 25%;
    height: 100%; }
    .I-order-details .I-order-details-info > div {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      padding: 20px; }
    .I-order-details .I-order-details-info .I-order-details-name {
      width: 100%; }
      .I-order-details .I-order-details-info .I-order-details-name i {
        font-size: 15px;
        color: #1B313F; }
      .I-order-details .I-order-details-info .I-order-details-name .I-details-name {
        font-size: 22px;
        font-weight: bold; }
      .I-order-details .I-order-details-info .I-order-details-name hr {
        width: 80px;
        border: 2px solid #1B313F;
        border-radius: 20px; }
      .I-order-details .I-order-details-info .I-order-details-name div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px; }
        .I-order-details .I-order-details-info .I-order-details-name div span {
          width: 100%;
          margin-left: 10px; }
    .I-order-details .I-order-details-info .I-details-addresses {
      display: flex;
      flex-direction: column;
      position: relative; }
      .I-order-details .I-order-details-info .I-details-addresses hr {
        width: 80px;
        border: 2px solid #1B313F;
        border-radius: 20px; }
      .I-order-details .I-order-details-info .I-details-addresses i {
        font-size: 15px;
        color: #1B313F; }
      .I-order-details .I-order-details-info .I-details-addresses > div {
        width: 85%;
        height: 100%;
        margin-top: 20px; }
        .I-order-details .I-order-details-info .I-details-addresses > div > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px; }
          .I-order-details .I-order-details-info .I-details-addresses > div > div span {
            width: 100%;
            margin-left: 10px;
            overflow: hidden; }
  .I-order-details .I-order-details-right-side {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .I-order-details .I-order-details-right-side > div {
      background-color: white;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px; }
    .I-order-details .I-order-details-right-side .I-order-details-right-content {
      width: 100%;
      margin: 0 auto;
      padding: 0 40px; }
      .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-right-side-headings {
        margin: 25px auto;
        padding: 0 20px;
        display: flex;
        align-items: center; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-right-side-headings div {
          color: #A5A6A7;
          width: 100%; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-right-side-headings .I-icon-down-div {
          position: absolute;
          left: 85%; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-right-side-headings .I-icon-down-div i {
            width: 20px;
            color: #A5A6A7;
            cursor: pointer;
            font-weight: bolder;
            display: flex;
            align-items: center; }
      .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-details-responses {
        width: 95%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-details-responses div {
          width: 100%;
          text-align: justify;
          color: black; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-order-details-responses .I-drop-down {
          min-width: 200px; }
      .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-headings {
        width: 95%;
        height: 50px;
        margin: 25px auto;
        border-bottom: 1px solid #e1e2e3;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-headings > .align-cols {
          display: flex;
          justify-content: flex-end;
          padding-left: 300px; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-headings > .align-cols > div {
            flex: 1; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-headings div {
          flex: 1;
          font-weight: bold; }
      .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description {
        width: 95%;
        margin: 25px auto;
        display: flex;
        flex-direction: column; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block {
          width: 100%;
          height: 80px;
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block > .align-item {
            flex: 4; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block > .align-item-small {
            flex: 1.7; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block .I-details-first-div {
            min-width: 70%;
            height: 80px;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-overflow: ellipsis;
            white-space: pre-wrap; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block .I-details-first-div p {
              width: 50%;
              text-overflow: ellipsis;
              white-space: pre-wrap; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block .I-details-first-div .I-ordered-image {
              min-width: 80px;
              height: 70px;
              border: 2px solid #c1c1c3;
              border-radius: 8px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              cursor: pointer; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block .I-details-first-div .I-ordered-item-description {
              width: 100%;
              margin-left: 20px; }
              .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block .I-details-first-div .I-ordered-item-description .I-ordered-item-options {
                color: #A5A6A7; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-details-prices {
          width: 100%;
          font-size: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-details-total-prices {
          width: 50%; }
        .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing {
          width: 100%;
          height: 180px;
          margin-top: 20px;
          border-top: 1px solid #e1e2e3;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-toolbar-buttons {
            margin-left: 0; }
          .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices {
            width: 400px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices > div {
              display: flex;
              flex-direction: column;
              white-space: nowrap; }
              .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices > div:nth-child(2), .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices > div:nth-child(3) {
                font-weight: bold; }
              .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices > div div:last-child {
                margin-top: 25px;
                font-size: 18px;
                font-weight: bold; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices .I-details-order-amounts div:nth-child(4), .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices .I-details-order-amounts div:nth-child(5) {
              color: red; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices .I-details-order-amounts div:nth-child(3) {
              color: #1B313F; }
            .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices .I-order-details-symbols {
              position: relative; }
              .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-pricing .I-order-details-prices .I-order-details-symbols > div:last-child {
                top: 117%;
                position: absolute; }
    .I-order-details .I-order-details-right-side .I-user-comment {
      width: 100%;
      height: 150px;
      margin-top: 28px;
      background-color: white;
      padding: 10px 60px; }
      .I-order-details .I-order-details-right-side .I-user-comment input {
        width: 100%;
        height: 80px;
        font-size: 18px;
        resize: none;
        border: 0;
        margin-top: 10px;
        cursor: default; }

.orders-page-item {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  .orders-page-item p {
    position: absolute;
    bottom: 10%;
    font-weight: bold;
    left: 0; }

.I-request-only-file {
  width: 95%;
  margin: 15px auto; }
  .I-request-only-file p {
    color: #A5A6A7;
    margin-left: 25px; }
  .I-request-only-file .I-status-only {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-request-only-file .I-status-only .I-drop-down {
      width: 50px;
      float: right;
      margin-right: 25px; }
      .I-request-only-file .I-status-only .I-drop-down span {
        margin-right: 25px; }
      .I-request-only-file .I-status-only .I-drop-down .Open {
        height: 150px !important; }
      .I-request-only-file .I-status-only .I-drop-down .I-drop-down-options div {
        padding: 15px 0 !important; }
    .I-request-only-file .I-status-only .I-drop-down-title p {
      width: calc(100% - 10px) !important; }
    .I-request-only-file .I-status-only .I-input-status {
      float: right;
      margin-right: 25px; }
  .I-request-only-file .I-request-image-flex .I-request-right-side-img {
    width: 200px;
    height: 150px;
    border: 2px solid #A5A6A7;
    border-radius: 8px;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .I-request-only-file .I-right-side-attach-product {
    width: 100% !important; }
    .I-request-only-file .I-right-side-attach-product div {
      width: 95% !important;
      margin: 0 auto; }
  .I-request-only-file .I-request-files-only {
    width: 95%;
    margin: 25px auto; }
    .I-request-only-file .I-request-files-only div, .I-request-only-file .I-request-files-only audio {
      margin-top: 20px; }

.I-modal-picture {
  width: 750px;
  height: 550px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; }

.I-request-files-div {
  margin-top: 50px;
  margin-left: 25px; }
  .I-request-files-div div {
    margin-bottom: 20px; }

.I-promotion-page .I-promotion-page-add {
  width: 90%;
  margin: 20px auto;
  padding-top: 20px;
  position: relative; }
  .I-promotion-page .I-promotion-page-add button {
    width: 160px; }
  .I-promotion-page .I-promotion-page-add .I-promotion-drop-down {
    width: auto;
    height: 100px;
    padding: 0 5px;
    background-color: white;
    box-shadow: 0 0 25px -10px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 110%;
    z-index: 100;
    display: flex;
    flex-direction: column; }
    .I-promotion-page .I-promotion-page-add .I-promotion-drop-down a {
      text-decoration: none;
      color: black;
      font-weight: bold;
      margin-top: 20px;
      margin-left: 15px; }

.I-promotion-page .I-promotion-home-category {
  width: 90%;
  margin: 5px auto; }
  .I-promotion-page .I-promotion-home-category .I-home-category-header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .I-promotion-page .I-promotion-home-category .I-home-category-header p {
      position: absolute;
      bottom: 10%;
      font-weight: bold;
      left: 0; }

.I-promotion-page .I-data-table,
.I-promotion-page .I-table-footer {
  width: 100%; }

.I-promotions-add-page {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .I-promotions-add-page .I-promotion-add-content {
    width: 50vw;
    min-height: 75vh;
    padding: 40px 30px;
    background-color: white;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px; }
    .I-promotions-add-page .I-promotion-add-content div {
      margin-top: 10px; }
    .I-promotions-add-page .I-promotion-add-content .I-promotion-languages {
      width: 100%;
      margin: 0 auto;
      padding-top: 40px; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages input {
        width: 100%;
        height: 35px;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #A9B2B8;
        font-size: 14px;
        padding-left: 7px; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages div {
        margin-bottom: 10px;
        font-size: 16px; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages div img {
          width: 16px;
          margin-right: 16px; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages label {
        padding: 100px 70px;
        border-radius: 8px;
        background: #F0F3F4;
        margin-top: 20px;
        display: table;
        font-size: 18px;
        color: #A0AAB0;
        cursor: pointer;
        position: relative; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages label input[type="file"] {
          display: none; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages label .I-upload-image {
          width: 341px;
          height: 223px;
          position: absolute;
          border: 0;
          left: 0;
          top: -5%;
          border-radius: 8px;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotions-link-type {
        width: 341px;
        height: 50px;
        border: 1px solid black;
        border-radius: 8px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 18px; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotions-link-type .I-method {
          width: 130px;
          text-align: center;
          padding: 8px;
          border-radius: 8px;
          color: black;
          cursor: pointer; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotions-link-type .I-change-method {
          width: 140px;
          text-align: center;
          padding: 8px;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          background-color: #1B313F; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-right-side-attach-product {
        width: 100%;
        margin: 25px auto;
        height: 150px; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-right-side-attach-product p {
          color: #A5A6A7;
          margin-bottom: 15px; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-right-side-attach-product div {
          width: 100%;
          height: 100px;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #F0F3F4;
          cursor: pointer; }
          .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-right-side-attach-product div i {
            font-size: 30px;
            color: #939EA6; }
          .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-right-side-attach-product div span {
            font-size: 18px;
            color: #939EA6; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-search-category {
        padding: 15px 0;
        margin-bottom: 85px; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-search-category p {
          margin-bottom: 15px; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-search-category input {
          margin-bottom: 0; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-search-category .I-search-category-items {
          width: 100%;
          height: auto;
          overflow-y: auto;
          background-color: white;
          box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
          border-radius: 8px; }
          .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-search-category .I-search-category-items .I-products-searches {
            padding: 0 20px; }
            .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-search-category .I-search-category-items .I-products-searches div {
              cursor: pointer; }
      .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotion-product {
        width: 100%;
        height: 120px;
        border-top: 1px solid #A5A6A7;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotion-product p {
          width: 100%; }
        .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotion-product .I-attach-img {
          width: 50px;
          height: 50px;
          border: 1px solid #E2E2E2;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 25px; }
          .I-promotions-add-page .I-promotion-add-content .I-promotion-languages .I-promotion-product .I-attach-img img {
            width: 43px;
            height: 43px;
            border-radius: 10px;
            margin: 0 auto; }
  .I-promotions-add-page .I-footer-buttons {
    width: 50vw;
    height: 60px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    background-color: white;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0 0; }
    .I-promotions-add-page .I-footer-buttons button {
      margin-right: 25px; }
    .I-promotions-add-page .I-footer-buttons .save {
      background-color: #1B313F;
      color: white; }
    .I-promotions-add-page .I-footer-buttons .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }

.I-home-category-page {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .I-home-category-page .I-home-category-content {
    width: 50vw;
    min-height: 75vh;
    padding: 40px 30px;
    background-color: white;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px; }
    .I-home-category-page .I-home-category-content div {
      margin-top: 10px; }
    .I-home-category-page .I-home-category-content .I-home-category-languages {
      width: 100%;
      margin: 0 auto;
      padding-top: 40px; }
      .I-home-category-page .I-home-category-content .I-home-category-languages input {
        width: 100%;
        height: 35px;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #A9B2B8;
        font-size: 14px;
        padding-left: 7px; }
      .I-home-category-page .I-home-category-content .I-home-category-languages div {
        margin-bottom: 10px;
        font-size: 16px; }
        .I-home-category-page .I-home-category-content .I-home-category-languages div img {
          width: 16px;
          height: 16px;
          margin-right: 10px; }
      .I-home-category-page .I-home-category-content .I-home-category-languages .I-right-side-attach-product {
        width: 100%;
        margin: 25px auto;
        height: 150px; }
        .I-home-category-page .I-home-category-content .I-home-category-languages .I-right-side-attach-product p {
          color: #A5A6A7;
          margin-bottom: 15px; }
        .I-home-category-page .I-home-category-content .I-home-category-languages .I-right-side-attach-product div {
          width: 100%;
          height: 100px;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #F0F3F4;
          cursor: pointer; }
          .I-home-category-page .I-home-category-content .I-home-category-languages .I-right-side-attach-product div i {
            font-size: 30px;
            color: #939EA6; }
          .I-home-category-page .I-home-category-content .I-home-category-languages .I-right-side-attach-product div span {
            font-size: 18px;
            color: #939EA6; }
      .I-home-category-page .I-home-category-content .I-home-category-languages .I-attached-products .I-promotion-product {
        width: 100%;
        height: 80px;
        border-top: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #A5A6A7; }
        .I-home-category-page .I-home-category-content .I-home-category-languages .I-attached-products .I-promotion-product p {
          width: 100%; }
        .I-home-category-page .I-home-category-content .I-home-category-languages .I-attached-products .I-promotion-product .I-attach-img {
          width: 50px;
          height: 50px;
          border: 1px solid #E2E2E2;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 25px; }
          .I-home-category-page .I-home-category-content .I-home-category-languages .I-attached-products .I-promotion-product .I-attach-img img {
            width: 43px;
            height: 43px;
            border-radius: 10px;
            margin: 0 auto; }
  .I-home-category-page .I-footer-buttons {
    width: 50vw;
    height: 60px;
    background-color: white;
    box-shadow: 0 0 25px -10px;
    border-radius: 5px;
    bottom: 0;
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center; }
    .I-home-category-page .I-footer-buttons button {
      margin-right: 25px; }
    .I-home-category-page .I-footer-buttons .save {
      background-color: #1B313F;
      color: white; }
    .I-home-category-page .I-footer-buttons .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }

.I-user-table {
  width: 90%;
  margin: 25px auto;
  padding-top: 20px;
  position: relative; }
  .I-user-table .I-data-table a {
    color: black !important; }
    .I-user-table .I-data-table a:hover {
      text-decoration: underline; }
  .I-user-table > p {
    margin-bottom: 10px;
    font-weight: bold; }

.I-promo-code-page .I-products-page-header {
  width: 90%;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .I-promo-code-page .I-products-page-header .I-products-page-left {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .I-promo-code-page .I-products-page-header .I-products-page-left p {
      position: absolute;
      bottom: -70%;
      font-weight: bold;
      left: 0; }
    .I-promo-code-page .I-products-page-header .I-products-page-left a {
      width: 160px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      background-color: #1B313F;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
    .I-promo-code-page .I-products-page-header .I-products-page-left .I-repeat-button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 5px;
      cursor: pointer; }
      .I-promo-code-page .I-products-page-header .I-products-page-left .I-repeat-button i {
        font-size: 20px;
        transition: 1s; }

.I-promo-code-page .I-data-table,
.I-promo-code-page .I-table-footer {
  width: 90%;
  margin: 0 auto; }

.I-promo-code-page .I-order-filter .I-filter-apply-buttons {
  width: 350px;
  margin-right: 25px; }

.I-promo-code-page .I-order-filter .I-order-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .I-promo-code-page .I-order-filter .I-order-input div,
  .I-promo-code-page .I-order-filter .I-order-input .I-drop-down {
    min-width: 30%; }

.I-promo-code-add {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .I-promo-code-add .I-promo-add-content {
    width: 50vw;
    min-height: 75vh;
    padding: 40px 30px;
    background-color: white;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px; }
    .I-promo-code-add .I-promo-add-content > h3 {
      padding-bottom: 15px; }
    .I-promo-code-add .I-promo-add-content p {
      margin-top: 25px; }
    .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable {
      cursor: not-allowed !important;
      background-color: rgba(0, 0, 0, 0.1); }
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable div,
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable .I-method,
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable .I-change-method {
        cursor: not-allowed !important; }
    .I-promo-code-add .I-promo-add-content .I-promo-choose-type,
    .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable {
      width: 80%;
      margin-top: 15px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 1px solid #b6bcc1;
      border-radius: 5px;
      font-size: 14px; }
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type .I-method,
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable .I-method {
        width: 170px;
        padding: 8px;
        text-align: center;
        border-radius: 8px;
        color: black;
        cursor: pointer; }
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type .I-change-method,
      .I-promo-code-add .I-promo-add-content .I-promo-choose-type-disable .I-change-method {
        width: 170px;
        padding: 8px;
        text-align: center;
        border-radius: 8px;
        color: white;
        cursor: pointer;
        background-color: #1B313F; }
    .I-promo-code-add .I-promo-add-content .I-promo-coupon-amount {
      width: 80%;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .I-promo-code-add .I-promo-add-content .I-promo-coupon-amount input {
        width: 100%;
        height: 35px;
        border-radius: 5px;
        border: 1px solid #A9B2B8;
        font-size: 14px;
        padding-left: 7px; }
      .I-promo-code-add .I-promo-add-content .I-promo-coupon-amount .I-coupon-amount-checkbox {
        width: 55%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 25px; }
        .I-promo-code-add .I-promo-add-content .I-promo-coupon-amount .I-coupon-amount-checkbox div {
          width: 25px;
          height: 25px;
          border: 1px solid #b6bcc1;
          border-radius: 3px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center; }
          .I-promo-code-add .I-promo-add-content .I-promo-coupon-amount .I-coupon-amount-checkbox div img {
            width: 14px; }
        .I-promo-code-add .I-promo-add-content .I-promo-coupon-amount .I-coupon-amount-checkbox span {
          margin-left: 25px;
          width: 300px; }
    .I-promo-code-add .I-promo-add-content .I-promo-title-input {
      width: 100%;
      height: 35px;
      margin-top: 15px;
      border-radius: 5px;
      border: 1px solid #A9B2B8;
      font-size: 14px;
      padding-left: 7px; }
    .I-promo-code-add .I-promo-add-content .I-promo-generate-code-disable {
      cursor: not-allowed !important;
      background-color: rgba(0, 0, 0, 0.1) !important; }
    .I-promo-code-add .I-promo-add-content .I-promo-generate-code,
    .I-promo-code-add .I-promo-add-content .I-promo-generate-code-disable {
      width: 100%;
      height: 35px;
      border-radius: 5px;
      border: 1px solid #A9B2B8;
      font-size: 14px;
      padding-left: 7px;
      margin-top: 15px;
      text-transform: uppercase; }
    .I-promo-code-add .I-promo-add-content .I-promo-auto-generation {
      width: 22%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      white-space: nowrap; }
      .I-promo-code-add .I-promo-add-content .I-promo-auto-generation .I-repeat {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #b6bcc1;
        cursor: pointer;
        margin-right: 20px; }
        .I-promo-code-add .I-promo-add-content .I-promo-auto-generation .I-repeat i {
          font-size: 14px; }
      .I-promo-code-add .I-promo-add-content .I-promo-auto-generation span {
        text-decoration: underline;
        cursor: pointer; }
    .I-promo-code-add .I-promo-add-content .I-promo-page-down-content {
      height: 400px; }
      .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-start-date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-start-date p {
          width: 45%;
          margin-top: 40px; }
      .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-page-date {
        width: 100%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-page-date .I-datepicker-start {
          width: 45%;
          height: 35px;
          padding-left: 7px;
          border: 1px solid #b6bcc1;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-page-date .I-datepicker-start input {
            width: 200px;
            border: 0; }
          .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-page-date .I-datepicker-start i {
            margin-right: 20px; }
      .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-text p {
          width: 45%; }
      .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-page-min-max {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-promo-code-add .I-promo-add-content .I-promo-page-down-content .I-promo-page-min-max input {
          width: 45%;
          height: 35px;
          margin-bottom: 20px;
          border-radius: 5px;
          border: 1px solid #A9B2B8;
          font-size: 14px;
          padding-left: 7px; }
  .I-promo-code-add .I-promo-page-footer {
    width: 50vw;
    height: 60px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
    background-color: white;
    position: absolute;
    bottom: 0;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .I-promo-code-add .I-promo-page-footer .save {
      background-color: #1B313F;
      color: white; }
    .I-promo-code-add .I-promo-page-footer .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }
    .I-promo-code-add .I-promo-page-footer button {
      width: 120px;
      margin-right: 25px;
      height: 40px;
      border: none;
      border-radius: 10px; }
      .I-promo-code-add .I-promo-page-footer button:first-child {
        color: black;
        background-color: white; }

.I-dashboard-page {
  width: 90%;
  height: 100px;
  margin: 40px auto; }
  .I-dashboard-page .I-dashboard-page-header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .I-dashboard-page .I-dashboard-page-header .I-drop-down {
      min-width: 280px;
      background-color: transparent; }
      .I-dashboard-page .I-dashboard-page-header .I-drop-down .Open {
        height: 150px !important; }
      .I-dashboard-page .I-dashboard-page-header .I-drop-down .I-drop-down-title {
        background-color: transparent; }
        .I-dashboard-page .I-dashboard-page-header .I-drop-down .I-drop-down-title p {
          color: black; }
    .I-dashboard-page .I-dashboard-page-header .I-toolbar-buttons {
      width: 150px; }
    .I-dashboard-page .I-dashboard-page-header > div {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .I-dashboard-page .I-dashboard-page-header > div .I-search-date-picker {
        width: 280px;
        border: none;
        border-bottom: 1px solid #A9B2B8;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 40px; }
        .I-dashboard-page .I-dashboard-page-header > div .I-search-date-picker .react-datepicker-popper {
          z-index: 1000000; }
        .I-dashboard-page .I-dashboard-page-header > div .I-search-date-picker hr {
          height: 44px;
          border: 0.5px solid #A9B2B8; }
        .I-dashboard-page .I-dashboard-page-header > div .I-search-date-picker input {
          width: 100%;
          border: none;
          font-size: 18px;
          text-indent: 10px;
          z-index: 155000;
          background-color: transparent; }
          .I-dashboard-page .I-dashboard-page-header > div .I-search-date-picker input::placeholder {
            font-size: 16px;
            color: black; }
  .I-dashboard-page .I-dashboard-items {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0; }
    .I-dashboard-page .I-dashboard-items > div {
      width: 15%;
      height: 180px;
      background-color: white;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .I-dashboard-page .I-dashboard-items > div p {
        margin: 5px auto;
        font-size: 26px;
        font-weight: bolder; }
        .I-dashboard-page .I-dashboard-items > div p:last-child {
          font-size: 17px;
          font-weight: normal;
          text-align: center;
          color: #ababac; }
      .I-dashboard-page .I-dashboard-items > div .gray-shadow {
        background-color: #dde0df; }
      .I-dashboard-page .I-dashboard-items > div .green-shadow {
        background-color: #d4ece5; }
      .I-dashboard-page .I-dashboard-items > div .red-shadow {
        background-color: #fad9dc; }
      .I-dashboard-page .I-dashboard-items > div .blue-shadow {
        background-color: #ccd2e6; }
      .I-dashboard-page .I-dashboard-items > div .orange-shadow {
        background-color: #f7dad1; }
      .I-dashboard-page .I-dashboard-items > div .purple-shadow {
        background-color: #dfcee8; }
      .I-dashboard-page .I-dashboard-items > div div {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px; }
        .I-dashboard-page .I-dashboard-items > div div i {
          font-size: 20px; }
        .I-dashboard-page .I-dashboard-items > div div .gray {
          color: #57635f; }
        .I-dashboard-page .I-dashboard-items > div div .green {
          color: #1B313F; }
        .I-dashboard-page .I-dashboard-items > div div .red {
          color: #e34151; }
        .I-dashboard-page .I-dashboard-items > div div .blue {
          color: #032080; }
        .I-dashboard-page .I-dashboard-items > div div .orange {
          color: #d8491c; }
        .I-dashboard-page .I-dashboard-items > div div .purple {
          color: #620a8e; }
  .I-dashboard-page .I-dashboard-second-table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0; }
    .I-dashboard-page .I-dashboard-second-table > div {
      width: 49%;
      height: 400px;
      background-color: white;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .I-dashboard-page .I-dashboard-second-table > div svg {
        width: 300px;
        margin-top: 25px;
        transform: scale(1.7); }
        .I-dashboard-page .I-dashboard-second-table > div svg .donutchart-legend-item {
          font-size: 20px !important;
          margin-top: 10px !important; }
        .I-dashboard-page .I-dashboard-second-table > div svg .donutchart-innertext-label, .I-dashboard-page .I-dashboard-second-table > div svg .donutchart-innertext-value {
          font-size: 20px !important; }
      .I-dashboard-page .I-dashboard-second-table > div h3 {
        position: absolute;
        left: 5%;
        top: 5%; }
      .I-dashboard-page .I-dashboard-second-table > div:nth-child(3), .I-dashboard-page .I-dashboard-second-table > div:nth-child(4) {
        margin-top: 40px; }

.I-settings-page {
  width: 90%;
  margin: 45px auto; }
  .I-settings-page .I-settings-page-content {
    width: 70%;
    min-height: 75vh;
    margin: 0 auto; }
    .I-settings-page .I-settings-page-content > div {
      width: 100%;
      height: 100px;
      background-color: white;
      margin-top: 50px;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      font-size: 20px;
      font-weight: bolder; }
    .I-settings-page .I-settings-page-content .I-change-language {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px; }
      .I-settings-page .I-settings-page-content .I-change-language .I-settings-choose-language,
      .I-settings-page .I-settings-page-content .I-change-language .I-settings-change-password {
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #A5A6A7;
        border-radius: 8px;
        font-size: 15px;
        font-weight: normal; }
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-choose-language div,
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-change-password div {
          cursor: pointer; }
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-choose-language .I-method,
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-change-password .I-method {
          width: 130px;
          text-align: center;
          padding: 8px;
          border-radius: 8px;
          color: black;
          cursor: pointer; }
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-choose-language .I-change-method,
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-change-password .I-change-method {
          width: 140px;
          text-align: center;
          border-radius: 8px;
          color: white;
          cursor: pointer;
          background-color: #1B313F; }
      .I-settings-page .I-settings-page-content .I-change-language .I-settings-change-password {
        border: none;
        display: flex;
        justify-content: flex-end; }
        .I-settings-page .I-settings-page-content .I-change-language .I-settings-change-password button {
          width: 120px;
          height: 40px;
          background-color: white;
          border: 1px solid #A5A6A7;
          border-radius: 5px; }
    .I-settings-page .I-settings-page-content .I-settings-mu-table {
      width: 100%;
      height: 100%; }
      .I-settings-page .I-settings-page-content .I-settings-mu-table .I-mu-header {
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .I-settings-page .I-settings-page-content .I-settings-mu-table .I-mu-header div {
          margin-top: 25px; }
      .I-settings-page .I-settings-page-content .I-settings-mu-table .I-data-table {
        box-shadow: none;
        margin-top: 40px; }
      .I-settings-page .I-settings-page-content .I-settings-mu-table button {
        width: 120px;
        height: 40px;
        background-color: white;
        border: 1px solid #A5A6A7;
        border-radius: 5px;
        color: black; }

.I-chat-page {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
  position: relative; }
  .I-chat-page .I-main-chat-div {
    width: 100%;
    display: flex;
    height: 100%; }
    .I-chat-page .I-main-chat-div .I-left {
      width: 25%;
      height: 100%;
      z-index: 100;
      border-right: 1px solid #e1e4e6;
      overflow-y: auto; }
      .I-chat-page .I-main-chat-div .I-left .I-left-side-head {
        width: 100%;
        height: 100px;
        border-bottom: 1px solid #e1e4e6;
        display: flex;
        align-items: center;
        justify-content: center; }
        .I-chat-page .I-main-chat-div .I-left .I-left-side-head div {
          width: 90%;
          border-radius: 8px;
          border: 1px solid #e1e4e6; }
      .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block {
        width: 100%;
        height: 120px;
        background-color: white;
        border-bottom: 1px solid #e1e4e6;
        border-left: 1px solid #e1e4e6;
        cursor: pointer;
        transition: 1s; }
        .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-head {
          width: 90%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-head .I-message-headings {
            margin-top: 15px;
            display: flex;
            align-items: center; }
            .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-head .I-message-headings p {
              width: 100%;
              line-height: 25px;
              color: #77778a; }
          .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-head .I-chat-seen {
            width: 10px;
            height: 10px;
            background-color: #1B313F;
            border-radius: 50%;
            margin-left: 20px; }
          .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-head > p {
            margin-top: 15px;
            font-size: 12px;
            color: #A5A6A7; }
        .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-content {
          width: 90%;
          margin: 20px auto;
          display: block;
          display: -webkit-box;
          height: 43px;
          font-size: 16px;
          line-height: 1.5;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis; }
          .I-chat-page .I-main-chat-div .I-left .I-left-side-conversation-list .I-conversation-block .I-message-content p {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }
    .I-chat-page .I-main-chat-div .I-chat-right-side {
      width: 100%;
      border-bottom: 1px solid #e1e4e6; }
      .I-chat-page .I-main-chat-div .I-chat-right-side .I-header {
        width: 100%;
        height: 100px;
        z-index: 100;
        background-color: #f0f3f4;
        display: flex;
        align-items: center;
        justify-content: center; }
        .I-chat-page .I-main-chat-div .I-chat-right-side .I-header p {
          font-weight: bold;
          font-size: 20px;
          cursor: pointer; }
      .I-chat-page .I-main-chat-div .I-chat-right-side .I-content {
        width: 100%;
        height: 70vh;
        overflow-y: auto;
        background-color: #f0f3f4; }
        .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat {
          width: 85%;
          margin: 40px auto; }
          .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-chat-messages {
            width: 100%;
            display: flex;
            justify-content: space-between; }
            .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-chat-messages .I-sended-sms {
              max-width: 100%;
              border-radius: 0 25px 25px 25px;
              border-top-left-radius: 0;
              background-color: white;
              margin-bottom: 20px;
              color: black;
              box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
              position: relative; }
              .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-chat-messages .I-sended-sms p {
                margin-left: 10px;
                padding: 15px; }
            .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-chat-messages .I-answered-sms {
              max-width: 100%;
              border-radius: 25px 0 25px 25px;
              background-color: #1B313F;
              margin-bottom: 20px;
              color: white;
              box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16); }
              .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-chat-messages .I-answered-sms p {
                margin-left: 10px;
                padding: 15px; }
          .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-request-image {
            width: 100%;
            align-items: center;
            border-radius: 8px;
            margin-bottom: 15px; }
            .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms .I-request-image .I-request-right-side-img {
              width: 100px;
              height: 100px;
              border: 2px solid #a5a6a7;
              border-radius: 8px;
              cursor: pointer;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat; }
          .I-chat-page .I-main-chat-div .I-chat-right-side .I-content .I-content-chat .I-sms audio {
            width: 50%;
            border: 1px solid #A5A6A7;
            padding: 0;
            margin-bottom: 15px;
            border-radius: 8px; }
      .I-chat-page .I-main-chat-div .I-chat-right-side .disabled {
        background-color: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.3);
        border: none;
        cursor: default; }
      .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom {
        width: 100%;
        height: 80px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px; }
        .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom .I-write-message {
          width: 90%;
          height: 60px;
          padding: 0 20px;
          border-radius: 8px;
          border: 1px solid #A5A6A7;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom .I-write-message .I-chat-icons {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between; }
            .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom .I-write-message .I-chat-icons i {
              font-size: 18px;
              cursor: pointer; }
          .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom .I-write-message .I-chat-type-message {
            width: 90%; }
            .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom .I-write-message .I-chat-type-message input {
              width: 95%;
              resize: none;
              overflow: hidden;
              border: none;
              min-height: 45px;
              max-height: 100px; }
          .I-chat-page .I-main-chat-div .I-chat-right-side .I-chat-page-bottom .I-write-message button {
            width: 120px;
            height: 30px;
            background-color: white;
            border: 1px solid #A5A6A7; }

.I-header-notifications-page {
  padding-top: 50px; }
  .I-header-notifications-page .I-notification-page-header {
    width: 90%;
    margin: 0 auto; }
    .I-header-notifications-page .I-notification-page-header > p {
      margin-bottom: 10px;
      font-weight: bold; }
  .I-header-notifications-page .I-notification-page-button {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px; }
    .I-header-notifications-page .I-notification-page-button a {
      width: 160px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      background-color: #29A27D;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
  .I-header-notifications-page .I-data-table,
  .I-header-notifications-page .I-table-footer {
    width: 90%;
    margin: 0 auto; }

.I-add-notification-page {
  width: 90%;
  margin: 50px auto;
  display: flex;
  flex-direction: column; }
  .I-add-notification-page .I-notification-container {
    width: 75vw;
    position: relative;
    padding: 30px 0; }
    .I-add-notification-page .I-notification-container .I-add-notification-header {
      width: 100%;
      height: 70px; }
      .I-add-notification-page .I-notification-container .I-add-notification-header p {
        font-size: 18px;
        margin-bottom: 10px; }
        .I-add-notification-page .I-notification-container .I-add-notification-header p:first-child {
          color: #A5A6A7; }
        .I-add-notification-page .I-notification-container .I-add-notification-header p:last-child {
          font-weight: bold; }
    .I-add-notification-page .I-notification-container .I-add-notification-container {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px; }
      .I-add-notification-page .I-notification-container .I-add-notification-container .I-select-language {
        width: 90%;
        height: 80px;
        margin: 20px auto;
        border-bottom: 1px solid #cfd1d2;
        display: flex;
        align-items: flex-end; }
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-select-language .I-active-border {
          border-bottom: 5px solid #1B313F; }
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-select-language div {
          padding: 5px 0;
          margin-right: 20px;
          width: 110px;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-bottom: 5px solid transparent; }
          .I-add-notification-page .I-notification-container .I-add-notification-container .I-select-language div img {
            width: 18px;
            margin-right: 10px; }
      .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-title-description {
        width: 90%;
        margin: 50px auto; }
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-title-description > div {
          margin-bottom: 20px; }
          .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-title-description > div > p {
            margin-bottom: 10px; }
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-title-description input,
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-title-description textarea {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          border: 1px solid #cfd1d2;
          text-indent: 20px;
          font-size: 18px; }
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-title-description textarea {
          width: 100% !important;
          height: 200px !important;
          padding: 10px;
          resize: none; }
      .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-notification-photo {
        width: 90%;
        margin: 20px auto; }
        .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-notification-photo label {
          width: 341px;
          height: 220px;
          padding: 100px;
          border-radius: 8px;
          background: #f0f3f4;
          margin-top: 20px;
          display: table;
          font-size: 18px;
          color: #a0aab0;
          cursor: pointer;
          position: relative; }
          .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-notification-photo label .I-uploaded-background {
            width: 200px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: space-between; }
          .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-notification-photo label input[type="file"] {
            display: none; }
          .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-notification-photo label .I-upload-image {
            width: 341px;
            height: 215px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat; }
            .I-add-notification-page .I-notification-container .I-add-notification-container .I-add-notification-photo label .I-upload-image:hover {
              background-color: rgba(0, 0, 0, 0.3); }
    .I-add-notification-page .I-notification-container .I-notification-step-2 {
      width: 100%;
      height: 100%; }
      .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter {
        width: 100%;
        height: 250px;
        background-color: white;
        box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        position: relative; }
        .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter > button {
          position: absolute;
          left: 20%;
          bottom: 25%;
          background-color: #1B313F;
          color: white;
          width: 140px;
          height: 40px;
          border: 0;
          border-radius: 8px; }
        .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter > p {
          position: absolute;
          right: 8%;
          top: 10%;
          font-size: 18px;
          font-weight: bold; }
        .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-order-filter {
          height: 100%;
          background-color: transparent; }
          .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-order-filter .I-order-input {
            margin-top: 55px; }
            .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-order-filter .I-order-input div {
              margin-right: 10px; }
        .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-notification-filter-footer {
          width: 100%;
          height: 50px;
          position: absolute;
          bottom: 0;
          background-color: white;
          border-radius: 10px;
          display: flex;
          justify-content: flex-end;
          padding: 0 100px; }
          .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-notification-filter-footer button {
            width: 140px;
            height: 40px;
            margin-right: 20px;
            border: 0;
            border-radius: 8px; }
            .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-notification-filter-footer button:first-child {
              background-color: white;
              color: black; }
            .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-notification-filter-footer button:nth-child(2) {
              background-color: #1B313F;
              color: white; }
            .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-filter .I-notification-filter-footer button:last-child {
              background-color: #1B313F;
              color: white; }
      .I-add-notification-page .I-notification-container .I-notification-step-2 .I-notification-table {
        width: 100%;
        border-radius: 10px;
        margin-top: 25px; }
  .I-add-notification-page .I-notification-page-footer {
    width: 75vw;
    height: 60px;
    background-color: white;
    box-shadow: 0 0 25px -10px;
    border-radius: 5px;
    bottom: 0;
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center; }
    .I-add-notification-page .I-notification-page-footer div {
      width: 96%;
      display: flex;
      justify-content: flex-end; }
      .I-add-notification-page .I-notification-page-footer div button {
        width: 120px;
        height: 40px;
        margin-left: 20px;
        border-radius: 8px;
        border: none; }
      .I-add-notification-page .I-notification-page-footer div .cancel {
        background-color: white;
        color: black; }
      .I-add-notification-page .I-notification-page-footer div .I-add-users {
        width: auto;
        height: 40px;
        padding: 5px;
        border-radius: 8px;
        border: 2px solid #1B313F;
        color: black;
        background: transparent; }
      .I-add-notification-page .I-notification-page-footer div .save {
        background-color: #1B313F;
        color: white; }
      .I-add-notification-page .I-notification-page-footer div .disabled {
        background-color: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.3);
        border: none;
        cursor: default; }

.I-home-left-bar {
  width: 320px;
  height: 100vh;
  background-color: white;
  box-shadow: 0 0 16px -10px;
  transition: .1s;
  overflow-y: auto;
  overflow-x: hidden; }
  .I-home-left-bar i {
    color: #1B313F;
    font-size: 18px; }
  .I-home-left-bar .I-left-bar-header {
    width: 100%;
    height: 100px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DADEE0; }
    .I-home-left-bar .I-left-bar-header .I-left-bar-logo {
      width: 70px;
      margin-left: 10px; }
    .I-home-left-bar .I-left-bar-header .I-hamburger {
      cursor: pointer; }
      .I-home-left-bar .I-left-bar-header .I-hamburger:hover span:last-child {
        width: 30px; }
      .I-home-left-bar .I-left-bar-header .I-hamburger:hover span:first-child {
        width: 20px; }
      .I-home-left-bar .I-left-bar-header .I-hamburger span {
        width: 30px;
        border: 1px solid;
        border-radius: 80px;
        margin-top: 10px;
        display: block;
        transition: 0.5s; }
        .I-home-left-bar .I-left-bar-header .I-hamburger span:last-child {
          width: 20px; }
  .I-home-left-bar .I-left-bar-content {
    width: 300px;
    padding: 40px;
    display: flex;
    flex-direction: column; }
    .I-home-left-bar .I-left-bar-content a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      text-align: left;
      margin-bottom: 24px; }
      .I-home-left-bar .I-left-bar-content a > div {
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg);
        border-radius: 5px; }
        .I-home-left-bar .I-left-bar-content a > div i {
          transform: rotate(-45deg); }
      .I-home-left-bar .I-left-bar-content a span {
        width: 150px;
        color: black;
        font-size: 17px; }
    .I-home-left-bar .I-left-bar-content .activeClass div {
      background-color: #1B313F; }
      .I-home-left-bar .I-left-bar-content .activeClass div i {
        color: white; }
    .I-home-left-bar .I-left-bar-content .activeClass span {
      color: #1B313F; }

.I-close {
  width: 120px;
  min-width: 120px;
  background-color: white;
  box-shadow: 0 0 16px -10px;
  transition: width 0.1s;
  overflow-y: auto;
  overflow-x: hidden; }
  .I-close i {
    color: #1B313F;
    font-size: 18px; }
  .I-close .I-closed-bar {
    width: 100%;
    height: 100px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dadee0;
    overflow-y: auto; }
    .I-close .I-closed-bar .I-close-hamburger {
      cursor: pointer; }
      .I-close .I-closed-bar .I-close-hamburger:hover span:last-child {
        width: 30px; }
      .I-close .I-closed-bar .I-close-hamburger:hover span:first-child {
        width: 20px; }
      .I-close .I-closed-bar .I-close-hamburger span {
        width: 30px;
        border: 1px solid;
        border-radius: 80px;
        margin-top: 10px;
        display: block;
        transition: 0.5s; }
        .I-close .I-closed-bar .I-close-hamburger span:last-child {
          width: 20px; }
  .I-close .I-left-close-content {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column; }
    .I-close .I-left-close-content a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      text-align: left;
      margin-bottom: 24px; }
      .I-close .I-left-close-content a > div {
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transform: rotate(45deg); }
        .I-close .I-left-close-content a > div > i {
          transform: rotate(-45deg); }
      .I-close .I-left-close-content a span {
        width: 150px;
        color: black;
        font-size: 18px; }
    .I-close .I-left-close-content .activeClass div {
      background-color: #1B313F; }
      .I-close .I-left-close-content .activeClass div i {
        color: white; }
    .I-close .I-left-close-content .activeClass span {
      color: #1B313F; }

.I-header {
  width: 85%;
  height: 100px;
  float: right;
  border-bottom: 1px solid #DADEE0; }
  .I-header .I-header-content {
    width: 90%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .I-header .I-header-content .I-header-toolbar {
      display: flex;
      align-items: center;
      position: relative; }
      .I-header .I-header-content .I-header-toolbar .I-header-icons {
        display: flex;
        align-items: center; }
        .I-header .I-header-content .I-header-toolbar .I-header-icons span {
          width: 10px;
          height: 10px;
          background-color: #630f0f;
          border-radius: 50%;
          position: absolute;
          left: 18%;
          bottom: 70%;
          animation-name: alert;
          animation-duration: 1s;
          animation-iteration-count: infinite; }

@keyframes alert {
  0% {
    background-color: #d30808; }
  50% {
    background-color: darkred; }
  100% {
    background-color: #d30808; } }
        .I-header .I-header-content .I-header-toolbar .I-header-icons i {
          font-size: 20px;
          margin-left: 35px;
          font-weight: bolder;
          cursor: pointer; }
        .I-header .I-header-content .I-header-toolbar .I-header-icons a {
          text-decoration: none;
          color: black; }
      .I-header .I-header-content .I-header-toolbar .I-header-user {
        display: flex;
        align-items: center; }
        .I-header .I-header-content .I-header-toolbar .I-header-user img {
          margin-left: 35px;
          width: 30px;
          cursor: pointer; }
        .I-header .I-header-content .I-header-toolbar .I-header-user i {
          margin-left: 5px;
          font-weight: bolder;
          cursor: pointer; }
      .I-header .I-header-content .I-header-toolbar .I-dropdown {
        width: 100%;
        height: 100px;
        background-color: white;
        box-shadow: 0 0 25px -10px;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 160%;
        z-index: 100; }
        .I-header .I-header-content .I-header-toolbar .I-dropdown div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          color: black;
          cursor: pointer; }
          .I-header .I-header-content .I-header-toolbar .I-dropdown div i {
            font-size: 18px;
            margin-left: 5px; }
          .I-header .I-header-content .I-header-toolbar .I-dropdown div p, .I-header .I-header-content .I-header-toolbar .I-dropdown div a {
            margin-left: 15px;
            width: 100%;
            text-decoration: none;
            color: black; }
      .I-header .I-header-content .I-header-toolbar .I-drop-down-hide {
        display: none; }
  .I-header .I-header-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .I-header .I-header-nav > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      color: grey;
      font-size: 14px; }
      .I-header .I-header-nav > div > i {
        font-size: 16px;
        margin-right: 5px; }

.I-request-page-filter .I-brands-page .I-brands-filter {
  width: 100%;
  height: 150px;
  margin: 0 auto;
  background-color: white;
  padding: 20px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 0 16px -10px; }
  .I-request-page-filter .I-brands-page .I-brands-filter .date-picker {
    width: 320px;
    height: 44px;
    margin: 10px;
    border-radius: 3px;
    border: 0;
    text-indent: 10px;
    border: 1px solid #A9B2B8; }
  .I-request-page-filter .I-brands-page .I-brands-filter .I-search-input {
    width: 24%;
    height: 44px;
    border: none;
    border-bottom: 1px solid #A9B2B8;
    border-radius: 3px;
    display: flex;
    align-items: center; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-search-input input {
      width: 85%;
      height: 42px;
      border: none;
      font-size: 18px;
      text-indent: 10px; }
      .I-request-page-filter .I-brands-page .I-brands-filter .I-search-input input::placeholder {
        font-size: 16px; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-search-input i {
      font-size: 18px;
      margin-left: 10px;
      cursor: pointer; }
  .I-request-page-filter .I-brands-page .I-brands-filter input {
    height: 42px;
    border-radius: 8px;
    border: 1px solid #A9B2B8; }
  .I-request-page-filter .I-brands-page .I-brands-filter .I-search-drop-down {
    width: 24%;
    height: 44px;
    border: 1px solid black;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-search-drop-down span {
      margin-left: 10px; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-search-drop-down i {
      margin-right: 10px;
      cursor: pointer; }
  .I-request-page-filter .I-brands-page .I-brands-filter .I-drop-down-hide {
    display: none; }
  .I-request-page-filter .I-brands-page .I-brands-filter .with-margin {
    margin-top: 15px; }
  .I-request-page-filter .I-brands-page .I-brands-filter .I-search-quantity {
    width: 24%;
    height: 44px;
    border: none;
    border-bottom: 1px solid #A9B2B8;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-search-quantity hr {
      height: 44px;
      border: 0.5px solid #A9B2B8; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-search-quantity input {
      width: 85%;
      border: none;
      font-size: 18px;
      text-indent: 10px; }
      .I-request-page-filter .I-brands-page .I-brands-filter .I-search-quantity input::placeholder {
        font-size: 16px; }
  .I-request-page-filter .I-brands-page .I-brands-filter .I-filter-footer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-filter-footer .I-filter-apply-buttons {
      margin-bottom: 15px;
      margin-top: 0 !important; }
    .I-request-page-filter .I-brands-page .I-brands-filter .I-filter-footer .I-show-more-div {
      width: 180px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer; }

.I-checkbox {
  width: 20px;
  cursor: pointer;
  height: 20px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 4px; }
  .I-checkbox > img {
    width: 14px;
    margin: auto; }
  .I-checkbox.I-radio {
    border-radius: 50%; }

.I-data-table {
  max-width: 100%;
  color: #000000;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  overflow: hidden; }
  .I-data-table > .I-data-table-header {
    height: 60px;
    padding: 0 40px;
    z-index: 2;
    display: flex;
    position: relative;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    list-style-type: none;
    font-weight: 800;
    color: #2e2d2d;
    background-color: white; }
    .I-data-table > .I-data-table-header > li {
      width: 100%;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .I-data-table > .I-data-table-body ul {
    height: 60px;
    padding: 0 40px;
    z-index: 1;
    display: flex;
    position: relative;
    font-size: 16px;
    background: white;
    align-items: center;
    list-style-type: none; }
    .I-data-table > .I-data-table-body ul:hover {
      background-color: #F0F3F4;
      cursor: pointer; }
    .I-data-table > .I-data-table-body ul:hover > .I-data-table-actions {
      display: initial; }
    .I-data-table > .I-data-table-body ul > li {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 15px;
      text-overflow: ellipsis; }
      .I-data-table > .I-data-table-body ul > li > div {
        top: 50%;
        width: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        transform: translateY(-50%);
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 30ch; }
        .I-data-table > .I-data-table-body ul > li > div > .I-data-table-image-row {
          width: 40px;
          float: left;
          height: 40px;
          border-radius: 8px; }
      .I-data-table > .I-data-table-body ul > li.I-data-table-actions {
        right: 20px;
        width: auto;
        display: none;
        position: absolute; }
        .I-data-table > .I-data-table-body ul > li.I-data-table-actions > div {
          padding-right: 0; }

.I-table-footer {
  height: 80px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-end; }
  .I-table-footer .I-table-page-panel {
    display: flex; }
    .I-table-footer .I-table-page-panel .I-table-page-info {
      width: 160px;
      margin-right: 30px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .I-table-footer .I-table-page-panel .I-table-page-info input {
        width: 50px;
        height: 32px;
        margin: 0 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1); }
      .I-table-footer .I-table-page-panel .I-table-page-info .I-page-count {
        min-width: 40px;
        color: #9e9d9d; }
    .I-table-footer .I-table-page-panel .I-table-page-slide {
      display: flex;
      width: 80px;
      display: flex;
      justify-content: space-between; }
      .I-table-footer .I-table-page-panel .I-table-page-slide div {
        height: 100%;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #334856;
        border-radius: 5px;
        cursor: pointer; }
        .I-table-footer .I-table-page-panel .I-table-page-slide div i {
          font-size: 20px;
          color: white; }
        .I-table-footer .I-table-page-panel .I-table-page-slide div .I-table-not-allow {
          color: grey;
          cursor: default; }
      .I-table-footer .I-table-page-panel .I-table-page-slide .I-table-not-allow-slide {
        cursor: default; }
  .I-table-footer .I-table-show-more {
    width: 150px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 15px;
    background-color: #334856;
    color: white;
    cursor: pointer; }

.I-drop-down {
  min-width: 360px;
  cursor: pointer; }
  .I-drop-down .I-drop-down-title {
    height: 100%;
    overflow: hidden;
    background-color: white;
    border: none !important;
    border-bottom: 1px solid #A9B2B8 !important;
    color: black; }
  .I-drop-down .I-drop-down-title {
    display: flex;
    align-items: center;
    justify-content: center; }
    .I-drop-down .I-drop-down-title p {
      width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .I-drop-down .I-drop-down-component {
    height: 44px; }
  .I-drop-down .I-drop-down-title {
    color: #A9B2B8; }
  .I-drop-down .I-drop-down-options {
    max-height: 400px;
    background-color: white;
    color: black;
    border-radius: 8px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 8px 25px -10px;
    z-index: 100;
    position: relative; }
    .I-drop-down .I-drop-down-options div {
      padding: 15px 20px;
      overflow: hidden; }
  .I-drop-down .placeholder {
    color: black; }

.selected {
  color: #1B313F;
  font-weight: bold; }

.I-modal-wrap {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000000;
  background-color: rgba(0, 0, 0, 0.3); }

.I-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  background-color: #fff; }

.I-accept-choice {
  width: 350px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  background-color: white; }
  .I-accept-choice .I-accept-choice-action {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .I-accept-choice .I-accept-choice-action > button {
      width: 100px;
      height: 100%; }
    .I-accept-choice .I-accept-choice-action .cancel {
      background-color: white;
      border: none; }
    .I-accept-choice .I-accept-choice-action .accept {
      border-radius: 5px;
      background-color: #1B313F;
      border: none;
      color: white; }

.I-attach-products-modal {
  width: 600px;
  height: 600px;
  border-radius: 10px;
  background-color: white; }
  .I-attach-products-modal .I-attach-heading {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid #808080;
    position: relative; }
    .I-attach-products-modal .I-attach-heading span {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 5%;
      font-size: 18px;
      cursor: pointer; }
  .I-attach-products-modal .I-attach-search {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .I-attach-products-modal .I-attach-search .I-attach-input {
      width: 90%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #808080;
      border-radius: 8px; }
      .I-attach-products-modal .I-attach-search .I-attach-input input {
        width: 80%;
        border-radius: 8px;
        border: 0;
        text-indent: 10px;
        font-size: 16px; }
      .I-attach-products-modal .I-attach-search .I-attach-input i {
        font-size: 16px;
        margin-right: 10px; }
  .I-attach-products-modal .I-attach-products-list {
    width: 90%;
    height: 345px;
    margin: 0 auto;
    overflow-y: auto; }
    .I-attach-products-modal .I-attach-products-list .I-data-table-header {
      display: none; }
    .I-attach-products-modal .I-attach-products-list .I-data-table-body ul {
      padding: 0 10px; }
  .I-attach-products-modal .I-attach-products-footer {
    width: 100%;
    height: 80px;
    margin-top: 15px;
    border-top: 1px solid #808080;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .I-attach-products-modal .I-attach-products-footer .I-attach-footer-buttons {
      padding: 0 30px; }
      .I-attach-products-modal .I-attach-products-footer .I-attach-footer-buttons button {
        width: 120px;
        margin-left: 20px;
        height: 40px;
        border: none;
        border-radius: 10px; }
        .I-attach-products-modal .I-attach-products-footer .I-attach-footer-buttons button:first-child {
          color: black;
          background-color: white; }
        .I-attach-products-modal .I-attach-products-footer .I-attach-footer-buttons button:nth-child(2) {
          color: white;
          background-color: #1B313F; }
      .I-attach-products-modal .I-attach-products-footer .I-attach-footer-buttons .disable {
        background-color: yellow;
        color: rgba(0, 0, 0, 0.3);
        border: none;
        cursor: default; }

.I-filter {
  width: 100%;
  height: 80px;
  background-color: white; }
  .I-filter .I-filter-first-block {
    width: 90%;
    padding: 20px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-filter .I-filter-first-block .I-filter-search {
      width: 20%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      border: 1px solid #A9B2B8; }
      .I-filter .I-filter-first-block .I-filter-search input {
        border: none;
        padding: 5px 10px;
        font-size: 18px; }
        .I-filter .I-filter-first-block .I-filter-search input::placeholder {
          font-size: 18px; }
      .I-filter .I-filter-first-block .I-filter-search i {
        margin-right: 10px;
        font-size: 18px; }
    .I-filter .I-filter-first-block .I-filter-dropdown {
      width: 20%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      border: 1px solid #A9B2B8; }
  .I-filter .I-toolbar {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 80px;
    background-color: white; }
    .I-filter .I-toolbar .I-show-more {
      cursor: pointer; }
      .I-filter .I-toolbar .I-show-more div {
        width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .I-filter .I-toolbar .I-filter-buttons {
      display: flex;
      align-items: center; }
      .I-filter .I-toolbar .I-filter-buttons button {
        padding: 10px;
        border-radius: 10px;
        border: none; }
        .I-filter .I-toolbar .I-filter-buttons button:first-child {
          margin-right: 20px;
          background-color: white; }
        .I-filter .I-toolbar .I-filter-buttons button:last-child {
          width: 120px;
          color: white;
          background-color: #1B313F; }
  .I-filter .I-open-filter {
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-filter .I-open-filter .I-open-filter-content {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .I-filter .I-close-filter {
    display: none; }

.I-brands-add-edit {
  width: 500px;
  height: 500px;
  background-color: white;
  border-radius: 8px; }
  .I-brands-add-edit .I-brands-content {
    width: 90%;
    margin: 25px auto; }
    .I-brands-add-edit .I-brands-content p {
      margin-top: 20px; }
    .I-brands-add-edit .I-brands-content input {
      width: 100%;
      margin-top: 20px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid #A9B2B8;
      padding: 10px;
      font-size: 16px; }
    .I-brands-add-edit .I-brands-content label {
      width: 341px;
      height: 220px;
      padding: 100px;
      border-radius: 8px;
      background: #F0F3F4;
      margin-top: 20px;
      display: table;
      font-size: 18px;
      color: #A0AAB0;
      cursor: pointer;
      position: relative; }
      .I-brands-add-edit .I-brands-content label .I-uploaded-background {
        width: 200px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .I-brands-add-edit .I-brands-content label input[type="file"] {
        display: none; }
      .I-brands-add-edit .I-brands-content label .I-upload-image {
        width: 341px;
        height: 215px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
        .I-brands-add-edit .I-brands-content label .I-upload-image:hover {
          background-color: rgba(0, 0, 0, 0.3); }
  .I-brands-add-edit .I-footer-buttons-brand {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16); }
    .I-brands-add-edit .I-footer-buttons-brand div .save {
      background-color: #1B313F;
      color: white; }
    .I-brands-add-edit .I-footer-buttons-brand div .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }
    .I-brands-add-edit .I-footer-buttons-brand div button {
      width: 120px;
      height: 40px;
      margin-left: 20px;
      border: none;
      border-radius: 10px; }
      .I-brands-add-edit .I-footer-buttons-brand div button:first-child {
        color: black;
        background-color: white; }

.I-order-filter {
  width: 100%;
  height: 150px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 16px -10px;
  padding: 20px 80px; }
  .I-order-filter .I-order-input {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .I-order-filter .I-order-input .I-drop-down-options.Open {
      min-height: 160px; }
    .I-order-filter .I-order-input .I-search-date-picker {
      width: 24%;
      border: none;
      border-bottom: 1px solid #A9B2B8;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .I-order-filter .I-order-input .I-search-date-picker .react-datepicker-popper {
        z-index: 1000000; }
      .I-order-filter .I-order-input .I-search-date-picker hr {
        height: 44px;
        border: 0.5px solid #A9B2B8; }
      .I-order-filter .I-order-input .I-search-date-picker input {
        width: 85%;
        border: none;
        font-size: 18px;
        text-indent: 10px;
        z-index: 155000; }
        .I-order-filter .I-order-input .I-search-date-picker input::placeholder {
          font-size: 16px;
          color: #A9B2B8; }
    .I-order-filter .I-order-input .I-drop-down-title {
      border: 1px solid #A9B2B8; }
  .I-order-filter .I-filter-apply-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .I-order-filter .I-filter-apply-buttons .I-drop-down {
      position: absolute;
      margin-right: 200px;
      left: 37%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .I-order-filter .I-filter-apply-buttons .I-search-quantity {
      width: 24%;
      border: none;
      border-bottom: 1px solid #A9B2B8;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .I-order-filter .I-filter-apply-buttons .I-search-quantity hr {
        height: 44px;
        border: 0.5px solid #A9B2B8; }
      .I-order-filter .I-filter-apply-buttons .I-search-quantity input {
        width: 85%;
        border: none;
        font-size: 18px;
        text-indent: 10px; }
        .I-order-filter .I-filter-apply-buttons .I-search-quantity input::placeholder {
          font-size: 16px; }
    .I-order-filter .I-filter-apply-buttons .react-datepicker-popper {
      z-index: 1000000; }

.I-details-left-side {
  width: 20%;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
  background-color: white;
  overflow: hidden;
  position: fixed; }
  .I-details-left-side hr {
    width: 80px;
    border: 2px solid #1B313F;
    position: absolute;
    left: 12%;
    top: 5%;
    border-radius: 10px; }
  .I-details-left-side .I-details-left-content {
    padding: 20px; }
    .I-details-left-side .I-details-left-content p {
      padding: 15px 25px; }
      .I-details-left-side .I-details-left-content p:first-child {
        font-size: 25px;
        font-weight: bolder; }
    .I-details-left-side .I-details-left-content span {
      padding: 0 25px;
      font-size: 13px;
      color: #A5A6A7;
      margin-top: 20px; }

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F1F1F1;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: #CCCDCE;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #1B313F; }

input:focus + .slider {
  box-shadow: 0 0 1px #1B313F; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.I-mu-add-edit {
  width: 500px;
  height: 420px;
  background-color: white;
  border-radius: 15px;
  position: relative; }
  .I-mu-add-edit h3 {
    margin-left: 25px;
    margin-top: 20px; }
  .I-mu-add-edit .I-category-languages {
    width: 90%;
    padding-top: 20px; }
    .I-mu-add-edit .I-category-languages div {
      margin: 15px auto;
      margin-bottom: 15px;
      display: flex;
      align-items: center; }
      .I-mu-add-edit .I-category-languages div p {
        margin-left: 10px; }
    .I-mu-add-edit .I-category-languages input {
      width: 100%;
      height: 35px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #A9B2B8;
      font-size: 14px;
      padding-left: 7px;
      margin-left: 20px; }
    .I-mu-add-edit .I-category-languages img {
      width: 16px;
      margin-left: 20px; }
  .I-mu-add-edit .I-footer-buttons-brand {
    width: 100%;
    height: 60px;
    padding: 0 25px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16); }
    .I-mu-add-edit .I-footer-buttons-brand span .save {
      background-color: #1B313F;
      color: white; }
    .I-mu-add-edit .I-footer-buttons-brand span .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }
    .I-mu-add-edit .I-footer-buttons-brand span button {
      width: 120px;
      height: 40px;
      margin-left: 20px;
      border: none;
      border-radius: 10px; }
      .I-mu-add-edit .I-footer-buttons-brand span button:first-child {
        color: black;
        background-color: white; }

.I-change-password {
  width: 500px;
  height: 380px;
  background-color: white;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative; }
  .I-change-password h3 {
    padding-top: 15px;
    margin-left: 25px; }
  .I-change-password .I-category-languages {
    width: 90%;
    margin: 0 auto; }
    .I-change-password .I-category-languages div {
      margin: 15px auto;
      display: flex;
      align-items: center; }
    .I-change-password .I-category-languages input {
      width: 100%;
      height: 35px;
      padding-left: 7px;
      border-radius: 8px;
      border: 1px solid #A9B2B8;
      font-size: 18px; }
    .I-change-password .I-category-languages img {
      width: 18px;
      margin-left: 20px; }
  .I-change-password .I-footer-buttons-brand {
    width: 100%;
    height: 60px;
    position: absolute;
    padding: 0 25px;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16); }
    .I-change-password .I-footer-buttons-brand span .save {
      background-color: #1B313F;
      color: white; }
    .I-change-password .I-footer-buttons-brand span .disabled {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      border: none;
      cursor: default; }
    .I-change-password .I-footer-buttons-brand span button {
      width: 120px;
      height: 40px;
      margin-left: 20px;
      border: none;
      border-radius: 10px; }
      .I-change-password .I-footer-buttons-brand span button:first-child {
        color: black;
        background-color: white; }

.I-support-modal {
  width: 500px;
  min-height: 200px;
  max-height: 700px;
  background-color: white;
  border-radius: 8px;
  padding: 20px; }
  .I-support-modal > div {
    height: 100%; }
    .I-support-modal > div p {
      margin-top: 15px; }
      .I-support-modal > div p i {
        font-weight: bold;
        color: #1B313F;
        margin-right: 15px; }

@media (max-width: 1880px) {
  .I-order-input .I-search-quantity {
    width: 280px !important; }
  .I-order-input .I-drop-down {
    min-width: 280px !important; } }

@media (max-width: 1650px) {
  .I-product-two-content input {
    min-width: 200px !important; }
  .I-product-two-content > .I-drop-down {
    min-width: 200px; } }

@media (max-width: 1500px) {
  .I-home-left-bar {
    width: 250px; }
    .I-home-left-bar .I-left-bar-content {
      width: 270px; }
  .I-order-input .I-drop-down {
    min-width: 260px !important; }
  .I-user-orders-requests .I-data-table {
    width: 85%; }
  .I-user-choose-status {
    width: 85% !important; } }

@media (max-width: 1400px) {
  .I-home-left-bar {
    width: 250px; }
  .I-order-input .I-drop-down {
    min-width: 220px !important; }
  .I-left-bar-content a span {
    font-size: 15px !important; }
  .I-promo-choose-type,
  .I-promo-choose-type-disable {
    font-size: 14px !important; }
  .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block > .align-item-small {
    font-size: 14px; } }

@media (max-width: 1300px) {
  .I-products-page .I-data-table {
    max-width: 980px !important; }
  .I-header-nav h2 {
    font-size: 14px;
    margin-top: 15px; }
  .I-order-filter {
    padding: 20px 30px !important; }
    .I-order-filter input {
      font-size: 14px; }
      .I-order-filter input::placeholder {
        font-size: 14px; }
  .I-drop-down-title p {
    font-size: 14px;
    font-weight: 100; }
  .I-user-details {
    width: 95%;
    margin: 10px auto !important; }
  .I-user-orders-requests .I-data-table {
    width: 100%; }
    .I-user-orders-requests .I-data-table > .I-data-table-header li {
      font-size: 12px !important; }
  .I-user-orders-requests .I-table-footer {
    width: 100% !important; }
  .I-user-choose-status {
    width: 100% !important;
    height: 50px !important; }
    .I-user-choose-status .I-choose-list {
      font-size: 12px !important;
      height: 20px !important; }
  .I-choose-status {
    font-size: 12px; }
  .I-user-details-counts span {
    font-size: 10px !important; }
  .I-user-page-right-side {
    width: 73% !important; }
  .I-user-orders-requests {
    margin-top: 10px !important; }
  .I-block-user > div {
    font-size: 12px; }
  .I-order-details-name {
    height: 100% !important; }
    .I-order-details-name .I-details-name {
      font-size: 12px !important; }
    .I-order-details-name i,
    .I-order-details-name span {
      font-size: 12px !important; }
  .I-data-table > .I-data-table-header li {
    font-size: 12px !important; }
  .I-data-table > .I-data-table-body ul {
    height: 55px !important; }
    .I-data-table > .I-data-table-body ul > li {
      font-size: 14px !important; }
  .I-search-quantity input::placeholder {
    font-size: 14px; }
  .I-filter-apply-buttons input::placeholder {
    font-size: 14px !important; }
  .I-order-details {
    width: 95%;
    margin: 10px auto !important; }
    .I-order-details .I-order-details-right-side {
      width: 73%; }
    .I-order-details .I-details-addresses span,
    .I-order-details .I-details-addresses i,
    .I-order-details .I-details-addresses h3 {
      font-size: 12px; }
  .I-order-right-side-headings div,
  .I-order-details-responses div {
    font-size: 12px; }
  .I-order-right-side-headings .I-drop-down,
  .I-order-details-responses .I-drop-down {
    min-width: 100px !important; }
  .I-order-details-responses {
    width: 92% !important; }
  .I-right-side-headings div {
    font-size: 12px;
    white-space: nowrap; }
  .I-ordered-item-description {
    font-size: 12px; }
  .I-order-details-prices {
    margin-top: 25px; }
    .I-order-details-prices div,
    .I-order-details-prices span {
      font-size: 12px; }
  .I-order-filter .I-drop-down {
    min-width: 180px !important; }
  .I-requests-page {
    padding: 0 40px; }
  .I-search-quantity {
    font-size: 15px !important; }
    .I-search-quantity input::placeholder {
      font-size: 14px !important; }
  .I-search-date-picker input {
    font-size: 15px !important; }
    .I-search-date-picker input::placeholder {
      font-size: 14px !important; }
  .react-datepicker__input-container input {
    font-size: 15px !important; }
  .I-request-details-page {
    width: 95%; }
    .I-request-details-page .I-request-details-right-side {
      width: 72%; }
  .I-details-left-side {
    height: 200px !important; }
    .I-details-left-side p,
    .I-details-left-side span {
      font-size: 12px !important; }
  .I-request-details-right-content p,
  .I-request-details-right-content span,
  .I-request-details-right-content i,
  .I-request-details-right-content div {
    font-size: 12px; }
  .I-request-details-right-content .I-drop-down {
    min-width: 150px; }
  .I-request-only-file p,
  .I-request-only-file div,
  .I-request-only-file i {
    font-size: 12px; }
  .I-request-only-file .I-drop-down {
    min-width: 150px; }
  .I-products-page-left p {
    white-space: nowrap; }
  .I-brands-toolbar p {
    white-space: nowrap; }
  .I-brands-page input::placeholder {
    font-size: 14px; }
  .I-category-item-name {
    font-size: 12px; }
  .I-promo-choose-type-disable > div {
    width: 100px;
    padding: 5px; } }
