.I-user-table {
  width       : 90%;
  margin      : 25px auto;
  padding-top : 20px;
  position    : relative;

  .I-data-table {
    a {
      color: black !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

 > p {
    margin-bottom : 10px;
    font-weight   : bold;
  }
}