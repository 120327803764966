.I-request-page-filter {

  .I-brands-page {

    .I-brands-filter {
      width            : 100%;
      height           : 150px;
      margin           : 0 auto;
      background-color : white;
      padding          : 20px 80px;
      display          : flex;
      flex-wrap        : wrap;
      justify-content  : space-between;
      position         : relative;
      box-shadow       : 0 0 16px -10px;

      .date-picker {
        width         : 320px;
        height        : 44px;
        margin        : 10px;
        border-radius : 3px;
        border        : 0;
        text-indent   : 10px;
        border        : 1px solid $filter-input;
      }

      .I-search-input {
        width         : 24%;
        height        : 44px;
        border        : none;
        border-bottom : 1px solid $filter-input;
        border-radius : 3px;
        display       : flex;
        align-items   : center;

        input {
          width       : 85%;
          height      : 42px;
          border      : none;
          font-size   : 18px;
          text-indent : 10px;

          &::placeholder {
            font-size : 16px;
          }
        }

        i {
          font-size   : 18px;
          margin-left : 10px;
          cursor      : pointer;
        }
      }

      input {
        height        : 42px;
        border-radius : 8px;
        border        : 1px solid $filter-input;
      }

      .I-search-drop-down {
        width           : 24%;
        height          : 44px;
        border          : 1px solid black;
        border-radius   : 8px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        cursor          : pointer;

        span {
          margin-left : 10px;
        }

        i {
          margin-right : 10px;
          cursor       : pointer;
        }
      }

      .I-drop-down-hide {
        display : none;
      }

      .with-margin {
        margin-top : 15px;
      }

      .I-search-quantity {
        width           : 24%;
        height          : 44px;
        border          : none;
        border-bottom   : 1px solid $filter-input;
        border-radius   : 3px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;

        hr {
          height : 44px;
          border : 0.5px solid $filter-input;
        }

        input {
          width       : 85%;
          border      : none;
          font-size   : 18px;
          text-indent : 10px;

          &::placeholder {
            font-size : 16px;
          }
        }
      }

      .I-filter-footer {
        width           : 100%;
        margin          : 0 auto;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        .I-filter-apply-buttons {
          margin-bottom : 15px;
          margin-top    : 0 !important;
        }

        .I-show-more-div {
          width           : 180px;
          height          : 50px;
          display         : flex;
          align-items     : center;
          justify-content : space-between;
          cursor          : pointer;
        }
      }
    }
  }
}