.I-request-only-file {
  width  : 95%;
  margin : 15px auto;

  p {
    color       : $main-gray;
    margin-left : 25px;
  }

 
  .I-status-only {   
    display: flex;
    align-items: center;
    justify-content: space-between; 

    .I-drop-down {
      width        : 50px;
      float        : right; 
      margin-right : 25px;
      
      span {
        margin-right : 25px;
      }

      .Open {
        height: 150px !important;
      }

      .I-drop-down-options {
        div {
          padding: 15px 0 !important;          
        }
      }
    }

    .I-drop-down-title {
      p {
        width: calc(100% - 10px) !important;
      }
    } 

    .I-input-status {
      float        : right;
      margin-right : 25px;
    }
  } 

  .I-request-image-flex {
    

    .I-request-right-side-img {
      width               : 200px;
      height              : 150px;
      border              : 2px solid #A5A6A7;
      border-radius       : 8px;
      cursor              : pointer;
      background-position : center;
      background-size     : cover;
      background-repeat   : no-repeat;
    }
  }

  .I-right-side-attach-product {
    width : 100% !important;

    div {
      width  : 95% !important;
      margin : 0 auto;
    }    
  }

  .I-request-files-only {
    width: 95%;
    margin: 25px auto;

    div, audio {
      margin-top: 20px;
    }
  } 
}

.I-modal-picture {
  width               : 750px;
  height              : 550px;
  border-radius       : 10px;
  position            : absolute;
  top                 : 50%;
  left                : 50%;
  transform           : translate(-50%, -50%);
  background-position : center;
  background-size     : contain;
  background-repeat   : no-repeat;
}

.I-request-files-div {
  margin-top  : 50px;
  margin-left : 25px;

  div {
    margin-bottom : 20px;
  }
}