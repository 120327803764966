.I-request-details-page {
  width           : 90%;
  margin          : 25px auto;
  display         : flex;
  justify-content : space-between;

  .I-request-details-right-side {
    width            : 70%;
    border-radius    : 10px;
    box-shadow       : $main-box-shadow;
    background-color : white;

    .I-request-details-right-content {
      width    : 95%;
      margin   : 25px auto;
      padding  : 0 40px;
      position : relative;

      .I-right-side-headings {
        width       : 70%;
        padding     : 0 20px;
        display     : flex;
        align-items : center;

        span {
          color : $main-gray;
          width : 100%;
        }
      }
      
      > i {
        width       : 20px;
        color       : $main-gray;
        cursor      : pointer;
        font-weight : bolder;
        display     : flex;
        align-items : center;
        position    : absolute;
        right       : 1%;
        top         : 0;
      }

      .I-right-side-small-content {
        width       : 80%;
        padding     : 0 20px;
        display     : flex;
        align-items : center;

        span {
          width      : 100%;
          text-align : justify;
        }
      }

      .I-right-side-responses {
        width       : 70%;
        padding     : 0 20px;
        margin-top  : 10px;
        display     : flex;
        align-items : center;

        span {
          width              : 100%;
          text-align         : justify;
          // display            : -webkit-box;
          // -webkit-line-clamp : 2;
          // -webkit-box-orient : vertical;
          overflow           : hidden;
          text-overflow      : ellipsis;
          white-space: nowrap;
        }
        

        .I-drop-down {
          position  : absolute;
          right     : 5%;
          min-width : 180px !important;

          .Open {
            height : 150px !important;
          }

          span {
            width : 25%;
          }
        }

        .I-drop-down {

          .I-drop-down-title {

            p {
              width : 145%;
            }
          }
        }
      }
    }

    .I-right-side-description {
      width      : 95%;
      padding    : 0 20px;
      margin-top : 15px;

      p {
        color         : $main-gray;
        margin-bottom : 15px;
        text-align    : justify;

        &:last-child {
          color : black;
        }
      }
    }

    .I-right-side-file {
      width  : 95%;
      margin : 15px auto;

      .I-request-files {

        audio,video,span {
          margin-top : 15px
        }
      }

      p {
        color         : $main-gray;
        margin-bottom : 15px;
      }

      .I-request-right-side-pdf {
        width       : 100%;
        display     : flex;
        align-items : center;

        i {
          font-size : 22px;
        }

        a {
          text-decoration : none;
          color           : black;
          margin-left     : 25px;
        }
      }

      .I-request-image {
        width         : 100%;
        align-items   : center;
        border-radius : 8px;
        margin-top    : 15px;

        .I-request-right-side-img {
          width               : 200px;
          height              : 150px;
          border              : 2px solid #A5A6A7;
          border-radius       : 8px;
          cursor              : pointer;
          background-position : center;
          background-size     : cover;
          background-repeat   : no-repeat;
        }
      }

    }

    .I-right-side-attach-product {
      width  : 95%;
      margin : 25px auto;

      p {
        color         : $main-gray;
        margin-bottom : 15px;
      }

      div {
        width            : 100%;
        height           : 100px;
        margin-top       : 20px;
        display          : flex;
        flex-direction   : column;
        justify-content  : center;
        align-items      : center;
        border-radius    : 8px;
        background-color : #F0F3F4;
        cursor           : pointer;
        position         : relative;
        
        i {
          font-size : 30px;
          color     : #939EA6;
        }

        span {
          font-size : 18px;
          color     : #939EA6;
        }
      }
    }

    .I-attached-headings {
      width           : 90%;
      padding         : 0 25px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      color           : $main-gray;
    }

    .I-right-side-attach {
      width      : 95%;
      margin     : 15px auto;
      border     : 0;
      border-top : 1px solid $main-gray;

      .I-product-attach-details {
        width       : 95%;
        height      : 70px;
        display     : flex;
        align-items : center;
        margin-top  : 15px;

        .I-attach-img {
          width           : 50px;
          height          : 50px;
          border          : 1px solid #E2E2E2;
          border-radius   : 10px;
          display         : flex;
          align-items     : center;
          justify-content : center;
          margin-right    : 25px;

          img {
            width         : 43px;
            height        : 43px;
            border-radius : 10px;
          }
        }
      }
    }
  }
}

.I-modal-picture {
  width               : 750px;
  height              : 550px;
  border-radius       : 10px;
  position            : absolute;
  top                 : 50%;
  left                : 50%;
  transform           : translate(-50%, -50%);
  background-position : center;
  background-size     : cover;
  background-repeat   : no-repeat;
}

.I-status-dropdown {
  width            : 200px;
  height           : 200px;
  background-color : red;
  position         : absolute;
}