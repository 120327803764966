.I-order-filter {
  width            : 100%;
  height           : 150px;
  margin           : 0 auto;
  background-color : white;
  box-shadow       : 0 0 16px -10px;
  padding          : 20px 80px;

  .I-order-input {
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    .I-drop-down-options.Open  {
      min-height: 160px;
    }

    .I-search-date-picker {
      width           : 24%;
      border          : none;
      border-bottom   : 1px solid $filter-input;
      border-radius   : 3px;
      display         : flex;
      justify-content : space-between;
      align-items     : center;

      .react-datepicker-popper {
        z-index : 1000000;
      }

      hr {
        height : 44px;
        border : 0.5px solid $filter-input;
      }

      input {
        width       : 85%;
        border      : none;
        font-size   : 18px;
        text-indent : 10px;
        z-index: 155000;

        &::placeholder {
          font-size : 16px;
          color : #A9B2B8;
        }
      }
    }

    .I-drop-down-title {
      border : 1px solid $filter-input;
    }
  }

  .I-filter-apply-buttons {
    width           : 100%;
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    position: relative;

    .I-drop-down {
      position: absolute;
      margin-right: 200px;
      left:37%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    .I-search-quantity {
      width           : 24%;
      border          : none;
      border-bottom   : 1px solid $filter-input;
      border-radius   : 3px;
      display         : flex;
      justify-content : space-between;
      align-items     : center;

      hr {
        height : 44px;
        border : 0.5px solid $filter-input;
      }

      input {
        width       : 85%;
        border      : none;
        font-size   : 18px;
        text-indent : 10px;

        &::placeholder {
          font-size : 16px;
        }
      }
    }

    .react-datepicker-popper {
      z-index : 1000000;
    }
  }
}
