.I-details-left-side {
  width            : 20%;
  height           : 250px;
  border-radius    : 10px;
  box-shadow       : $main-box-shadow;
  background-color : white;
  overflow         : hidden;
  position         : fixed;

  hr {
    width         : 80px;
    border        : 2px solid $app-color;
    position      : absolute;
    left          : 12%;
    top           : 5%;
    border-radius : 10px;
  }

  .I-details-left-content {
    padding : 20px;

    p {
      padding : 15px 25px;

      &:first-child {
        font-size   : 25px;
        font-weight : bolder;
      }
    }

    span {
      padding    : 0 25px;
      font-size  : 13px;
      color      : $main-gray;
      margin-top : 20px;
    }
  }
}