.I-change-password {
  width            : 500px;
  height           : 380px;
  background-color : white;
  box-shadow       : $main-box-shadow;
  border-radius    : 8px;
  position         : relative;

  h3 {
    padding-top : 15px;
    margin-left : 25px;
  }

  .I-category-languages {
    width  : 90%;
    margin : 0 auto;

    div {
      margin      : 15px auto;
      display     : flex;
      align-items : center;
    }

    input {
      width         : 100%;
      height        : 35px;
      padding-left  : 7px;
      border-radius : 8px;
      border        : 1px solid $filter-input;
      font-size     : 18px;
    }

    img {
      width       : 18px;
      margin-left : 20px;
    }
  }

  .I-footer-buttons-brand {
    width           : 100%;
    height          : 60px;
    position        : absolute;
    padding         : 0 25px;
    bottom          : 0;
    left            : 0;
    display         : flex;
    align-items     : center;
    justify-content : flex-end;
    box-shadow      : $main-box-shadow;

    span {

      .save {
        background-color : $app-color;
        color            : white;
      }

      .disabled {
        background-color : rgba(0, 0, 0, 0.1);
        color            : rgba(0, 0, 0, 0.3);
        border           : none;
        cursor           : default;
      }

      button {
        width         : 120px;
        height        : 40px;
        margin-left   : 20px;
        border        : none;
        border-radius : 10px;

        &:first-child {
          color            : black;
          background-color : white;
        }
      }
    }
  }
}
