.I-checkbox {  
  width           : 20px;
  cursor          : pointer;
  height          : 20px;
  border          : 1px solid;
  display         : flex;
  align-items     : center;
  justify-content : center;
  margin-right    : 10px;
  border-radius   : 4px;
  
  > img {
    width  : 14px;
    margin : auto;
  }

  &.I-radio { border-radius: 50% }
}