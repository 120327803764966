.switch {
  position : relative;
  display  : inline-block;
  width    : 55px;
  height   : 30px;
}

.switch input { 
  opacity : 0;
  width   : 0;
  height  : 0;
}

.slider {
  position           : absolute;
  cursor             : pointer;
  top                : 0;
  left               : 0;
  right              : 0;
  bottom             : 0;
  background-color   : #F1F1F1;
  -webkit-transition : .4s;
  transition         : .4s;
}


.slider:before {
  position           : absolute;
  content            : "";
  height             : 22px;
  width              : 22px;
  left               : 4px;
  bottom             : 4px;
  background-color   : #CCCDCE;
  -webkit-transition : .4s;
  transition         : .4s;
}

input:checked + .slider {
  background-color : $app-color;
}

input:focus + .slider {
  box-shadow : 0 0 1px $app-color;
}

input:checked + .slider:before {
  -webkit-transform : translateX(26px);
  -ms-transform     : translateX(26px);
  transform         : translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius : 34px;
}

.slider.round:before {
  border-radius : 50%;
}