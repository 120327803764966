.I-chat-page {
  width            : 100%;
  height           : 100%;
  background-color : white;
  box-shadow       : $main-box-shadow;
  position         : relative;

  .I-main-chat-div {
    width   : 100%;
    display : flex;
    height  : 100%;

    .I-left {
      width        : 25%;
      height       : 100%;
      z-index      : 100;
      border-right : 1px solid #e1e4e6;
      overflow-y   : auto;

      .I-left-side-head {
        width           : 100%;
        height          : 100px;
        border-bottom   : 1px solid #e1e4e6;
        display         : flex;
        align-items     : center;
        justify-content : center;

        div {
          width         : 90%;
          border-radius : 8px;
          border : 1px solid #e1e4e6;         
        }
      }

      .I-left-side-conversation-list {        
 
  
        .I-conversation-block {
          width            : 100%;
          height           : 120px;
          background-color : white;
          border-bottom    : 1px solid #e1e4e6;
          border-left      : 1px solid #e1e4e6;
          cursor           : pointer;
          transition       : 1s;
              
          // .I-border-left {
          //   border-left      : 2px solid $app-color;        
          // }

          .I-message-head {
            width           : 90%;
            margin          : 0 auto;
            display         : flex;
            align-items     : center;
            justify-content : space-between;
 
            .I-message-headings {
              margin-top  : 15px;
              display     : flex;
              align-items : center;

              p {
                width       : 100%;
                line-height : 25px;
                color       : #77778a;
              }
            }

            .I-chat-seen {
              width            : 10px;
              height           : 10px;
              background-color : $app-color;
              border-radius    : 50%;
              margin-left      : 20px;
            }

            > p {
              margin-top : 15px;
              font-size  : 12px;
              color      : $main-gray;
            }
          }

          .I-message-content {
            width              : 90%;
            margin             : 20px auto;
            display            : block;
            display            : -webkit-box;
            height             : 43px;
            font-size          : 16px;
            line-height        : 1.5;
            -webkit-line-clamp : 2;
            -webkit-box-orient : vertical;
            text-overflow      : ellipsis;

            p {
              width: 200px;
              overflow: hidden;
              text-overflow      : ellipsis;
              white-space   : nowrap;
            }
          }
        }
      }
    }

    .I-chat-right-side {
      width         : 100%;
      border-bottom : 1px solid #e1e4e6;

      .I-header {
        width            : 100%;
        height           : 100px;
        z-index          : 100;
        background-color : #f0f3f4;
        display          : flex;
        align-items      : center;
        justify-content  : center;
   

        p {
          font-weight      : bold;
          font-size        : 20px;
          cursor           : pointer;
        }
      }

      .I-content {
        width            : 100%;
        height           : 70vh;
        overflow-y       : auto;
        background-color : #f0f3f4;

        .I-content-chat {
          width  : 85%;
          margin : 40px auto;

          .I-sms {
            
            .I-chat-messages {
              width           : 100%;
              display         : flex;
              justify-content : space-between;

              .I-sended-sms {
                max-width              : 100%;
                border-radius          : 0 25px 25px 25px;
                border-top-left-radius : 0;
                background-color       : white;
                margin-bottom          : 20px;
                color                  : black;
                box-shadow             : $main-box-shadow;
                position               : relative;

                p {
                  margin-left : 10px;
                  padding     : 15px;
                }

               
              }

              .I-answered-sms {
                max-width        : 100%;
                border-radius    : 25px 0 25px 25px;
                background-color : $app-color;
                margin-bottom    : 20px;
                color            : white;
                box-shadow       : $main-box-shadow;

                p {
                  margin-left : 10px;
                  padding     : 15px;
                }
              }        
            }

                 
            .I-request-image {
              width         : 100%;
              align-items   : center;
              border-radius : 8px;
              margin-bottom : 15px;

              .I-request-right-side-img {
                width               : 100px;
                height              : 100px;
                border              : 2px solid #a5a6a7;
                border-radius       : 8px;
                cursor              : pointer;
                background-position : center;
                background-size     : cover;
                background-repeat   : no-repeat;
              }
            }

            audio {
              width         : 50%;
              border        : 1px solid $main-gray;
              padding       : 0;
              margin-bottom : 15px;
              border-radius : 8px;
            }
          }
        }
      }

      .disabled {
        background-color : rgba(0, 0, 0, .1);
        color            : rgba(0, 0, 0, .3);
        border           : none;
        cursor           : default;
      }

      .I-chat-page-bottom {
        width            : 100%;
        height           : 80px;
        background-color : white;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        padding          : 0 30px;

        .I-write-message {
          width           : 90%;
          height          : 60px;
          padding         : 0 20px;
          border-radius   : 8px;
          border          : 1px solid $main-gray;
          display         : flex;
          align-items     : center;
          justify-content : space-between;

          .I-chat-icons {
            width           : 50px;
            display         : flex;
            align-items     : center;
            justify-content : space-between;

            i {
              font-size : 18px;
              cursor    : pointer;
            }
          }

          .I-chat-type-message {
            width : 90%;

            input {
              width      : 95%;
              resize     : none;
              overflow   : hidden;
              border     : none;
              min-height : 45px;
              max-height : 100px;
            }
          }

          button {
            width            : 120px;
            height           : 30px;
            background-color : white;
            border           : 1px solid $main-gray;
          }
        }
      }
    }
  }
}
