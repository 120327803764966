 .I-header-notifications-page {
   padding-top : 50px;

   .I-notification-page-header {
     width  : 90%;
     margin : 0 auto;
     
    > p {
      margin-bottom : 10px;
      font-weight   : bold;
    }
   }

   .I-notification-page-button  {
    width         : 90%;
    margin        : 0 auto;
    margin-bottom : 20px;

    a {
      width            : 160px;
      height           : 40px;
      border           : none;
      border-radius    : 5px;
      font-size        : 15px;
      background-color : #29A27D;
      color            : white;
      text-decoration  : none;
      display          : flex;
      align-items      : center;
      justify-content  : center;
      cursor           : pointer;
    }
   }
   
  .I-data-table,
  .I-table-footer {
    width  : 90%;
    margin : 0 auto;
  }
 }
