.I-category-details {
  width           : 100%;
  padding         : 80px 0;
  display         : flex;
  justify-content : center;
  align-items     : center;

  .I-category-content {
    width            : 60vw;
    min-height       : 75vh;
    padding          : 30px 0;
    background-color : white;
    box-shadow       : 0 0 25px -10px;
    border-radius    : 5px;

    h3 {
      margin-top  : 25px;
      margin-left : 45px;
    }

    .I-category-languages {
      width       : 90%;
      margin      : 0 auto;
      padding-top : 40px;

      input {
        width         : 100%;
        height        : 35px;
        margin-bottom : 20px;
        border-radius : 5px;
        border        : 1px solid $filter-input;
        font-size     : 14px;
        padding-left  : 7px;
      }

      textarea {
        width         : 100%;
        height        : 100px;
        border-radius : 5px;
        border        : 1px solid $filter-input;
        text-indent   : 20px;
        font-size     : 18px;
        resize        : none;
        padding       : 10px;
      }

      div {
        margin-bottom : 10px;
        font-size     : 16px;

        img {
          width        : 16px;
          height       : 16px;
          margin-right : 10px;
        }
      }

      label {
        width         : 341px;
        height        : 245px;
        padding       : 100px;
        border-radius : 8px;
        background    : #f0f3f4;
        margin-top    : 20px;
        display       : table;
        font-size     : 18px;
        color         : #a0aab0;
        cursor        : pointer;
        position      : relative;

        .I-uploaded-background {
          width           : 200px;
          position        : absolute;
          left            : 50%;
          top             : 50%;
          transform       : translate(-50%,-50%);
          display         : flex;
          align-items     : center;
          justify-content : space-between;
        }

        input[type="file"] {
          display : none;
        }

        .I-upload-image {
          width               : 100%;
          height              : 100%;
          position            : absolute;
          border              : 0;
          left                : 0;
          top                 : -1%;
          border-radius       : 8px;
          background-position : center;
          background-size     : contain;
          background-repeat   : no-repeat;

          &:hover {
            background-color : rgba(0, 0, 0, 0.3);
          }
        }
      }

      .I-categories-icons {
        width           : 100%;
        display         : flex;
        justify-content : space-between;
      }
    }
  }

  .I-category-add-footer {
    min-width        : 60vw;
    height           : 60px;
    background-color : white;
    box-shadow       : 0 0 25px -10px;
    border-radius    : 5px;
    bottom           : 0;
    z-index          : 1;
    position         : absolute;
    display          : flex;
    align-items      : center;

    div {
      width           : 95%;
      display         : flex;
      justify-content : flex-end;

      button {
        width         : 120px;
        height        : 40px;
        margin-left   : 20px;
        border-radius : 8px;
        border        : none;

        &:first-child {
          background-color : white;
          color            : black;
        }
      }


    }
    .save {
      background-color : $app-color;
      color            : white;
    }

    .disabled {
      background-color : rgba(0, 0, 0, 0.1);
      color            : rgba(0, 0, 0, 0.3);
      border           : none;
      cursor           : default;
    }
  }
}
