.I-requests-page {
  width            : 100%;
  height           : 100vh;
  padding          : 40px 80px;
  background-color : #F0F3F4;

  .I-data-table {
    a {
      color: black !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .I-request-page-toolbar {
    width           : 100%;
    height          : 120px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    position        : relative;

    p {
      position    : absolute;
      bottom      : 10%;
      font-weight : bold;
      left        : 0;
    }

    button {
      width            : 80px;
      height           : 40px;
      border-radius    : 5px;
      background-color : white;
      border           : 1px solid #CBD1D4;
      margin-left      : 15px;
    }

    .I-request-repeat {
      width            : 40px;
      height           : 40px;
      display          : flex;
      justify-content  : center;
      align-items      : center;
      background-color : white;
      border           : 1px solid;
      border-radius    : 5px;

      i {
        font-size  : 20px;
        cursor     : pointer;
        transition : 1s;
      }
    }
  }

  .I-request-page-content {
    width            : 100%;
    height           : 100px;
    background-color : white;
    border-radius    : 10px 10px 0 0;
    border-bottom    : 1px solid gray;

    .I-request-page-headings {
      width           : 97%;
      padding         : 40px;
      display         : flex;
      justify-content : space-between;
      align-items     : center;
      font-weight     : bolder;

      div {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
      }
    }
  }

  .I-request-page-list {
    width            : 100%;
    background-color : white;
    display          : flex;
    justify-content  : space-between;
    align-items      : center;
    padding          : 20px 40px;

    .I-request-checkbox {
      width       : 100%;
      display     : flex;
      align-items : center;
    }

    .I-request-quantity {
      width : 108%;
    }

    .I-request-page-hours {
      width        : 114%;
      margin-right : 10px;
    }

    .I-request-name {
      width : 115%;
    }

    .I-request-page-status {
      width            : 100%;
      height           : 30px;
      background-color : red;
      display          : flex;
      align-items      : center;
      justify-content  : center;
      text-align       : center;
      color            : white;
      border-radius    : 10px;
      font-weight      : bolder;
    }

    .I-request-page-status-yellow {
      width            : 35%;
      height           : 30px;
      display          : flex;
      align-items      : center;
      justify-content  : center;
      background-color : #ECAD17;
      text-align       : center;
      border-radius    : 10px;
      font-weight      : bolder;
      color            : white;
    }
  }
}

.I-request-page-footer {
  width           : 100%;
  height          : 60px;
  margin-top      : 40px;
  display         : flex;
  justify-content : space-between;
  align-items     : center;

  .I-request-page-panel {
    width           : 300px;
    height          : 50px;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    .I-request-page-number {
      padding          : 5px 15px;
      background-color : white;
      border           : 1px solid gray;
      border-radius    : 5px;
    }

    .I-request-page-slide {
      width           : 100px;
      height          : 50px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      div {
        width            : 40px;
        height           : 40px;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        border-radius    : 7px;
        background-color : #334856;
        color            : white;
        cursor           : pointer;

        i {
          font-size : 18px;
        }
      }
    }
  }
}

.I-request-repeat {
  width            : 40px;
  height           : 40px;
  display          : flex;
  justify-content  : center;
  align-items      : center;
  background-color : white;
  border           : 1px solid;
  border-radius    : 5px;

  i {
    font-size  : 20px;
    cursor     : pointer;
    transition : 1s;
  }
}