.I-uploaded-image {

  width         : calc(25% - 30px);
  margin        : 30px 15px 0 15px;
  height        : 200px;
  cursor        : pointer;
  overflow      : hidden;
  border-radius : 12px;

  &:hover > .I-delete-wrapper { display: flex; }

  > .I-delete-wrapper {
    width           : 20%;
    padding         :     20px;
    color           : white;
    display         : none;
    font-size       : 20px;
    background      : transparentize(#000000, .5);
    align-items     : center;
    justify-content : center;
    position        : relative;
    z-index         : 5555555555555555;
  }
}

.I-add-product-page {
  padding : 80px 0;

  .I-add-product-headings {
    width  : 60%;
    margin : 0 auto;

    h5 {
      color : #AEB2B3;
    }
  }

  .I-add-product-step {
    width            : 50vw;
    min-height       : 75vh;
    margin           : 20px auto;
    padding          : 30px 0;
    box-shadow       : 0 0 25px -10px;
    background-color : white;
    border-radius    : 5px;

    .I-add-product-images {
      padding : 40px 0;

      .I-uploader {
        width           : calc(100% - 80px);
        color           : #b6bec3;
        cursor          : pointer;
        margin          : 0 40px;
        padding         : 60px 0;
        display         : flex;
        flex-wrap       : wrap;
        font-size       : 18px;
        text-align      : center;
        background      : #eff3f4;
        border-radius   : 12px;
        justify-content : center;

        > i {
          width         : 100%;
          font-size     : 40px;
          margin-bottom : 10px;
        }

        > input { display: none };
      }

      .I-uploaded-container {

        width     : 100%;
        display   : flex;
        flex-wrap : wrap;
      }

      .I-uploaded-images {

        width     : calc(100% - 60px);
        margin    : 0 30px 0;
        display   : flex;
        flex-wrap : wrap;
      }
    }

    .I-select-language {
      width         : 90%;
      height        : 80px;
      margin        : 20px auto;
      border-bottom : 1px solid #97A2A8;
      display       : flex;
      align-items   : flex-end;

      .I-active-border {
        border-bottom : 5px solid $app-color;
      }

      div {
        padding       : 5px 0;
        margin-right  : 20px;
        width         : 110px;
        display       : flex;
        align-items   : center;
        cursor        : pointer;
        border-bottom : 5px solid transparent;

        img {
          width        : 18px;
          margin-right : 10px;
        }
      }
    }

    .I-add-product-content {
      width          : 90%;
      margin         : 20px auto;
      padding-bottom : 30px;


      > .I-product-brand { 

        position   : relative;
        margin-top : 20px;

        > .I-brand-drop-down {
          width            : 100%;
          height           : auto;
          background-color : white;
          position         : absolute;
          left             : 0;
          top              : 68px;
          border-radius    : 8px;
          box-shadow       : 0 0 25px -10px;
          padding          : 0 20px;
          font-weight      : bolder;
          overflow-y       : auto;
          max-height       : 200px;
          z-index          : 100;
  
          p { cursor: pointer;
               margin-top: 10px; }
        }
      }

      .I-attributes-image-choose {

        width         : 45px;
        height        : 45px;
        border-radius : 8px;
        border        : 1px solid #999;
        cursor        : pointer;
        position      : relative;

        > div {

          width      : 300px;
          height     : 300px;
          padding    : 20px;
          background : white;
        }
      }

      > .I-generate-variants {

        color         : white;
        border        : 0;
        margin        : 20px 0 40px;
        padding       : 10px 20px;
        background    : $app-color;
        border-radius : 8px;
        
        &:first-of-type { margin-right: 20px; }
      }

      > .I-product-multi-select {

        padding-top : 40px;

        > span { display: none !important; }
        
        > div:first-of-type {
          z-index    : 1000;
          border     : 1px solid #999 !important;
          min-height : 40px;
          box-shadow : none;
        }

         > div {
          &:last-child {
            z-index: 50000000;
          }
        }
      }

      &.I-add-product-pricing {

        padding-top : 40px;

        > h2 {

          width       : 100%;
          margin      : 30px 0;
          font-weight : 300;

          &:first-child { margin-top: 0; }
        }

        > .I-product-pricing-date-input {

          float        : left;
          width        : calc(50% - 10px);
          margin-right : 20px;

          &:last-child { margin-right: 0; }

          > .react-datepicker-wrapper {

            width      : 100%;
            margin-top : 10px;

            > .react-datepicker__input-container {

              width : 100%;

              > input { width: 100%; }
            }
          }
        }
      }

      &.I-add-product-categories {

        min-height  : inherit;
        padding-top : 30px;

        > .I-tree {

          height     : 0;
          min-height : inherit;
        }

        .rst__moveHandle { display: none; }

        .rst__rowContents {

          border     : 1px solid #999;
          box-shadow : none;
        }

        .rst__rowTitle {

          display : flex;

          > .I-checkbox { margin-right: 10px; }

          > .I-categories-include-line {

            content       : ' ';
            cursor        : pointer;
            position      : absolute;
            width         : 22px;
            height        : 22px;
            left          : -34px;
            top           : 9px;
            border        : 2px solid $app-color;
            border-radius : 50%;
          }  
        }
      }

      &.I-add-product-type {

        width          : 100%;
        margin         : 0;
        display        : flex;
        flex-wrap      : wrap;
        padding-top    : 30px;
        padding-bottom : 0;

        > .I-type-choose-content {

          width  : calc(50% - 10px);
          margin : 0 20px 30px 0;

          > .I-add-product-text-switch {

            display         : flex;
            width           : 100%;
            justify-content : space-between;
            border          : 1px solid #CBD1D4;
            padding         : 5px;
            margin-top      : 10px;
            height          : 38px;
            border-radius   : 8px;

            > div {

              border-radius   : 8px;
              cursor          : pointer;
              display         : flex;
              width           : calc(50% - 5px);
              transition      : .2s;
              align-items     : center;
              justify-content : center;

              &.I-active-text {
                
                color      : white;
                background : $app-color;
              }
            }
          }

          &:nth-of-type(2), &:nth-of-type(5) { margin-right: 0; }
        }
      }

      .I-add-new {
  
        width  : 100%;
        color  : $app-color;
        cursor : pointer;
      }

      > .I-pricing-default-price { margin-bottom: 20px; }

      > .I-pricing-row {

        display         : flex;
        align-items     : flex-end;
        margin-bottom   : 20px;
        justify-content : space-between;

        > .I-pricing-content {

          width : calc(100% / 3 - 20px);
          float : left;
        }

        > .icon-close {

          cursor         : pointer;
          font-size      : 20px;
          padding-bottom : 10px;
        }
      }

      &.I-add-features-content {

        margin    : 0 auto;
        display   : flex;
        flex-wrap : wrap;

        > .I-features-content {

          width         : calc(50% - 50px);
          margin-right  : 30px;
          margin-bottom : 20px;
        }
    
        > .icon-close {
  
          cursor      : pointer;
          height      : 68px;
          font-size   : 25px;
          margin-left : 15px;
          line-height : 95px;
  
          &.I-disabled-remove {
  
            cursor  : not-allowed;
            opacity : .3;
          }
        }
      }

      input {
        padding       : 10px;
        border-radius : 8px;
        border        : 1px solid #CBD1D4;
      }

      .I-product-name-input {
        width      : 100%;
        margin-top : 10px;
      }

      .I-product-articles {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        margin-top      : 20px;

        > p {

          width : calc(50% - 10px);
          
          &:first-child { margin-right: 20px; }
        }
      }

      .I-product-two-content {
        width           : 100%;
        margin-top      : 20px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;

        input {
          width  : calc(50% - 10px);
          height : 44px;
          border : 1px solid #CBD1D4;
        }

        > .I-drop-down { width : calc(50% - 10px); }
      }

      .I-products-description {
        margin-top : 20px;

        .I-product-description-input {
          width      : 100%;
          border     : 1px solid #CBD1D4;
          height     : 100px;
          overflow-y : hidden;
          margin-top : 20px;
        }
      }
    }

    .I-products-footer {
      width            : 50vw;
      height           : 70px;
      bottom           : 0;
      z-index          : 1;
      position         : absolute;
      background-color : white;
      border-radius    : 5px 5px 0 0;
      box-shadow       : 0 0 25px -10px;
      display          : flex;
      justify-content  : flex-end;
      align-items      : center;
      padding          : 0 40px;

      .I-finish-button {
        background-color : $app-color;
        color            : white;
      }

      button {
        margin-left      : 20px;
        width            : 100px;
        height           : 40px;
        border           : none;
        border-radius    : 5px;
        background-color : white;

        &.I-products-back {
          
          width            : auto;
          margin           : 0 auto 0 0;
          background-color : white !important;
        }

        &:last-child {
          background-color : $app-color;
          color            : white;
        }
      }
    }
  }

  .disabled {
    background-color : rgba(0, 0, 0, .1) !important;
    color            : rgba(0, 0, 0, .3) !important;
    border           : none;
    cursor           : default;
  }
}

.I-attributes-image-modal {

  width      : 600px;
  height     : 400px;
  padding    : 20px;
  overflow-y : auto;

  > div {

    width         : calc(100% / 3 - 20px);
    height        : 150px;
    margin        : 10px;
    cursor        : pointer;
    float         : left;
    border-radius : 8px;

    &.I-choose-no-image {

      background      : #eee;
      display         : flex;
      justify-content : center;
      align-items     : center;
    }
  }
}