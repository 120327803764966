.I-accept-choice {
  width            : 350px;
  height           : 120px;
  display          : flex;
  flex-direction   : column;
  justify-content  : space-between;
  padding          : 20px;
  border-radius    : 15px;
  background-color : white;
  
  .I-accept-choice-action {
    width           : 100%;
    height          : 35px;
    display         : flex;
    flex-direction  : row;
    justify-content : flex-end;

    > button {
      width  : 100px;
      height : 100%;
    }

    .cancel {
      background-color : white;
      border           : none;
    }

    .accept {
      border-radius    : 5px;
      background-color : $app-color;
      border           : none;
      color            : white
    }
  }
}