.cssload-spin-box {
  position           : absolute;
  left               : 55%;
  top                : 55%;
  transform          : translate(-50%,-55%);
  width              : 15px;
  height             : 15px;
  border-radius      : 100%;
  box-shadow         : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -o-box-shadow      : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -ms-box-shadow     : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -webkit-box-shadow : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  -moz-box-shadow    : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  animation          : cssload-spin ease infinite 2.8s;
  -o-animation       : cssload-spin ease infinite 2.8s;
  -ms-animation      : cssload-spin ease infinite 2.8s;
  -webkit-animation  : cssload-spin ease infinite 2.8s;
  -moz-animation     : cssload-spin ease infinite 2.8s;
  z-index            : 5555555555555555555;
}

@keyframes cssload-spin {

  0%,
  100% {
    box-shadow : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  }

  25% {
    box-shadow : -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249;
  }

  50% {
    box-shadow : -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F;
  }

  75% {
    box-shadow : 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249;
  }
}

@-o-keyframes cssload-spin {

  0%,
  100% {
    box-shadow : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  }

  25% {
    box-shadow : -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249;
  }

  50% {
    box-shadow : -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F;
  }

  75% {
    box-shadow : 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249;
  }
}

@-ms-keyframes cssload-spin {

  0%,
  100% {
    box-shadow : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  }

  25% {
    box-shadow : -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249;
  }

  50% {
    box-shadow : -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F;
  }

  75% {
    box-shadow : 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249;
  }
}

@-webkit-keyframes cssload-spin {

  0%,
  100% {
    box-shadow : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  }

  25% {
    box-shadow : -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249;
  }

  50% {
    box-shadow : -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F;
  }

  75% {
    box-shadow : 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249;
  }
}

@-moz-keyframes cssload-spin {

  0%,
  100% {
    box-shadow : 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F;
  }

  25% {
    box-shadow : -15px 15px #ED323F, -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249;
  }

  50% {
    box-shadow : -15px -15px #2B3249, 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F;
  }

  75% {
    box-shadow : 15px -15px #ED323F, 15px 15px #2B3249, -15px 15px #ED323F, -15px -15px #2B3249;
  }
}