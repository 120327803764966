.I-user-details {
  width           : 90%;
  margin          : 40px auto;
  display         : flex;
  justify-content : space-between;

  .I-user-details-info {
    width          : 25%;
    height         : 100%;
    display        : flex;
    flex-direction : column;

    > div {
      width            : 100%;
      height           : auto;
      box-shadow       : $main-box-shadow;
      background-color : white;
      border-radius    : 10px;
      margin-bottom      : 20px;
    }

    .I-order-details-name {
      width          : 100%;
      padding        : 10px 30px;
      display        : flex;
      flex-direction : column;

      i {
        font-size : 15px;
        color     : $app-color;
      }

      .I-details-name {
        font-size   : 22px;
        font-weight : bold;
      }

      hr {
        width         : 80px;
        border        : 2px solid $app-color;
        border-radius : 20px;
      }

      div {
        display         : flex;
        align-items     : center;
        justify-content : space-between;
        margin-top      : 20px;

        span {
          width       : 100%;
          margin-left : 10px;
        }
      }
    }

    .I-user-details-counts {
      width          : 100%;
      padding        : 10px 30px;
      display        : flex;
      flex-direction : column;

      div {
        margin-top      : 10px;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        span {
          width : 100%;

          &:first-child {
            width       : 250px;
            font-weight : bold;
            font-size   : 15px;
          }

          &:last-child {
            width       : 200px;
            font-weight : bold;
            font-size   : 15px;
          }
        }
      }
    }

    .I-block-user {
      width            : 100%;
      height           : 100px;
      background-color : white;
      display          : flex;
      align-items      : center;
      justify-content  : space-between;
      padding          : 0 30px;
      font-weight      : bold;

      input:checked + .slider {
        background-color : red;
      }

      input:focus + .slider {
        box-shadow : 0 0 1px red;
      }
    }

    .I-choose-status {
      width            : 100%;
      height           : 100px;
      background-color : white;
      display          : flex;
      align-items      : center;
      justify-content  : space-between;
      padding          : 0 30px;
      font-weight      : bold;

      > div {
        display        : flex;
        flex-direction : column;
        align-items    : center;

        &:nth-child(2) {
          color: silver;
        }

        &:last-child {
          color: gold;
        }

        input {
          width      : 20px;
          height     : 20px;
          margin-top : 10px;
        }
      }
    }
  }

  .I-user-page-right-side {
    width          : 70%;
    display        : flex;
    flex-direction : column;

    .I-user-choose-status {
      width            : 100%;
      height           : 70px;
      padding          : 0 40px;
      display          : flex;
      align-items      : center;
      box-shadow       : $main-box-shadow;
      background-color : white;
      border-radius    : 10px;

      .I-choose-list {
        height       : 30px;
        margin-right : 15px;
        font-size    : 18px;
        font-weight  : bold;
        cursor       : pointer;
      }

      .I-selected-list {
        border-bottom : 3px solid $app-color;
      }
    }

    .I-user-orders-requests {
      margin-top : 25px;
    }
  }
}
