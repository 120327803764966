.I-mu-add-edit {
  width            : 500px;
  height           : 420px;
  background-color : white;
  border-radius    : 15px;
  position         : relative;

  h3 {
    margin-left : 25px;
    margin-top  : 20px;
  }

  .I-category-languages {
    width       : 90%;
    padding-top : 20px;

    div {
      margin          : 15px auto;
      margin-bottom   : 15px;
      display         : flex;
      align-items     : center;

      p {
        margin-left: 10px;
      }
    }

    input {
      width         : 100%;
      height        : 35px;
      margin-bottom : 10px;
      border-radius : 5px;
      border        : 1px solid $filter-input;
      font-size     : 14px;
      padding-left  : 7px;
      margin-left   : 20px;
    }

    img {
      width       : 16px;
      margin-left : 20px;
    }
  }

  .I-footer-buttons-brand {
    width           : 100%;
    height          : 60px;
    padding         : 0 25px;
    position        : absolute;
    bottom          : 0;
    display         : flex;
    align-items     : center;
    justify-content : flex-end;
    box-shadow      : $main-box-shadow;

    span {

      .save {
        background-color : $app-color;
        color            : white;
      }

      .disabled {
        background-color : rgba(0, 0, 0, .1);
        color            : rgba(0, 0, 0, .3);
        border           : none;
        cursor           : default;
      }

      button {
        width         : 120px;
        height        : 40px;
        margin-left   : 20px;
        border        : none;
        border-radius : 10px;

        &:first-child {
          color            : black;
          background-color : white;
        }
      }
    }
  }
}