.I-attribute-page {
  width          : 100%;
  display        : flex;
  flex-direction : column;

  .I-data-table,
  .I-table-footer {
    width  : 90%;
    margin : 0 auto;
  }

  .I-attribute-page-header {
    width           : 90%;
    margin          : 0 auto;
    height          : 120px;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    align-items     : center;
    position        : relative;

    p {
      position    : absolute;
      bottom      : 10%;
      font-weight : bold;
      left        : 0;
    }

    >button {
      width            : 160px;
      height           : 40px;
      border           : none;
      border-radius    : 5px;
      font-size        : 15px;
      background-color : $app-color;
      color            : white;
    }

    .I-attribute-page-header-actions {
      display     : flex;
      align-items : center;
      height      : 100%;

      >button {
        width            : 120px;
        height           : 40px;
        border-radius    : 5px;
        background-color : white;
        border           : 1px solid #CBD1D4;
        margin-left      : 20px;
      }
    }
  }
}

.I-attribute-add {
  width           : 100%;
  display         : flex;
  flex-direction  : column;
  justify-content : space-between;
  align-items     : center;
  padding         : 80px 0;
  position        : relative;

  .I-attribute-add-main {
    width            : 50vw;
    height           : 600px;
    display          : flex;
    flex-direction   : column;
    justify-content  : space-between;
    border-radius    : 5px;
    box-shadow       : $main-box-shadow;
    padding          : 40px;
    background-color : white;

    .I-attribute-add-body {
      width           : 100%;
      height          : 100%;
      display         : flex;
      flex-direction  : column;
      justify-content : space-between;
      padding-top     : 40px;

      .I-attribute-add-item {
        height          : 65px;
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;

        
        // .I-drop-down-options { 
        //   height: 250px !important;
        // }

        >div {
          display        : flex;
          flex-direction : row;

          >p {
            margin-left : 10px;
          }

        }

        .I-attribute-add-item-img {
          width               : 16px;
          height              : 16px;
          background-position : center;
          background-size     : cover;
        }

        >input {
          width         : 100%;
          height        : 35px;
          border-radius : 5px;
          border        : 1px solid rgba(0, 0, 0, 0.3);
          padding-left  : 7px;
          font-size     : 14px;
        }

        .I-drop-down {
          width : 100%;

          >div {
            width : 100%;

            >div {
              width  : 100%;
              height : 35px;

              >div {
                color : rgba(0, 0, 0, .3);

                >p {
                  color     : black;
                  font-size : 14px;
                }
              }
            }
          }
        }

        .I-attribute-disable-item {
          width            : 100%;
          height           : 35px;
          border           : 1px solid rgba(0, 0, 0, .3);
          border-radius    : 5px;
          align-items      : center;
          font-size        : 14px;
          background-color : rgba(0, 0, 0, .05);
          cursor           : not-allowed;
        }

      }

    }

  }

  .I-attribute-add-option {
    width            : 50vw;
    height           : 100%;
    display          : flex;
    flex-direction   : column;
    justify-content  : space-between;
    border-radius    : 5px;
    box-shadow       : $main-box-shadow;
    margin-top       : 10px;
    background-color : white;

    .I-attribute-add-option-header {
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;
      align-items     : center;
      margin          : 40px;

      .I-attribute-add-buttons {
        display : flex;

        button {
          width            : 120px;
          height           : 40px;
          border-radius    : 5px;
          align-items      : center;
          font-size        : 14px;
          border           : 1px solid rgba(0, 0, 0, .4);
          background-color : white;
          color            : black;
          margin-left      : 25px;
        }

        .disabled {
          background-color : rgba(0, 0, 0, .1);
          color            : rgba(0, 0, 0, .3);
          border           : none;
          cursor           : default;
        }
      }

    }

    >.I-attribute-add-option-body-header {
      width           : 100%;
      height          : 35px;
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;
      padding         : 0 110px 0 40px;
      border-bottom   : 2px solid rgba(0, 0, 0, .1);
    }

    >.I-attribute-add-option-body-body {
      width   : 100%;
      padding : 20px 110px 100px 40px;

      >div {
        width           : 100%;
        height          : 35px;
        display         : flex;
        flex-direction  : row;
        justify-content : space-between;
      }
    }

    >.I-data-table {
      box-shadow : none;
      margin-top : -20px;

      .I-data-table-header {
        font-weight : 400;

        >li {
          font-size : 16px;
          overflow  : auto;
        }
      }

      .I-data-table-body {

        >ul:hover {
          background-color : white !important;
          cursor           : default;
        }
      }
    }

    .I-data-table-body ul {

      li {
        position : relative;

        &:nth-child(3) {
         position : absolute;
         left     : 50%;
         overflow : unset !important;
        }

        &:nth-child(2) {
          position : absolute;
          left     : 35%;
          overflow : unset !important;
         }

      }
    }

    .I-option-input {
      width         : 40px;
      height        : 25px;
      border-radius : 2px;
      text-align    : center;
      border        : 1px solid $main-gray;
      color         : black;
    }
  }

  .I-attribute-add-save {
    width            : 50vw;
    height           : 70px;
    display          : flex;
    justify-content  : flex-end;
    border-radius    : 5px;
    box-shadow       : $main-box-shadow;
    padding          : 20px 40px;
    margin-top       : 50px;
    background-color : white;
    position         : fixed;
    bottom           : 0;
    z-index          : 1000;

    >div {
      width           : 180px;
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;
      align-items     : center;

      .cancel {
        border           : none;
        background-color : white;
      }

      .save {
        width            : 100px;
        height           : 32px;
        border-radius    : 5px;
        align-items      : center;
        border           : none;
        background-color : $app-color;
        color            : white;
      }

      .disabled {
        background-color : rgba(0, 0, 0, .1);
        color            : rgba(0, 0, 0, .3);
        border           : none;
        cursor           : default;
      }
    }
  }
}

.I-add-color-option {
  height : 65vh;
}

.I-add-usual-option {
  height : 45vh;
}

.I-add-option {
  width          : 35vw;
  min-height     : 550px;
  display        : flex;
  flex-direction : column;

  .I-add-option-header {
    height          : 80px;
    display         : flex;
    flex-direction  : row;
    justify-content : center;
    align-items     : center;
    border-bottom   : 1px solid grey;

    >p {
      font-size   : 20px;
      font-weight : 500;
    }
  }

  .I-add-option-body {
    height          : 80%;
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
    padding         : 40px 30px 30px 30px;

    .I-add-option-item {
      height          : 65px;
      display         : flex;
      flex-direction  : column;
      justify-content : space-between;

      >div {
        display        : flex;
        flex-direction : row;

        >p {
          margin-left : 10px;
        }

      }

      .I-add-option-item-img {
        width               : 16px;
        height              : 16px;
        background-position : center;
        background-size     : cover;
      }

      >input {
        width         : 100%;
        height        : 35px;
        border-radius : 5px;
        border        : 1px solid rgba(0, 0, 0, 0.3);
        padding-left  : 7px;
        font-size     : 14px;
      }

      .I-drop-down {
        width : 100%;

        >div {
          width : 100%;

          >div {
            width  : 100%;
            height : 35px;

            >div {
              color : rgba(0, 0, 0, .3);

              >p {
                color     : black;
                font-size : 14px;
              }

            }
          }
        }
      }
    }

    .I-add-option-color-block {
      width           : 100%;
      height          : 35px;
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;
      align-items     : center;

      .I-add-option-one-color-item {
        width          : 100%;
        height         : 100%;
        position       : relative;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        border-radius  : 5px;
        padding-left   : 5px;
        border         : 1px solid rgba(0, 0, 0, .3);

        >.S-click-outside {
          width    : 100%;
          position : absolute;
          bottom   : 35px;
          left     : 0;

          >.chrome-picker {
            width         : 100% !important;
            border-radius : 5px !important;
            box-shadow    : 0 0 5px -2px !important;
          }

        }

        .I-add-option-one-color-block {
          width         : 100px;
          height        : 80%;
          border-radius : 5px;
          cursor        : pointer;
        }

      }

      p {
        margin-left : 15px;
      }

      .I-add-option-two-color-item {
        width          : 45%;
        height         : 100%;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        border-radius  : 5px;
        padding-left   : 5px;
        border         : 1px solid rgba(0, 0, 0, .3);

        .I-add-option-two-color-block {
          width         : 100px;
          height        : 80%;
          border-radius : 5px;
          cursor        : pointer;
        }

        >.S-click-outside {
          width    : 91%;
          position : absolute;
          bottom   : 145px;
          left     : 30px;

          >.chrome-picker {
            width         : 100% !important;
            border-radius : 5px !important;
            box-shadow    : 0 0 5px -2px !important;
          }

        }
      }
    }

  }

  .I-add-option-footer {
    display         : flex;
    flex-direction  : row;
    justify-content : flex-end;
    align-items     : center;
    padding         : 30px 30px 5px 30px;
    border-top      : 1px solid rgba(0, 0, 0, .3);

    >div {
      width  : 200px;
      height : 45px;

      button {
        height : 35px;
        width  : 100px;
      }

      .cancel {
        border           : none;
        background-color : white;
      }

      .save {
        width            : 100px;
        height           : 32px;
        border-radius    : 5px;
        align-items      : center;
        border           : none;
        background-color : $app-color;
        color            : white;
      }

      .disabled {
        background-color : rgba(0, 0, 0, .1);
        color            : rgba(0, 0, 0, .3);
        border           : none;
        cursor           : default;
      }
    }

  }

}