.I-categories-page {
  width   : 90%;
  margin  : 0 auto;
  padding : 40px 0;

  .I-partners-page-header {
    width       : 100%;
    margin      : 40px auto;
    position    : relative;

    a {
      width            : 160px;
      height           : 40px;
      border           : none;
      border-radius    : 5px;
      font-size        : 15px;
      background-color : $app-color;
      color            : white;
      text-decoration  : none;
      display          : flex;
      align-items      : center;
      justify-content  : center;
      cursor           : pointer;
    }

    
    p {
      position    : absolute;
      bottom      : -70%;
      font-weight : bold;
      left        : 0;
    }
  }

  .I-categories-page-header {
    width           : 100%;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    a {
      width            : 160px;
      height           : 40px;
      border           : none;
      border-radius    : 5px;
      font-size        : 15px;
      background-color : $app-color;
      color            : white;
      text-decoration  : none;
      display          : flex;
      align-items      : center;
      justify-content  : center;
      cursor           : pointer;
    }
  }
 

  .I-categories-page-drag-drop {
    width            : 100%;
    height           : 100%;
    background-color : white;
    box-shadow       : $main-box-shadow;
    border-radius    : 8px;
    margin-top       : 25px;

    .rst__tree {
      padding : 20px 0;
    }

    .I-drag-drop-list {
      width           : 700px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      .I-category-item-name {
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        img {
          width        : 20px;
          margin-right : 25px;
        }
      }

      i {
        padding     : 7px;
        cursor      : pointer;
        transition  : 0.3s;
        font-size   : 15px;
        margin-left : 20px;
        color       : black;

        &:hover {
          border-radius    : 50%;
          color            : #3b4042;
          background-color : #e8eaed;
        }
      }

      .I-add-category {
        width    : 20px;
        position : absolute;
        right    : -5%;
        cursor   : pointer;
      }
    }

    .I-sub-categories {
      width           : 400px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      div {
        &:first-child {
          overflow      : hidden;
          text-overflow : ellipsis;
        }
      }

      i {
        padding     : 7px;
        cursor      : pointer;
        transition  : 0.3s;
        font-size   : 15px;
        margin-left : 20px;
        color       : black;

        &:hover {
          border-radius    : 50%;
          color            : #3b4042;
          background-color : #e8eaed;
        }
      }

      .I-add-category {
        width    : 20px;
        position : absolute;
        right    : -10%;
        cursor   : pointer;
      }
    }

    .I-main-drag {
      .I-drag-drop {
        width            : 100% !important;
        height           : 70px;
        padding          : 20px;
        background-color : white;
        cursor           : move;
        resize           : none;
        border-top       : 1px solid #ccd1d4;
        display          : flex;
        justify-content  : space-between;
        align-items      : center;
        font-size        : 20px;
        position         : relative;

        &:hover {
          .I-drag-drop-edit {
            i {
              opacity : 1;
            }
          }
        }

        &:first-child {
          border-top : none;
        }

        .I-drag-drop-icons {
          display     : flex;
          align-items : center;

          img {
            width        : 30px;
            margin-right : 30px;
          }

          i {
            margin-right : 30px;
          }
        }

        .I-drag-drop-edit {
          i {
            cursor      : pointer;
            margin-left : 30px;
            opacity     : 0;
            transition  : 0.7s;

            &:hover {
              font-weight : bolder;
            }
          }
        }
      }

      .I-sub-categories {
        width           : 100% !important;
        height          : 70px;
        padding         : 20px 60px;
        cursor          : move;
        resize          : none;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        font-size       : 20px;
        position        : relative;
      }
    }
  }
}
