.I-promo-code-page {
  .I-products-page-header {
    width           : 90%;
    margin          : 40px auto;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    .I-products-page-left {
      width           : 220px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      position        : relative;

      p {
        position    : absolute;
        bottom      : -70%;
        font-weight : bold;
        left        : 0;
      }

      a {
        width            : 160px;
        height           : 40px;
        border           : none;
        border-radius    : 5px;
        font-size        : 15px;
        background-color : $app-color;
        color            : white;
        text-decoration  : none;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        cursor           : pointer;
      }

      .I-repeat-button {
        width            : 40px;
        height           : 40px;
        display          : flex;
        justify-content  : center;
        align-items      : center;
        background-color : white;
        border-radius    : 5px;
        cursor           : pointer;

        i {
          font-size  : 20px;
          transition : 1s;
        }
      }
    }
  }

  .I-data-table,
  .I-table-footer {
    width  : 90%;
    margin : 0 auto;
  }

  .I-order-filter {

    .I-filter-apply-buttons {
      width        : 350px;
      margin-right : 25px;
    }

    .I-order-input {
      width           : 100%;
      display         : flex;
      align-items     : center;
      justify-content : space-around;

      div,
      .I-drop-down {
        min-width : 30%;
      }
    }
  }
}
