.I-add-notification-page {
  width          : 90%;
  margin         : 50px auto;
  display        : flex;
  flex-direction : column;

  .I-notification-container {
    width    : 75vw;
    position : relative;
    padding  : 30px 0;

    .I-add-notification-header {
      width  : 100%;
      height : 70px;

      p {
        font-size     : 18px;
        margin-bottom : 10px;

        &:first-child {
          color : $main-gray;
        }

        &:last-child {
          font-weight : bold;
        }
      }
    }

    .I-add-notification-container {
      width            : 100%;
      height           : 100%;
      background-color : white;
      box-shadow       : $main-box-shadow;
      border-radius    : 10px;

      .I-select-language {
        width         : 90%;
        height        : 80px;
        margin        : 20px auto;
        border-bottom : 1px solid #cfd1d2;
        display       : flex;
        align-items   : flex-end;

        .I-active-border {
          border-bottom : 5px solid $app-color;
        }

        div {
          padding       : 5px 0;
          margin-right  : 20px;
          width         : 110px;
          display       : flex;
          align-items   : center;
          cursor        : pointer;
          border-bottom : 5px solid transparent;

          img {
            width        : 18px;
            margin-right : 10px;
          }
        }
      }

      .I-add-title-description {
        width  : 90%;
        margin : 50px auto;

        > div {
          margin-bottom : 20px;

          > p {
            margin-bottom : 10px;
          }
        }

        input,
        textarea {
          width         : 100%;
          height        : 40px;
          border-radius : 8px;
          border        : 1px solid #cfd1d2;
          text-indent   : 20px;
          font-size     : 18px;
        }

        textarea {
          width   : 100% !important;
          height  : 200px !important;
          padding : 10px;
          resize  : none;
        }
      }

      .I-add-notification-photo {
        width  : 90%;
        margin : 20px auto;

        label {
          width         : 341px;
          height        : 220px;
          padding       : 100px;
          border-radius : 8px;
          background    : #f0f3f4;
          margin-top    : 20px;
          display       : table;
          font-size     : 18px;
          color         : #a0aab0;
          cursor        : pointer;
          position      : relative;

          .I-uploaded-background {
            width           : 200px;
            position        : absolute;
            left            : 50%;
            top             : 50%;
            transform       : translate(-50%, -50%);
            display         : flex;
            align-items     : center;
            justify-content : space-between;
          }

          input[type="file"] {
            display : none;
          }

          .I-upload-image {
            width               : 341px;
            height              : 215px;
            position            : absolute;
            left                : 50%;
            top                 : 50%;
            transform           : translate(-50%, -50%);
            border-radius       : 8px;
            background-position : center;
            background-size     : contain;
            background-repeat   : no-repeat;

            &:hover {
              background-color : rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }

    .I-notification-step-2 {
      width  : 100%;
      height : 100%;

      .I-notification-filter {
        width            : 100%;
        height           : 250px;
        background-color : white;
        box-shadow       : $main-box-shadow;
        border-radius    : 10px;
        position         : relative;

        > button {
          position         : absolute;
          left             : 20%;
          bottom           : 25%;
          background-color : $app-color;
          color            : white;
          width            : 140px;
          height           : 40px;
          border           : 0;
          border-radius    : 8px;
        }

        > p {
          position    : absolute;
          right       : 8%;
          top         : 10%;
          font-size   : 18px;
          font-weight : bold;
        }

        .I-order-filter {
          height           : 100%;
          background-color : transparent;

          .I-order-input {
            margin-top : 55px;

            div {
              margin-right : 10px;
            }
          }
        }

        .I-notification-filter-footer {
          width            : 100%;
          height           : 50px;
          position         : absolute;
          bottom           : 0;
          background-color : white;
          border-radius    : 10px;
          display          : flex;
          justify-content  : flex-end;
          padding          : 0 100px;

          button {
            width         : 140px;
            height        : 40px;
            margin-right  : 20px;
            border        : 0;
            border-radius : 8px;

            &:first-child {
              background-color : white;
              color            : black;
            }

            &:nth-child(2) {
              background-color : $app-color;
              color            : white;
            }

            &:last-child {
              background-color : $app-color;
              color            : white;
            }
          }
        }
      }
      .I-notification-table {
        width         : 100%;
        border-radius : 10px;
        margin-top    : 25px;
      }
    }
  }

  .I-notification-page-footer {
    width            : 75vw;
    height           : 60px;
    background-color : white;
    box-shadow       : 0 0 25px -10px;
    border-radius    : 5px;
    bottom           : 0;
    z-index          : 1;
    position         : absolute;
    display          : flex;
    align-items      : center;

    div {
      width           : 96%;
      display         : flex;
      justify-content : flex-end;

      button {
        width         : 120px;
        height        : 40px;
        margin-left   : 20px;
        border-radius : 8px;
        border        : none;
      }

      .cancel {
        background-color : white;
        color            : black;
      }
      
      .I-add-users {
        width         : auto;
        height        : 40px;
        padding       : 5px;
        border-radius : 8px;
        border        : 2px solid $app-color;
        color         : black;
        background    : transparent;
      }

      .save {
        background-color : $app-color;
        color            : white;
      }

      .disabled {
        background-color : rgba(0, 0, 0, 0.1);
        color            : rgba(0, 0, 0, 0.3);
        border           : none;
        cursor           : default;
      }
    }
  }
}
