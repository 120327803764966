@media (max-width: 1880px) {
  .I-order-input {
    .I-search-input {
    }

    .I-search-quantity {
      width : 280px !important;
    }

    .I-drop-down {
      min-width : 280px !important;
    }
  }
}

@media (max-width: 1650px) {
  .I-product-two-content {
    input {
      min-width : 200px !important;
    }

    > .I-drop-down {
      min-width : 200px;
    }
  }
}

@media (max-width: 1500px) {
  .I-home-left-bar {
    width : 250px;

    .I-left-bar-content {
      width : 270px;
    }
  }

  .I-order-input {
    .I-search-input {
    }

    .I-drop-down {
      min-width : 260px !important;
    }
  }

  .I-user-orders-requests {
    .I-data-table {
      width : 85%;
    }
  }

  .I-user-choose-status {
    width : 85% !important;
  }
}

@media (max-width: 1400px) {
  .I-home-left-bar {
    width : 250px;
  }

  .I-order-input {
    .I-search-input {
    }

    .I-drop-down {
      min-width : 220px !important;
    }
  }

  .I-left-bar-content {
    a {
      span {
        font-size : 15px !important;
      }
    }
  }

  .I-promo-choose-type,
  .I-promo-choose-type-disable {
    font-size : 14px !important;
  }
  .I-order-details .I-order-details-right-side .I-order-details-right-content .I-right-side-description .I-order-details-block > .align-item-small {
    font-size: 14px;
  }

  // .I-product-two-content {

  //   input {
  //     width      : 280px !important;
  //     margin-top : 15px;
  //   }

  //   .I-drop-down {
  //     min-width  : 240px !important;
  //     margin-top : 15px !important;
  //   }
  // }
}

@media (max-width: 1300px) {

  .I-products-page {
    .I-data-table {
      max-width: 980px !important;
    }
  }

  .I-header-nav {
    h2 {
      font-size  : 14px;
      margin-top : 15px;
    }
  }

  .I-order-filter {
    padding : 20px 30px !important;
    input {
      font-size : 14px;

      &::placeholder {
        font-size : 14px;
      }
    }
  }

  .I-drop-down-title {
    p {
      font-size   : 14px;
      font-weight : 100;
    }
  }

  // USER BLOCK //

  .I-user-details {
    width  : 95%;
    margin : 10px auto !important;
  }

  .I-user-orders-requests {
    .I-data-table {
      width : 100%;

      > .I-data-table-header {
        li {
          font-size : 12px !important;
        }
      }
    }

    .I-table-footer {
      width : 100% !important;
    }
  }

  .I-user-choose-status {
    width  : 100% !important;
    height : 50px !important;

    .I-choose-list {
      font-size : 12px !important;
      height    : 20px !important;
    }
  }

  .I-choose-status {
    font-size : 12px;
  }

  .I-user-details-counts {
    span {
      font-size : 10px !important;
    }
  }

  .I-user-page-right-side {
    width : 73% !important;
  }

  .I-user-orders-requests {
    margin-top : 10px !important;
  }

  .I-block-user {
    > div {
      font-size : 12px;
    }
  }

  .I-order-details-name {
    height : 100% !important;
    .I-details-name {
      font-size : 12px !important;
    }
    i,
    span {
      font-size : 12px !important;
    }
  }

  .I-data-table {
    > .I-data-table-header {
      li {
        font-size : 12px !important;
      }
    }
    > .I-data-table-body ul {
      height : 55px !important;
      > li {
        font-size : 14px !important;
      }
    }
  }

  .I-search-quantity {
    input {
      &::placeholder {
        font-size : 14px;
      }
    }
  }

  .I-filter-apply-buttons {
    input {
      &::placeholder {
        font-size : 14px !important;
      }
    }
  }

  // USER BLOCK END //

  // ORDER BLOCK //

  .I-order-details {
    width  : 95%;
    margin : 10px auto !important;

    .I-order-details-right-side {
      width : 73%;
    }

    .I-details-addresses {
      span,
      i,
      h3 {
        font-size : 12px;
      }
    }
  }

  .I-order-right-side-headings,
  .I-order-details-responses {
    div {
      font-size : 12px;
    }

    .I-drop-down {
      min-width : 100px !important;
    }
  }

  .I-order-details-responses {
    width : 92% !important;
  }

  .I-right-side-headings {
    div {
      font-size   : 12px;
      white-space : nowrap;
    }
  }

  .I-ordered-item-description {
    font-size : 12px;
  }

  .I-order-details-prices {
    margin-top : 25px;

    div,
    span {
      font-size : 12px;
    }
  }

  // ORDER BLOCK END //

  // REQUEST BLOCK //

  .I-order-filter {
    .I-drop-down {
      min-width : 180px !important;
    }
  }

  .I-requests-page {
    padding : 0 40px;
  }

  .I-search-quantity {
    font-size: 15px !important;
    input {
      &::placeholder {
        font-size : 14px !important;
      }
    }
  }

  .I-search-date-picker {
    input {
      font-size: 15px !important;
      &::placeholder {
        font-size : 14px !important;
      }
    }
  }

  .react-datepicker__input-container {
    input {
      font-size: 15px !important;
    }
  }

  .I-request-details-page {
    width : 95%;

    .I-request-details-right-side {
      width : 72%;
    }
  }

  .I-details-left-side {
    height : 200px !important;

    p,
    span {
      font-size : 12px !important;
    }
  }

  .I-request-details-right-content {
    p,
    span,
    i,
    div {
      font-size : 12px;
    }

    .I-drop-down {
      min-width : 150px;
    }
  }

  .I-request-only-file {
    p,
    div,
    i {
      font-size : 12px;
    }

    .I-drop-down {
      min-width : 150px;
    }
  }

  .I-products-page-left {
    p {
      white-space: nowrap;
    }
  }

  .I-brands-toolbar {
    p {
      white-space: nowrap;
    }
  }

  .I-brands-page {
    input {
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .I-category-item-name {
    font-size: 12px;
  }

  .I-promo-choose-type-disable {
    
    > div {
      width : 100px;
      padding: 5px;
    }
  }
}

@media (max-width: 1280px) {
  // .I-table-footer {
  //   display         : flex;
  //   align-items     : center;
  //   justify-content: flex-start;
  //  }
}
