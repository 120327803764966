.I-promo-code-add {
  width           : 100%;
  padding         : 80px 0;
  display         : flex;
  justify-content : center;
  align-items     : center;

  .I-promo-add-content {
    width            : 50vw;
    min-height       : 75vh;
    padding          : 40px 30px;
    background-color : white;
    box-shadow       : $main-box-shadow;
    border-radius    : 5px;

    > h3 {
      padding-bottom: 15px;
    }

    p {
      margin-top : 25px;
    }

    .I-promo-choose-type-disable {
      cursor           : not-allowed !important;
      background-color : rgba(0, 0, 0, 0.1);

      div,
      .I-method,
      .I-change-method {
        cursor : not-allowed !important;
      }
    }

    .I-promo-choose-type,
    .I-promo-choose-type-disable {
      width           : 80%;
      margin-top      : 15px;
      height          : 50px;
      display         : flex;
      align-items     : center;
      justify-content : space-around;
      border          : 1px solid #b6bcc1;
      border-radius   : 5px;
      font-size       : 14px;

      .I-method {
        width         : 170px;
        padding       : 8px;
        text-align    : center;
        border-radius : 8px;
        color         : black;
        cursor        : pointer;
      }

      .I-change-method {
        width            : 170px;
        padding          : 8px;
        text-align       : center;
        border-radius    : 8px;
        color            : white;
        cursor           : pointer;
        background-color : $app-color;
      }
    }

    .I-promo-coupon-amount {
      width           : 80%;
      margin-top      : 15px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      input {
        width         : 100%;
        height        : 35px;
        border-radius : 5px;
        border        : 1px solid $filter-input;
        font-size     : 14px;
        padding-left  : 7px;
      }

      .I-coupon-amount-checkbox {
        width           : 55%;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        margin-left     : 25px;

        div {
          width           : 25px;
          height          : 25px;
          border          : 1px solid #b6bcc1;
          border-radius   : 3px;
          cursor          : pointer;
          display         : flex;
          align-items     : center;
          justify-content : center;

          img {
            width : 14px;
          }
        }

        span {
          margin-left : 25px;
          width       : 300px;
        }
      }
    }

    .I-promo-title-input {
      width         : 100%;
      height        : 35px;
      margin-top    : 15px;
      border-radius : 5px;
      border        : 1px solid $filter-input;
      font-size     : 14px;
      padding-left  : 7px;
    }

    .I-promo-generate-code-disable {
      cursor           : not-allowed !important;
      background-color : rgba(0, 0, 0, 0.1) !important;
    }

    .I-promo-generate-code,
    .I-promo-generate-code-disable {
      width          : 100%;
      height         : 35px;
      border-radius  : 5px;
      border         : 1px solid $filter-input;
      font-size      : 14px;
      padding-left   : 7px;
      margin-top     : 15px;
      text-transform : uppercase;
    }

    .I-promo-auto-generation {
      width           : 22%;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      margin-top      : 5px;
      white-space     : nowrap;

      .I-repeat {
        width            : 20px;
        height           : 20px;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        background-color : white;
        border-radius    : 5px;
        border           : 1px solid #b6bcc1;
        cursor           : pointer;
        margin-right     : 20px;

        i {
          font-size : 14px;
        }
      }

      span {
        text-decoration : underline;
        cursor          : pointer;
      }
    }

    .I-promo-page-down-content {
      height : 400px;

      .I-promo-start-date {
        width           : 100%;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        p {
          width      : 45%;
          margin-top : 40px;
        }
      }

      .I-promo-page-date {
        width           : 100%;
        margin-top      : 10px;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        .I-datepicker-start {
          width           : 45%;
          height          : 35px;
          padding-left    : 7px;
          border          : 1px solid #b6bcc1;
          border-radius   : 5px;
          display         : flex;
          align-items     : center;
          justify-content : space-between;

          input {
            width  : 200px;
            border : 0;
          }

          i {
            margin-right: 20px;
          }
        }
      }

      .I-promo-text {
        width           : 100%;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        p {
          width : 45%;
        }
      }

      .I-promo-page-min-max {
        margin-top      : 10px;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        input {
          width         : 45%;
          height        : 35px;
          margin-bottom : 20px;
          border-radius : 5px;
          border        : 1px solid $filter-input;
          font-size     : 14px;
          padding-left  : 7px;
        }
      }
    }
  }

  .I-promo-page-footer {
    width            : 50vw;
    height           : 60px;
    box-shadow       : $main-box-shadow;
    background-color : white;
    position         : absolute;
    bottom           : 0;
    border-radius    : 5px 5px 0 0;
    display          : flex;
    justify-content  : flex-end;
    align-items      : center;

    .save {
      background-color : $app-color;
      color            : white;
    }

    .disabled {
      background-color : rgba(0, 0, 0, 0.1);
      color            : rgba(0, 0, 0, 0.3);
      border           : none;
      cursor           : default;
    }

    button {
      width         : 120px;
      margin-right  : 25px;
      height        : 40px;
      border        : none;
      border-radius : 10px;

      &:first-child {
        color            : black;
        background-color : white;
      }
    }
  }
}
