.I-settings-page {
  width  : 90%;
  margin : 45px auto;

  .I-settings-page-content {
    width      : 70%;
    min-height : 75vh;
    margin     : 0 auto;

    >div {
      width            : 100%;
      height           : 100px;
      background-color : white;
      margin-top       : 50px;
      box-shadow       : $main-box-shadow;
      border-radius    : 8px;
      font-size        : 20px;
      font-weight      : bolder;
    }

    .I-change-language {
      width           : 100%;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      padding         : 0 40px;

      .I-settings-choose-language,
      .I-settings-change-password {
        width           : 50%;
        height          : 50px;
        display         : flex;
        align-items     : center;
        justify-content : space-around;
        border          : 1px solid $main-gray;
        border-radius   : 8px;
        font-size       : 15px;
        font-weight     : normal;

        div {
          cursor : pointer;
        }

        .I-method {
          width         : 130px;
          text-align    : center;
          padding       : 8px;
          border-radius : 8px;
          color         : black;
          cursor        : pointer;
        }

        .I-change-method {
          width            : 140px;
          text-align       : center;
          border-radius    : 8px;
          color            : white;
          cursor           : pointer;
          background-color : $app-color;
        }
      }

      .I-settings-change-password {
        border          : none;
        display         : flex;
        justify-content : flex-end;

        button {
          width            : 120px;
          height           : 40px;
          background-color : white;
          border           : 1px solid $main-gray;
          border-radius    : 5px;
        }
      }
    }

    .I-settings-mu-table {
      width  : 100%;
      height : 100%;

      .I-mu-header {
        width           : 100%;
        padding         : 0 40px;
        margin          : 0 auto;
        display         : flex;
        align-items     : center;
        justify-content : space-between;
        
        div {
          margin-top: 25px;
        }
      }
 
      .I-data-table {
        box-shadow : none;
        margin-top : 40px;
      }

        button {
          width            : 120px;
          height           : 40px;
          background-color : white;
          border           : 1px solid $main-gray;
          border-radius    : 5px;
          color            : black;
        }
      }
    }
}