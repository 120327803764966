@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9ty0pt');
  src:  url('../fonts/icomoon.eot?9ty0pt#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9ty0pt') format('truetype'),
    url('../fonts/icomoon.woff?9ty0pt') format('woff'),
    url('../fonts/icomoon.svg?9ty0pt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Asset-178:before {
  content: "\e903";
}
.icon-Asset-216:before {
  content: "\e904";
}
.icon-Asset-219:before {
  content: "\e905";
}
.icon-attach:before {
  content: "\e906";
}
.icon-attributes:before {
  content: "\e907";
}
.icon-attributes_01:before {
  content: "\e908";
}
.icon-back:before {
  content: "\e909";
}
.icon-brand_01:before {
  content: "\e90a";
}
.icon-camera:before {
  content: "\e90b";
}
.icon-camera_01:before {
  content: "\e90c";
}
.icon-cart:before {
  content: "\e90d";
}
.icon-cart_01:before {
  content: "\e90e";
}
.icon-category:before {
  content: "\e90f";
}
.icon-category_01:before {
  content: "\e910";
}
.icon-chat:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e912";
}
.icon-close:before {
  content: "\e913";
}
.icon-company:before {
  content: "\e914";
}
.icon-company_01:before {
  content: "\e915";
}
.icon-dashboard:before {
  content: "\e916";
}
.icon-dashboard_01:before {
  content: "\e917";
}
.icon-delete:before {
  content: "\e918";
}
.icon-delete_01:before {
  content: "\e919";
}
.icon-department_01:before {
  content: "\e91a";
}
.icon-document:before {
  content: "\e91b";
}
.icon-down:before {
  content: "\e91c";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-edit_01:before {
  content: "\e91e";
}
.icon-eye:before {
  content: "\e91f";
}
.icon-eye_01:before {
  content: "\e920";
}
.icon-fb:before {
  content: "\e921";
}
.icon-google:before {
  content: "\e922";
}
.icon-help:before {
  content: "\e923";
}
.icon-home:before {
  content: "\e924";
}
.icon-home_01:before {
  content: "\e925";
}
.icon-homedepartment:before {
  content: "\e926";
}
.icon-linkdine:before {
  content: "\e927";
}
.icon-location:before {
  content: "\e928";
}
.icon-logout:before {
  content: "\e929";
}
.icon-minus:before {
  content: "\e92a";
}
.icon-more:before {
  content: "\e92b";
}
.icon-mu:before {
  content: "\e92c";
}
.icon-mu_01:before {
  content: "\e92d";
}
.icon-notification:before {
  content: "\e92e";
}
.icon-options:before {
  content: "\e92f";
}
.icon-options_01:before {
  content: "\e930";
}
.icon-orders:before {
  content: "\e931";
}
.icon-orders_01:before {
  content: "\e932";
}
.icon-photo:before {
  content: "\e933";
}
.icon-photo_01:before {
  content: "\e934";
}
.icon-photo_01_1:before {
  content: "\e935";
}
.icon-plus:before {
  content: "\e936";
}
.icon-plus_01:before {
  content: "\e937";
}
.icon-products:before {
  content: "\e938";
}
.icon-products_1:before {
  content: "\e939";
}
.icon-promo_code:before {
  content: "\e93a";
}
.icon-promo_code_01:before {
  content: "\e93b";
}
.icon-Rectangle-263:before {
  content: "\e93c";
}
.icon-requests_01:before {
  content: "\e93d";
}
.icon-right:before {
  content: "\e93e";
}
.icon-search:before {
  content: "\e93f";
}
.icon-sent:before {
  content: "\e940";
}
.icon-settings:before {
  content: "\e941";
}
.icon-settings_01:before {
  content: "\e942";
}
.icon-share:before {
  content: "\e943";
}
.icon-top:before {
  content: "\e944";
}
.icon-update:before {
  content: "\e945";
}
.icon-upload:before {
  content: "\e946";
}
.icon-user:before {
  content: "\e947";
}
.icon-user_01:before {
  content: "\e948";
}
.icon-voice:before {
  content: "\e949";
}
.icon-voice_01:before {
  content: "\e94a";
}
.icon-wishlist:before {
  content: "\e94b";
}
.icon-wishlist_01-copy:before {
  content: "\e94c";
}
.icon-wishlist_01:before {
  content: "\e94d";
}
.icon-credit_card_02:before {
  content: "\e94e";
}
.icon-dollar:before {
  content: "\e94f";
}
.icon-credit_card:before {
  content: "\e950";
}
.icon-call-answer:before {
  content: "\e900";
}
.icon-envelope:before {
  content: "\e901";
}
.icon-case:before {
  content: "\e902";
}
.icon-requests:before {
  content: "\e951";
}