.I-brands-page {

  .I-brands-filter {
    width            : 100%;
    height           : 150px;
    margin           : 0 auto;
    background-color : white;
    box-shadow       : 0 0 16px -10px;
    padding          : 20px 80px;

    .I-brands-input {
      display     : flex;
      align-items : baseline;

      .I-search-quantity {
        margin-left : 25px;
      }
    }
  }

  .I-data-table,
  .I-table-footer {
    width  : 90%;
    margin : 0 auto;
  }
}

.I-brands-table-header {
  width           : 90%;
  margin          : 40px auto;
  display         : flex;
  align-items     : center;
  justify-content : space-between;

  .I-brands-toolbar {
    width           : 220px;
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    position        : relative;

    p {
      position    : absolute;
      bottom      : -70%;
      font-weight : bold;
      left        : 0;
    }

    button {
      width : 160px;
    }
    
    div {
      cursor : pointer;
    }
  }
}