.I-filter {
  width            : 100%;
  height           : 80px;
  background-color : white;

  .I-filter-first-block {
    width           : 90%;
    padding         : 20px 0;
    margin          : 0 auto;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    .I-filter-search {
      width           : 20%;
      height          : 40px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      border-radius   : 4px;
      border          : 1px solid $filter-input;

      input {
        border    : none;
        padding   : 5px 10px;
        font-size : 18px;

        &::placeholder {
          font-size : 18px;
        }
      }

      i {
        margin-right : 10px;
        font-size    : 18px;
      }
    }

    .I-filter-dropdown {
      width           : 20%;
      height          : 40px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      border-radius   : 4px;
      border          : 1px solid $filter-input;
    }
  }

  .I-toolbar {
    width            : 100%;
    margin           : 0 auto;
    display          : flex;
    align-items      : center;
    justify-content  : space-between;
    height           : 50px;
    padding          : 0 80px;
    background-color : white;

    .I-show-more {
      cursor : pointer;

      div {
        width           : 120px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
      }
    }

    .I-filter-buttons {
      display     : flex;
      align-items : center;

      button {
        padding       : 10px;
        border-radius : 10px;
        border        : none;

        &:first-child {
          margin-right     : 20px;
          background-color : white;
        }

        &:last-child {
          width            : 120px;
          color            : white;
          background-color : $app-color;
        }
      }
    }
  }

  .I-open-filter {
    width            : 100%;
    height           : 50px;
    background-color : white;
    display          : flex;
    align-items      : center;
    justify-content  : space-between;

    .I-open-filter-content {
      width           : 90%;
      margin          : 0 auto;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
    }
  }

  .I-close-filter {
    display : none;
  }
}