* {
  margin      : 0;
  padding     : 0;
  box-sizing  : border-box;
  outline     : 0;
  font-family : "Regular", "Armenian";
}

@font-face {
  font-family : "Regular";
  src         : url("../fonts/FedraSansPro\ Book.otf") format("truetype");
  font-weight : normal;
  font-style  : normal;
}

@font-face {
  font-family : "Armenian";
  src         : url("../fonts/Armenian.TTF") format("truetype");
  font-weight : normal;
  font-style  : normal;
}

button {
  cursor : pointer;
}

.I-error {
  box-shadow : 0 0 20px rgba(158, 0, 0, 0.8);
}

a {
  text-decoration: none;
}

.I-main-flex {
  width    : 100%;
  display  : flex;
  overflow : hidden;

  .I-left {
    width      : 20%;
    height     : 100vh;
    z-index    : 100;
    transition : 0.5s;
  }

  .I-head {
    width  : 100%;
    height : 100vh;

    .I-header {
      width   : 100%;
      height  : 100px;
      z-index : 100;
    }

    .I-content {
      width            : 100%;
      height           : calc(100vh - 100px);
      overflow-y       : auto;
      background-color : #f0f3f4;
    }
  }
}

::-webkit-scrollbar {
  width  : 5px;
  height : 5px;
}

::-webkit-scrollbar-track {
  background : #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background : #888;
}

::-webkit-scrollbar-thumb:hover {
  background : $app-color;
}

audio,
.I-full-width {
  width : 100%;
}

video {
  background-color : black;
}

.I-transparent {
  color      : black !important;
  background : transparent !important;
}

.rdw-editor-wrapper {
  margin : 20px 0;

  .rdw-editor-main {
    border     : 1px solid #eee;
    padding    : 10px;
    max-height : 200px;
  }
}

.rst__collapseButton,
.rst__expandButton {
  z-index : 10;

  &:focus {
    box-shadow : 0 0 0 1px #000;
  }
}

.react-datepicker__close-icon::after {
  background-color : transparent;
  color            : black;
  font-size        : 25px;
  right            : 4px;
  top              : 4px;
}

.I-close {
  height  : 100vh;
  z-index : 100;
}

.I-search-input {
  width         : 24%;
  border        : none;
  border-bottom : 1px solid $filter-input;
  display       : flex;
  align-items   : center;
  overflow      : hidden;
  color         : $filter-input;

  input {
    width       : 85%;
    height      : 42px;
    border      : none;
    font-size   : 18px;
    text-indent : 10px;

    &::placeholder {
      font-size : 16px;
      color     : $main-gray;
    }
  }

  i {
    font-size   : 18px;
    margin-left : 10px;
    cursor      : pointer;
  }
}

.I-search-quantity {
  width         : 24%;
  color         : $filter-input;
  border-bottom : 1px solid $filter-input;

  input {
    width       : 49%;
    height      : 44px;
    border      : none;
    font-size   : 18px;
    text-indent : 10px;

    &::placeholder {
      font-size : 16px;
      color     : $main-gray;
    }
  }
}

.I-filter-apply-buttons {
  margin-top      : 25px;
  display         : flex;
  align-items     : center;
  justify-content : space-around;
  float           : right;

  button {
    margin-left   : 10px;
    padding       : 10px 25px;
    border-radius : 8px;
    border        : none;

    &:first-child {
      background-color : white;
    }

    &:last-child {
      background-color : $app-color;
      color            : white;
    }
  }
}

.I-repeat-button {
  width            : 40px;
  height           : 40px;
  display          : flex;
  justify-content  : center;
  align-items      : center;
  background-color : white;
  border           : 1px solid #cbd1d4;
  border-radius    : 5px;

  i {
    font-size  : 20px;
    cursor     : pointer;
    transition : 1s;
  }
}

.I-add-new-button {
  width            : 120px;
  height           : 40px;
  border           : none;
  border-radius    : 5px;
  font-size        : 15px;
  background-color : $app-color;
  color            : white;
}

.I-toolbar-buttons {
  width            : 120px;
  height           : 40px;
  border-radius    : 5px;
  background-color : white;
  border           : 1px solid #cbd1d4;
  margin-left      : 20px;
}

.I-table-img {
  width           : 60px;
  height          : 50px;
  border          : 1px solid $filter-input;
  border-radius   : 4px;
  display         : flex;
  align-items     : center;
  justify-content : center;

  div {
    width               : 55px;
    height              : 40px;
    background-size     : 150%;
    background-position : center;
    background-repeat   : no-repeat;
    border-radius       : 3px;
  }
}

.I-footer-buttons {
  width           : 100%;
  height          : 50px;
  position        : absolute;
  bottom          : 0;
  display         : flex;
  justify-content : flex-end;

  button {
    width         : 120px;
    margin-left   : 20px;
    height        : 40px;
    border        : none;
    border-radius : 10px;

    &:first-child {
      color            : black;
      background-color : white;
    }

    &:last-child {
      color            : white;
      background-color : $app-color;
    }
  }
}

.I-wrong {
  color     : red;
  font-size : 14px !important;
  float     : right;
}

.I-wrong-field {
  border : 1px solid red !important;
}

.I-show-more-div {
  width           : 120px;
  height          : 50px;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  cursor          : pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance : none;
  margin             : 0;
}

// input-type-radio-start

.container {
  position            : relative;
  padding-left        : 35px;
  margin-bottom       : 12px;
  cursor              : pointer;
  font-size           : 22px;
  -webkit-user-select : none;
  -moz-user-select    : none;
  -ms-user-select     : none;
  user-select         : none;

  input {
    position : absolute;
    opacity  : 0;
    cursor   : pointer;
  }

  .checkmark {
    position         : absolute;
    top              : 0;
    left             : 0;
    height           : 25px;
    width            : 25px;
    background-color : #eee;
    border-radius    : 50%;
  }
}

.container:hover input ~ .checkmark {
  background-color : #ccc;
}

.container input:checked ~ .checkmark {
  background-color : $app-color;
}

.checkmark:after {
  content  : "";
  position : absolute;
  display  : none;
}

.container input:checked ~ .checkmark:after {
  display : block;
}

.container .checkmark:after {
  top           : 9px;
  left          : 9px;
  width         : 8px;
  height        : 8px;
  border-radius : 50%;
  background    : white;
}

// input-type-radio-end

.I-no-result {
  font-size   : 20px;
  font-weight : bolder;
  position    : absolute;
  left        : 50%;
  top         : 50%;
}

.notification-container {
  width   : 350px !important;
  padding : 20px 0 !important;
}

.notification-error {
  background-color : white !important;
  border-left      : 7px solid #ec5963;
  border-radius    : 6px !important;

  &::before {
    color   : white !important;
    z-index : -100 !important;
  }
}

.notification-success {
  background-color : white !important;
  border-left      : 7px solid $app-color;
  border-radius    : 6px !important;

  &::before {
    color   : white !important;
    z-index : -100 !important;
  }
}

.message {
  color       : black;
  font-weight : bold;
}

.notification {
  padding : 10px 15px !important;
  z-index : 8888888888 !important;
  opacity : 1 !important;
}

.I-radio {
  width         : 20px;
  height        : 20px;
  border-radius : 50%;
}

.disabled {
  background-color : rgba(0, 0, 0, 0.1);
  color            : rgba(0, 0, 0, 0.3);
  border           : none;
  cursor           : default;
}

.chartjs-render-monitor {
  width  : 880px !important;
  height : 280px !important;
}

.css-15k3avv {
  z-index : 500000;
}
