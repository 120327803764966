.I-dashboard-page {
  width  : 90%;
  height : 100px;
  margin : 40px auto;

  .I-dashboard-page-header {
    width           : 100%;
    height          : 50px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;

    .I-drop-down {
      min-width        : 280px;
      background-color : transparent;

      .Open {
        height : 150px !important;
      }

      .I-drop-down-title {
        background-color: transparent;

        p {
          color : black;
        }
      }
    }

    .I-toolbar-buttons {
      width : 150px;
    }

    > div {
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      .I-search-date-picker {
        width           : 280px;
        border          : none;
        border-bottom   : 1px solid $filter-input;
        border-radius   : 3px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        margin-right    : 40px;

        .react-datepicker-popper {
          z-index : 1000000;
        }

        hr {
          height : 44px;
          border : 0.5px solid $filter-input;
        }

        input {
          width       : 100%;
          border      : none;
          font-size   : 18px;
          text-indent : 10px;
          z-index     : 155000;
          background-color: transparent;

          &::placeholder {
            font-size : 16px;
            color     : black;
          }
        }
      }
    }
  }

  .I-dashboard-items {
    width           : 100%;
    margin-top      : 25px;
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    padding         : 20px 0;

    > div {
      width            : 15%;
      height           : 180px;
      background-color : white;
      box-shadow       : $main-box-shadow;
      border-radius    : 8px;
      display          : flex;
      justify-content  : center;
      flex-direction   : column;

      p {
        margin      : 5px auto;
        font-size   : 26px;
        font-weight : bolder;

        &:last-child {
          font-size   : 17px;
          font-weight : normal;
          text-align  : center;
          color       : #ababac;
        }
      }

      .gray-shadow {
        background-color : #dde0df;
      }

      .green-shadow {
        background-color : #d4ece5;
      }

      .red-shadow {
        background-color : #fad9dc;
      }

      .blue-shadow {
        background-color : #ccd2e6;
      }

      .orange-shadow {
        background-color : #f7dad1;
      }

      .purple-shadow {
        background-color : #dfcee8;
      }

      div {
        width           : 45px;
        height          : 45px;
        border-radius   : 50%;
        margin          : 0 auto;
        display         : flex;
        justify-content : center;
        align-items     : center;
        margin-bottom   : 20px;

        i {
          font-size : 20px;
        }

        .gray {
          color : #57635f;
        }

        .green {
          color : $app-color;
        }

        .red {
          color : #e34151;
        }

        .blue {
          color : #032080;
        }

        .orange {
          color : #d8491c;
        }

        .purple {
          color : #620a8e;
        }
      }
    }
  }

  .I-dashboard-second-table {
    width           : 100%;
    display         : flex;
    flex-wrap       : wrap;
    align-items     : center;
    justify-content : space-between;
    padding         : 20px 0;

    > div {
      width            : 49%;
      height           : 400px;
      background-color : white;
      box-shadow       : $main-box-shadow;
      border-radius    : 8px;
      display          : flex;
      align-items      : center;
      justify-content  : center;
      position         : relative;

      svg {
        width: 300px;
        margin-top: 25px;
        transform: scale(1.7);

        .donutchart-legend-item {
          font-size: 20px !important;
          margin-top: 10px !important;
        }

        .donutchart-innertext-label,.donutchart-innertext-value {
          font-size: 20px !important;
        }
      }

      h3 {
        position : absolute;
        left     : 5%;
        top      : 5%;
      }

      &:nth-child(3),
      &:nth-child(4) {
        margin-top : 40px;
      }
    }
  }
}
