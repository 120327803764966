.I-promotions-add-page {
  width           : 100%;
  padding         : 80px 0;
  display         : flex;
  justify-content : center;
  align-items     : center;

  .I-promotion-add-content {
    width            : 50vw;
    min-height       : 75vh;
    padding          : 40px 30px;
    background-color : white;
    box-shadow       : $main-box-shadow;
    border-radius    : 5px;

    div {
      margin-top : 10px;
    }

    .I-promotion-languages {
      width       : 100%;
      margin      : 0 auto;
      padding-top : 40px;

      input {
        width         : 100%;
        height        : 35px;
        margin-bottom : 20px;
        border-radius : 5px;
        border        : 1px solid $filter-input;
        font-size     : 14px;
        padding-left  : 7px;
      }

      div {
        margin-bottom : 10px;
        font-size     : 16px;

        img {
          width        : 16px;
          margin-right : 16px;
        }
      }

      label {
        padding       : 100px 70px;
        border-radius : 8px;
        background    : #F0F3F4;
        margin-top    : 20px;
        display       : table;
        font-size     : 18px;
        color         : #A0AAB0;
        cursor        : pointer;
        position      : relative;
  
        input[type="file"] {
          display : none;
        }  
  
        .I-upload-image {
          width               : 341px;
          height              : 223px;
          position            : absolute;
          border              : 0;
          left                : 0;
          top                 : -5%;
          border-radius       : 8px;
          background-position : center;
          background-size     : 100%;
          background-repeat   : no-repeat;
        }
      }

      .I-promotions-link-type {
        width           : 341px;
        height          : 50px;
        border          : 1px solid black;
        border-radius   : 8px;
        display         : flex;
        justify-content : space-around;
        align-items     : center;
        margin-top      : 18px;
        
        .I-method {
          width         : 130px;
          text-align    : center;
          padding       : 8px;
          border-radius : 8px;
          color         : black;
          cursor        : pointer;
        }

        .I-change-method {
          width            : 140px;
          text-align       : center;
          padding          : 8px;
          border-radius    : 8px;
          color            : white;
          cursor           : pointer;
          background-color : $app-color;
        }
      }

      .I-right-side-attach-product {
        width  : 100%;
        margin : 25px auto;
        height : 150px;

        p {
          color         : $main-gray;
          margin-bottom : 15px;
        }
  
        div {
          width            : 100%;
          height           : 100px;
          margin-top       : 20px;
          display          : flex;
          flex-direction   : column;
          justify-content  : center;
          align-items      : center;
          border-radius    : 8px;
          background-color : #F0F3F4;
          cursor           : pointer;
  
          i {
            font-size : 30px;
            color     : #939EA6;
          }
  
          span {
            font-size : 18px;
            color     : #939EA6;
          }
        }
      }

      .I-search-category {
        padding       : 15px 0;
        margin-bottom : 85px;

        p {
          margin-bottom : 15px;
        }

        input {
          margin-bottom : 0;
        }

        .I-search-category-items {
          width            : 100%;
          height           : auto;
          overflow-y       : auto;
          background-color : white;
          box-shadow       : $main-box-shadow;
          border-radius    : 8px;
  
          .I-products-searches {
            padding : 0 20px;

            div {
              cursor : pointer;
            }
          }
        }
      }

      .I-promotion-product {
        width           : 100%;
        height          : 120px;
        border-top      : 1px solid $main-gray;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        p {
          width: 100%;
        }

        .I-attach-img {
          width           : 50px;
          height          : 50px;
          border          : 1px solid #E2E2E2;
          border-radius   : 10px;
          display         : flex;
          align-items     : center;
          justify-content : center;
          margin-right    : 25px;

          img {
            width         : 43px;
            height        : 43px;
            border-radius : 10px;
            margin        : 0 auto;
          }
        }
      }
    }
  }
 
  .I-footer-buttons {
    width            : 50vw;
    height           : 60px;
    box-shadow       : $main-box-shadow;
    background-color : white;
    position         : absolute;
    bottom           : 0;
    display          : flex;
    align-items      : center;
    border-radius    : 8px 8px 0 0;
  
    button {
      margin-right : 25px;
    }
  
    .save {
      background-color : $app-color;
      color            : white;
    }
    
    .disabled {
      background-color : rgba(0, 0, 0, .1);
      color            : rgba(0, 0, 0, .3);
      border           : none;
      cursor           : default;
    }
  }
  
}



