.I-admin-login-page {
  width    : 100%;
  height   : 100vh;
  display  : flex;
  overflow : hidden;

  .I-right-side {
    width            : 100%;
    background-color : white;
    display          : flex;
    justify-content  : center;
    align-items      : center;

    .I-right-side-content {
      width    : 20%;
      height   : 60%;
      position : relative;

      .I-right-side-logo {
        display         : flex;
        justify-content : center;

        img {
          width  : 160px;
        }
      }

      .I-right-side-validation {
        width    : 100%;
        height   : 200px;
        position : absolute;
        top      : 30%;

        .form {
          width  : 90%;
          margin : 0 auto;

          p {
            font-size : 18px;

            &:last-child {
              position : absolute;
              right    : 5%;
            }
          }

          input,
          button {
            width         : 100%;
            height        : 44px;
            padding       : 5px;
            margin        : 10px auto;
            border-radius : 8px;
            border        : 1px solid;
            font-size     : 18px;
          }

          .I-password-input {
            display     : flex;
            align-items : center;

            .I-password {
              width         : 90%;
              border-right  : 0;
              border-radius : 8px 0 0 8px;
            }

            div {
              width         : 10%;
              border        : 1px solid;
              height        : 44px;
              border-left   : 0;
              border-radius : 0 8px 8px 0;
              display       : flex;
              align-items   : center;

              img {
                width  : 25px;
                cursor : pointer;
              }
            }
          }

          button {
            background-color : $app-color;
            color            : white;
            font-size        : 15px;
          }
        }
      }
    }
  }
}