.I-promotion-page {
  
  .I-promotion-page-add {
    width       : 90%;
    margin      : 20px auto;
    padding-top : 20px;
    position    : relative;

    button {
      width : 160px;
    }

    .I-promotion-drop-down {
      width            : auto;
      height           : 100px;
      padding          : 0 5px;
      background-color : white;
      box-shadow       : 0 0 25px -10px;
      border-radius    : 10px;
      position         : absolute;
      left             : 0;
      top              : 110%;
      z-index          : 100;
      display          : flex;
      flex-direction   : column;

      a {
        text-decoration : none;
        color           : black;
        font-weight     : bold;
        margin-top      : 20px;
        margin-left     : 15px;
      }
    }
  }

  .I-promotion-home-category {
    width       : 90%;
    margin      : 5px auto;

    .I-home-category-header {
      width           : 100%;
      height          : 100px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      position        : relative;

      p {
        position    : absolute;
        bottom      : 10%;
        font-weight : bold;
        left        : 0;
      }
    }
  }
  
  .I-data-table,
  .I-table-footer {
    width : 100%;
  } 
}