.I-home-left-bar {
  width            : 320px;
  height           : 100vh;
  background-color : white;
  box-shadow       : 0 0 16px -10px;
  transition       : .1s;
  overflow-y       : auto;
  overflow-x       : hidden;
  
  i {
    color     : $app-color;
    font-size : 18px;
  }

  .I-left-bar-header {
    width           : 100%;
    height          : 100px;
    padding         : 20px 40px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    border-bottom   : 1px solid #DADEE0;

    .I-left-bar-logo {
      width       : 70px;
      margin-left : 10px;
    }

    .I-hamburger {
      cursor : pointer;

      &:hover {

        span {

          &:last-child {
            width : 30px;
          }

          &:first-child {
            width : 20px;
          }
        }
      }

      span {
        width         : 30px;
        border        : 1px solid;
        border-radius : 80px;
        margin-top    : 10px;
        display       : block;
        transition    : 0.5s;

        &:last-child {
          width : 20px;
        }
      }
    }
  }

  .I-left-bar-content {
    width          : 300px;
    padding        : 40px;
    display        : flex;
    flex-direction : column;

    a {
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      text-decoration : none;
      text-align      : left;
      margin-bottom   : 24px;

      > div {

        padding         : 7px;
        display         : flex;
        align-items     : center;
        justify-content : center;
        transform       : rotate(45deg);
        border-radius   : 5px;

        i {
          transform : rotate(-45deg);
        }
      }

      span {
        width     : 150px;
        color     : black;
        font-size : 17px;
      }
    }

    .activeClass {

      div {
        background-color : $app-color;

        i { color: white; }
      }    

      span {
        color : $app-color;
      }
    }
  }

}