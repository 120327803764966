.I-header {
  
  width         : 85%;
  height        : 100px;
  float         : right;
  border-bottom : 1px solid #DADEE0;

  .I-header-content {
    width           : 90%;
    height          : 100px;
    margin          : 0 auto;
    display         : flex;
    justify-content : space-between;
    align-items     : center;

    .I-header-toolbar {
      display     : flex;
      align-items : center;
      position    : relative;

      .I-header-icons {
        display     : flex;
        align-items : center;

        span {
          width                     : 10px;
          height                    : 10px;
          background-color          : rgb(99, 15, 15);
          border-radius             : 50%;
          position                  : absolute;
          left                      : 18%;
          bottom                    : 70%;
          animation-name            : alert;
          animation-duration        : 1s;
          animation-iteration-count : infinite;
        }

        @keyframes alert {

          0% {
            background-color : rgb(211, 8, 8);
          }

          50% {
            background-color : rgb(139, 0, 0);
          }
          
          100% {
            background-color : rgb(211, 8, 8);
          }
        }

        i {
          font-size   : 20px;
          margin-left : 35px;
          font-weight : bolder;
          cursor      : pointer;
        }

        a {
          text-decoration : none;
          color           : black;
        }
      }

      .I-header-user {
        display     : flex;
        align-items : center;

        img {
          margin-left : 35px;
          width       : 30px;
          cursor      : pointer;
        }

        i {
          margin-left : 5px;
          font-weight : bolder;
          cursor      : pointer;
        }
      }

      .I-dropdown {
        width            : 100%;
        height           : 100px;
        background-color : white;
        box-shadow       : 0 0 25px -10px;
        border-radius    : 10px;
        position         : absolute;
        right            : 0;
        top              : 160%;
        z-index          : 100;

        div {
          width           : 100%;
          display         : flex;
          align-items     : center;
          justify-content : space-between;
          margin-top      : 20px;
          color           : black;
          cursor          : pointer;

          i {
            font-size   : 18px;
            margin-left : 5px;
          }

          p, a {
            margin-left     : 15px;
            width           : 100%;
            text-decoration : none;
            color           : black;
          }
        }
      }

      .I-drop-down-hide {
        display : none;
      }
    }
  }

  .I-header-nav {
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;

    >div {
      display        : flex;
      flex-direction : row;
      align-items    : center;
      cursor         : pointer;
      color          : grey;
      font-size      : 14px;

      >i {
        font-size    : 16px;
        margin-right : 5px;
      }
    }
  }
}