.I-data-table {
  max-width     : 100%;
  color         : #000000;
  box-shadow    : $main-box-shadow;
  border-radius : 5px;
  overflow      : hidden;

  >.I-data-table-header {
    height           : 60px;
    padding          : 0 40px;
    z-index          : 2;
    display          : flex;
    position         : relative;
    font-size        : 14px;
    border-bottom    : 1px solid rgba(0, 0, 0, .1);
    align-items      : center;
    list-style-type  : none;
    font-weight      : 800;
    color            : rgb(46, 45, 45);
    background-color : white;

    >li {
      width         : 100%;
      font-size     : 16px;
      text-overflow : ellipsis;
      white-space   : nowrap;
      overflow      : hidden;
    }
  }


  >.I-data-table-body ul {

    height          : 60px;
    padding         : 0 40px;
    z-index         : 1;
    display         : flex;
    position        : relative;
    font-size       : 16px;
    background      : white;
    align-items     : center;
    list-style-type : none;

    

    &:hover {
      background-color : #F0F3F4;
      cursor           : pointer;
    }

    &:hover>.I-data-table-actions {
      display : initial;
    }

    >li {
      width         : 100%;
      height        : 100%;
      display       : block;
      position      : relative;
      overflow      : hidden;
      white-space   : nowrap;
      padding-right : 15px;
      text-overflow : ellipsis;

      >div {
        top           : 50%;
        width         : 100%;
        float         : left;
        overflow      : hidden;
        position      : relative;
        transform     : translateY(-50%);
        white-space   : nowrap;
        text-overflow : ellipsis;
        max-width: 30ch;


        >.I-data-table-image-row {

          width         : 40px;
          float         : left;
          height        : 40px;
          border-radius : 8px;
        }
      }

      &.I-data-table-actions {
        right    : 20px;
        width    : auto;
        display  : none;
        position : absolute;

        >div {
          padding-right : 0;
        }
      }
    }
  }
}

.I-table-footer {
  height          : 80px;
  display         : flex;
  align-items     : center;
  margin-top      : 20px;
  justify-content : flex-end;

  .I-table-page-panel {
    display : flex;

    .I-table-page-info {
      width          : 160px;
      margin-right   : 30px;
      display        : flex;
      flex-direction : row;
      align-items    : center;

      input {
        width         : 50px;
        height        : 32px;
        margin        : 0 10px;
        padding       : 10px;
        border-radius : 5px;
        border        : 1px solid rgba(0, 0, 0, .1);
      }

      .I-page-count {
        min-width : 40px;
        color     : rgb(158, 157, 157);
      }

    }

    .I-table-page-slide {
      display         : flex;
      width           : 80px;
      display         : flex;
      justify-content : space-between;

      div {
        height           : 100%;
        width            : 32px;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        background-color : #334856;
        border-radius    : 5px;
        cursor           : pointer;
        

        i {
          font-size : 20px;
          color     : white;
        }

        .I-table-not-allow {
          color  : grey;
          cursor : default;
        }
      }

      ;

      .I-table-not-allow-slide {
        cursor : default;
      }
    }
  }

  .I-table-show-more {
    width            : 150px;
    height           : 32px;
    display          : flex;
    justify-content  : center;
    align-items      : center;
    border-radius    : 5px;
    font-size        : 15px;
    background-color : #334856;
    color            : white;
    cursor           : pointer;
  }
}