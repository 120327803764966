.I-support-modal {
  width            : 500px;
  min-height       : 200px;
  max-height       : 700px;
  background-color : white;
  border-radius    : 8px;
  padding          : 20px;

  > div {
    height : 100%;

    p {
      margin-top : 15px;
  
      i {
        font-weight  : bold;
        color        : $app-color;
        margin-right : 15px;
      }
    }
  } 
}