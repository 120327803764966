.I-products-page {

  .I-drop-down  {
    margin-right: 20px;
  }

  .I-order-filter {
    .I-order-input {
      display        : flex;
      flex-direction : row;

      .I-drop-down {
        min-width : 340px;
      }

      .I-drop-down-title {
        border : 1px solid $filter-input;
      }
    }

    .I-filter-apply-block {
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      margin-top      : 20px;

      > div {
        display     : flex;
        align-items : center;

        .I-drop-down {
          min-width : 340px;
        }
      }

      .I-footer-apply {
        margin-top      : 15px;
        display         : flex;
        align-items     : center;
        justify-content : space-around;
        float           : right;

        button {
          margin-left   : 10px;
          padding       : 10px 25px;
          border-radius : 8px;
          border        : none;
          white-space   : nowrap;

          &:first-child {
            background-color : white;
          }

          &:last-child {
            background-color : $app-color;
            color            : white;
          }
        }
      }

      .I-search-quantity {
        width           : 44%;
        border          : none;
        border-bottom   : 1px solid $filter-input;
        border-radius   : 3px;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        margin-right    : 22px;

        hr {
          height : 44px;
          border : 0.5px solid $filter-input;
        }

        input {
          width       : 85%;
          border      : none;
          font-size   : 18px;
          text-indent : 10px;

          &::placeholder {
            font-size : 16px;
          }
        }
      }
    }
  }

  .I-data-table,
  .I-table-footer {
    max-width : 90% !important;
    margin    : 0 auto;
  }

  .I-products-page-header {
    width           : 90%;
    margin          : 40px auto;
    position        : relative;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    .I-products-page-left {
      width           : 220px;
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      position        : relative;

      p {
        position    : absolute;
        bottom      : -70%;
        font-weight : bold;
        white-space : nowrap;
      }

      a {
        width            : 160px;
        height           : 40px;
        border           : none;
        border-radius    : 5px;
        font-size        : 15px;
        background-color : $app-color;
        color            : white;
        text-decoration  : none;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        cursor           : pointer;
      }

      .I-repeat-button {
        width            : 40px;
        height           : 40px;
        display          : flex;
        justify-content  : center;
        align-items      : center;
        background-color : white;
        border-radius    : 5px;
        cursor           : pointer;

        i {
          font-size  : 20px;
          transition : 1s;
        }
      }
    }

    .I-products-page-right {
      width           : 40%;
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      button {
        width            : 120px;
        background-color : white;
        height           : 40px;
        border-radius    : 5px;
        font-size        : 15px;
        border           : 1px solid $main-gray;
      }
    }
  }
}

.I-table-ellipsis {
  overflow      : hidden;
  white-space   : nowrap;
  text-overflow : ellipsis;
}
