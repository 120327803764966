.I-modal-wrap {
  height           : 100vh;
  width            : 100vw;
  position         : fixed;
  top              : 0;
  left             : 0;
  right            : 0;
  bottom           : 0;
  z-index          : 10000000000;
  background-color : rgba(0,0,0,0.3);
}

.I-modal-content {
  position         : absolute;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  box-shadow       : 0 1px 15px 0 rgba(0,0,0,.4);
  border-radius    : 10px; 
  background-color : #fff;
}