.I-order-details {
  width           : 90%;
  margin          : 40px auto;
  display         : flex;
  align-items     : flex-start;
  justify-content : space-between;

  .I-order-details-info {
    width  : 25%;
    height : 100%;

    > div {
      width            : 100%;
      height           : auto;
      margin-bottom    : 20px;
      background-color : white;
      box-shadow       : $main-box-shadow;
      border-radius    : 10px;
      padding          : 20px;
    }

    .I-order-details-name {
      width          : 100%;

      i {
        font-size : 15px;
        color     : $app-color;
      }

      .I-details-name {
        font-size   : 22px;
        font-weight : bold;
      }

      hr {
        width         : 80px;
        border        : 2px solid $app-color;
        border-radius : 20px;
      }

      div {
        display         : flex;
        align-items     : center;
        justify-content : space-between;
        margin-top      : 20px;

        span {
          width       : 100%;
          margin-left : 10px;
        }
      }
    }
    
    .I-details-addresses {
      display        : flex;
      flex-direction : column;
      position       : relative;

      hr {
        width         : 80px;
        border        : 2px solid $app-color;
        border-radius : 20px;
      }

      i {
        font-size : 15px;
        color     : $app-color;
      }

      > div {
        width  : 85%;
        height : 100%;
        margin-top      : 20px;

        > div {
          width           : 100%;
          display         : flex;
          justify-content : space-between;
          align-items     : center;
          margin-top      : 10px;

          span {
            width         : 100%;
            margin-left   : 10px;
            // text-overflow : ellipsis;
            // white-space   : nowrap;
            overflow      : hidden;
          }
        }
      }
    }
  }

  .I-order-details-right-side {
    width          : 70%;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    
    > div {
      background-color : white;
      box-shadow       : $main-box-shadow;
      border-radius    : 10px;
    }

    .I-order-details-right-content {
      width   : 100%;
      margin  : 0 auto;
      padding : 0 40px;

      .I-order-right-side-headings {
        margin      : 25px auto;
        padding     : 0 20px;
        display     : flex;
        align-items : center;

        div {
          color : $main-gray;
          width : 100%;
        }

        .I-icon-down-div {
          position : absolute;
          left     : 85%;

          i {
            width       : 20px;
            color       : $main-gray;
            cursor      : pointer;
            font-weight : bolder;
            display     : flex;
            align-items : center;
          }
        }
      }

      .I-order-details-responses {
        width           : 95%;
        margin          : 0 auto;
        display         : flex;
        justify-content : space-between;
        align-items     : center;

        div {
          width      : 100%;
          text-align : justify;
          color      : black;
        }

        .I-drop-down {
          min-width: 200px;
        }
      }

      .I-right-side-headings {
        width           : 95%;
        height          : 50px;
        margin          : 25px auto;
        border-bottom   : 1px solid #e1e2e3;
        display         : flex;
        align-items     : center;
        justify-content : space-between;

        >.align-cols {
          display: flex;
          justify-content: flex-end;
          padding-left: 300px;
          >div {
            flex: 1;
          }
        }

        div {
          flex: 1;
          font-weight : bold;
        }
      }

      .I-right-side-description {
        width          : 95%;
        margin         : 25px auto;
        display        : flex;
        flex-direction : column;

        .I-order-details-block {
          width           : 100%;
          height          : 80px;
          margin-top      : 5px;
          display         : flex;
          align-items     : center;
          justify-content : space-between;

          >.align-item {
            flex: 4;
          }
          >.align-item-small {
            flex: 1.7;
  
          }

          .I-details-first-div {
            min-width       : 70%;
            height          : 80px;
            margin-top      : 5px;
            display         : flex;
            align-items     : center;
            justify-content : space-between;
            text-overflow   : ellipsis;
            white-space     : pre-wrap;

            p {
              width         : 50%;
              text-overflow : ellipsis;
              white-space   : pre-wrap;
            }

            .I-ordered-image {
              min-width           : 80px;
              height              : 70px;
              border              : 2px solid #c1c1c3;
              border-radius       : 8px;
              background-position : center;
              background-size     : contain;
              background-repeat   : no-repeat;
              cursor              : pointer;
            }

            .I-ordered-item-description {
              width       : 100%;
              margin-left : 20px;

              .I-ordered-item-options {
                color : $main-gray;
              }
            }
          }
        }

        .I-details-prices {
          width         : 100%;
          font-size     : 16px;
          text-overflow : ellipsis;
          white-space   : nowrap;
          overflow      : hidden;
        }

        .I-details-total-prices {
          width : 50%;
        }

        .I-order-details-pricing {
          width           : 100%;
          height          : 180px;
          margin-top      : 20px;
          border-top      : 1px solid #e1e2e3;
          display         : flex;
          align-items     : center;
          justify-content : space-between;

          .I-toolbar-buttons {
            margin-left : 0;
          }

          .I-order-details-prices {
            width           : 400px;
            display         : flex;
            align-items     : flex-start;
            justify-content : space-between;

            > div {
              display        : flex;
              flex-direction : column;
              white-space    : nowrap;

              &:nth-child(2), &:nth-child(3) {
                font-weight : bold;
              }

              div {
                &:last-child {
                  margin-top  : 25px;
                  font-size   : 18px;
                  font-weight : bold;
                }
              }
            }

            .I-details-order-amounts {

              div {
                &:nth-child(4),&:nth-child(5) {
                  color : red;
                }

                &:nth-child(3) {
                  color : $app-color;
                }
              }          
            }

            
          .I-order-details-symbols {
            position: relative;

            > div {
              &:last-child {
                top: 117%;
                position: absolute;
              }
            }
          }
          }
        }
      }
    }
    
    .I-user-comment {
      width            : 100%;
      height           : 150px;
      margin-top       : 28px;
      background-color : white;
      padding          : 10px 60px;

      input {
        width      : 100%;
        height     : 80px;
        font-size  : 18px;
        resize     : none;
        border     : 0;
        margin-top : 10px;
        cursor     : default;
      }
    }
  }
}
.orders-page-item {
  width           : 100%;
  height          : 120px;
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  position        : relative;
  p {
    position    : absolute;
    bottom      : 10%;
    font-weight : bold;
    left        : 0;
  }
}