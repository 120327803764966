.I-brands-add-edit {
  width            : 500px;
  height           : 500px;
  background-color : white;
  border-radius    : 8px;

  .I-brands-content {
    width  : 90%;
    margin : 25px auto;

    p {
      margin-top : 20px;
    }

    input {
      width         : 100%;
      margin-top    : 20px;
      height        : 42px;
      border-radius : 4px;
      border        : 1px solid #A9B2B8;
      padding       : 10px;
      font-size     : 16px;
    }

    label {
      width         : 341px;
      height        : 220px;
      padding       : 100px;
      border-radius : 8px;
      background    : #F0F3F4;
      margin-top    : 20px;
      display       : table;
      font-size     : 18px;
      color         : #A0AAB0;
      cursor        : pointer;
      position      : relative;
      
      .I-uploaded-background {
        width           : 200px;
        position        : absolute;
        left            : 50%;
        top             : 50%;
        transform       : translate(-50%,-50%);
        display         : flex;
        align-items     : center;
        justify-content : space-between;
      }

      input[type="file"] {
        display : none;
      }

      .I-upload-image {
        width               : 341px;
        height              : 215px;
        position            : absolute;
        left                : 50%;
        top                 : 50%;
        transform           : translate(-50%,-50%);
        border-radius       : 8px;
        background-position : center;
        background-size     : contain;
        background-repeat   : no-repeat;

        &:hover {
          background-color : rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .I-footer-buttons-brand {
    width           : 100%;
    height          : 60px;
    padding         : 0 20px;
    position        : absolute;
    bottom          : 0;
    display         : flex;
    align-items     : center;
    justify-content : flex-end;
    box-shadow      : $main-box-shadow;

    div {

      .save {
        background-color : $app-color;
        color            : white;
      }

      .disabled {
        background-color : rgba(0, 0, 0, .1);
        color            : rgba(0, 0, 0, .3);
        border           : none;
        cursor           : default;
      }

      button {
        width         : 120px;
        height        : 40px;
        margin-left   : 20px;
        border        : none;
        border-radius : 10px;


        &:first-child {
          color            : black;
          background-color : white;
        }
      }
    }
  }
}